import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getWorkPrograms,
    getWorkProgramsSuccess,
    getWorkProgramsError,
    addWorkProgram,
    updateWorkProgram,
    //getWorkProgram,
    //deleteWorkProgram,
    actionWorkProgramError,
    actionWorkProgramSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';

function* refreshList(access_token) {

    try {
        // Fetch List
        let response = yield call(API.medicals.getWorkProgram, access_token);
        yield put(getWorkProgramsSuccess(response.data))

    } catch (e) {
        yield put(getWorkProgramsError(e))
    }
}

function* getWorkProgramsSaga(action) {
    const { access_token } = action.payload

    yield call(refreshList, access_token);
}


function* addWorkProgramSaga(action) {

    const { access_token, values, callback = () => { } } = action.payload;

    try {
        // Add Work Program
        let add = yield call(API.medicals.addWorkProgram, { access_token, values });
        yield put(actionWorkProgramSuccess(add.data.data))
    } catch (e) {
        yield put(actionWorkProgramError(e))
    }

    yield call(refreshList, access_token);
    callback()
}

function* updateWorkProgramSaga(action) {

    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        // Update Training
        let update = yield call(API.medicals.updateWorkProgram, { access_token, values, id });
        yield put(actionWorkProgramSuccess(update.data.data))
    } catch (e) {
        yield put(actionWorkProgramError(e))
    }
    
    yield call(refreshList, access_token);
    callback()
}


// function* getWorkProgramSaga(action) {

//     const { access_token, id, callback = () => { } } = action.payload;

//     try {
//         // Get 
//         let { data } = yield call(API.medicals.getWorkProgram, { access_token, id });
//         yield put(actionWorkProgramSuccess(data.data))
//         callback()

//     } catch (e) {
//         yield put(actionWorkProgramError(e))
//     }
// }

// function* deleteWorkProgramSaga(action) {

//     const { access_token, id } = action.payload;

//     try {
//         // Delete
//         yield call(API.medicals.deleteWorkProgram, { access_token, id });
//         yield put(actionWorkProgramSuccess(null))

//     } catch (e) {
//         yield put(actionWorkProgramError(e))
//     }
// }




export function* workProgramsWatcherSaga() {
    yield takeLatest(getWorkPrograms, getWorkProgramsSaga);
    yield takeLatest(addWorkProgram, addWorkProgramSaga);
    yield takeLatest(updateWorkProgram, updateWorkProgramSaga);
    //yield takeLatest(getWorkProgram, getWorkProgramSaga);
    //yield takeLatest(deleteWorkProgram, deleteWorkProgramSaga);
}