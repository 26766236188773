import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import useModal from '../../../hooks/useModal';

import {
    getTrainingNotes,
    deleteTrainingNote,
    resetTrainingNote,
} from "../../../redux/actions/actions";

import { selectNotes } from "../../../redux/selectors/selectors";
import { withAuthContext } from '../../../contexts/auth/Auth.context';

import NewNote from './NewNote.container';

import Note from '../../../components/note';
import { IconAddComponent } from '../../../components/ui/icon/Icon.component';
import ProgressComponent from '../../../components/ui/progress/Progress.component';
import TrainingBoxTemplate from '../../../template/training/TrainingBox.template';

import FormModal from '../../../components/form/ui/modal';


const NotesPage = ({
    authContext,
    trainingId,
    getTrainingNotes,
    deleteTrainingNote,
    resetTrainingNote,
    notes,
    enabled,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal();
    const [currentNote, setCurrentNote] = useState(null)

    useEffect(() => {
        getTrainingNotes({
            access_token: authContext.access_token,
            id: trainingId
        })
    }, []);

    const onDeleteNote = (id) => {
        deleteTrainingNote({
            access_token: authContext.access_token,
            id: id,
            training: trainingId
        })
    }

    const onUpdateNote = (id) => {        
        setCurrentNote(id)
        openModal()
    }

    const onAddNote = () => {
        setCurrentNote(null)
        openModal()
    }

    const disabled = !enabled

    return (
        <>
            <TrainingBoxTemplate
                titleBox='Notes'
                actionBox={
                    disabled ? null : <IconAddComponent action={() => onAddNote()} />
                }>
                {notes.loading ? <ProgressComponent /> :
                    notes.data.map((item, index) =>
                        <Note
                            key={index}
                            item={item}
                            onDeleteNote={(id) => onDeleteNote(id)}
                            onUpdateNote={(id) => onUpdateNote(id)}
                            disabled={disabled} />
                    )
                }
            </TrainingBoxTemplate>
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setCurrentNote(null)
                    resetTrainingNote()
                    closeModal()
                }}
                onSave={() => {
                    setCurrentNote(null)
                    resetTrainingNote()
                    closeModal()
                }}
                title={currentNote ? 'Update Note' : 'Add Note'}>
                <NewNote
                    noteId={currentNote}
                    onDismiss={() => {
                        setCurrentNote(null)
                        resetTrainingNote()
                        closeModal()
                    }}
                />
            </FormModal>
        </>
    )
};

const mapStateToProps = state => ({
    notes: selectNotes(state)
});

const mapDispatchToProps = dispatch => ({
    getTrainingNotes: (item) => {
        dispatch(getTrainingNotes(item));
    },
    deleteTrainingNote: (item) => {
        dispatch(deleteTrainingNote(item))
    },
    resetTrainingNote: () => {
        dispatch(resetTrainingNote())
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NotesPage));