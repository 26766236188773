export const endpoints = {
    //baseURL: "https://footuredesk.abinsula.com", //"https://footuredesk.abinsula.com", // http://10.0.130.132:8182 // "http://10.1.128.48:8888"
    baseURL: process.env.REACT_APP_BASE_URL,

    login: "/api-auth/token/",
    me: "/api/v1/users/me/",

    weather: "api/v1/tools/weather/",
    conditionField: "api/v1/tools/field_condition/",
    typologyField: "api/v1/tools/field_typology/",

    training: "api/v1/coaching/training/",
    trainingPlan: "api/v1/coaching/training_plan/",
    trainingDesk: "api/v1/coaching/training_player_desk/",
    exerciseDesk: "api/v1/coaching/exercise_player_desk/",
    exerciseGoalkeeperDesk: "api/v1/coaching/exercise_goalkeeper_desk/",

    workProposal: "api/v1/coaching/work_proposal/",
    proposal: "api/v1/coaching/proposal/",
    theme: "api/v1/coaching/theme/",
    activity: "api/v1/coaching/activity/",
    objective: "api/v1/coaching/objective/",
    pitchPosition: "api/v1/coaching/pitch_position/",
    combinedPitchPosition: "api/v1/coaching/combined_pitch_position/",
    trainingRole: "api/v1/coaching/training_role/",
    exerciseCategory: "api/v1/coaching/exercise_category/",
    exerciseName: "api/v1/coaching/exercise_name/",
    exerciseImage: "api/v1/coaching/exercise_image/",

    workout: "api/v1/coaching/workout_goalkeeper/",
    workoutModality: "api/v1/coaching/workout_modality/",
    kickType: "api/v1/coaching/kick_type/",
    teamField: "api/v1/coaching/team_field_work/",
    ballType: "api/v1/coaching/ball_type/",
    gymWork: "api/v1/coaching/gym_work/",
    limb: "api/v1/coaching/limb/",
    psychokineticLightWork: "api/v1/coaching/psychokinetic_light_work/",
    equipment: "api/v1/coaching/equipment/",
    sandWork: "api/v1/coaching/sand_work/",

    workProposalGoalkeeper: "api/v1/coaching/work_proposal_goalkeeper/",

    trainingNote: "api/v1/coaching/training_note/",

    players: "api/v1/players/player/",
    squad: "api/v1/players/squad/",
    teams: "api/v1/players/team/",

    medicalNote: "api/v1/medicals/medical_note/",
    injuryStatistics: "api/v1/medicals/injury_statistic/",

    therapy: "api/v1/medicals/therapy/",
    avaibility: "api/v1/medicals/availability/",
    workProgram: "api/v1/medicals/work_program/",
    injuryType: "api/v1/medicals/injury_type/",

    clubCompetitions: "api/v1/club/competition/",
    clubTeams: "api/v1/club/team/",

    clubCategories: "api/v1/report/report-categories/",
    clubLinks: "api/v1/report/report-links/"
}
