import React from 'react';
import { withListContext } from '../../hoc';
import { HeadComponent } from '../../../ui/table/head/Head.component';
import { RowComponent } from '../../../ui/table/row/Row.component';
import { CellCheckboxComponent } from '../../../ui/table/checkbox/CellCheckbox.component';
import { CellTextComponent } from '../../../ui/table/text/CellText.component';

const ListHead = ({ listContext }) => {

    const {
        selected,
        head: {
            cells
        },
        actions: {
            onSelectAll
        },
    } = listContext;

    return (
        <HeadComponent>
            <RowComponent>
                {cells.map(({ label, component, align }, index) => {
                    switch (component) {
                        case 'CellCheckbox':
                            return (
                                <CellCheckboxComponent
                                    key={index}
                                    onChange={(event) => onSelectAll(event)}
                                    checked={selected.length > 0} />
                            )
                        case 'CellText':
                            return (
                                <CellTextComponent
                                    key={index}
                                    label={label}
                                    align={align} />
                            )
                        default:
                            return null;
                    }
                })}
            </RowComponent>
        </HeadComponent>
    )
};

export default withListContext(ListHead);