import React from 'react';
import BoxComponent from '../../components/ui/box/Box.component';
import CardComponent from '../../components/ui/card/Card.component';
import { TypeEvent, TypeTrauma } from '../../config/medicalArea/CommonTypeConf';
import moment from 'moment';

import { appColor } from '../../config/colors/colors'


const mainContent = (start, end, availability) => {
    const titles = [
        'Date:',
        'Availability:',
    ]

    const values = [
        `${moment(start).format('YYYY/MM/DD')} - ${moment(end).format('YYYY/MM/DD')}`,
        availability.availability,
    ]

    return content(titles, values)
}

const otherContent = (injury_type, therapies, work_programs, trauma_type, event_type, training_plan) => {
    const titles = [
        'Injury Type:',
        'Therapies:',
        'Work programs:',
        'Trauma Type:',
        'Event Type:',
        'Training Plan:'
    ]

    const values = [
        injury_type.injury_type,
        therapies ? therapies.map(element => element.therapy + ',') : '-',
        work_programs ? work_programs.map(element => element.work_program + ',') : '-',
        trauma_type ? TypeTrauma.trauma_types.find(type => {return type.value === trauma_type }).label : '-',
        event_type ? TypeEvent.events.find(type => {return type.value === event_type }).label : '-',
        training_plan ? training_plan.training_plan : '-'
    ]


    return content(titles, values)
}

const content = (titles, values) => {

    return (
        titles.map((item, index) =>
            <BoxComponent key={item} width="100%" display="flex" flexDirection="row" mt={2}>
                <BoxComponent
                    fontWeight='fontWeightRegular'
                    textAlign="left"
                    mr={1}>{item}</BoxComponent>
                <BoxComponent
                    textAlign="left"
                    fontWeight='fontWeightLight'>{values[index]}</BoxComponent>
            </BoxComponent>
        )
    )
}


const Report = ({
    report,
}) => {

    const {
        start,
        end,
        player,
        injury_type,
        therapies,
        work_programs,
        trauma_type,
        event_type,
        training_plan,
        availability,
    } = report;

    const title = `${player.name} ${player.surname}`;
    const subheader = `${player.role.role}`;

    const colors = [
        appColor.grey,
        appColor.green,
        appColor.red,
        appColor.orange
    ]

    return (
        <CardComponent
            title={title}
            subheader={subheader}
            src={player.image}
            iconColor={colors[availability.id]}
            mainContent={mainContent(start, end, availability)}
            otherContent={otherContent(injury_type, therapies, work_programs, trauma_type, event_type, training_plan)}>           
        </CardComponent>
    )
}

export default Report;