import React from 'react';
import { withDashboardContext } from '../../hoc';
import { ContainerComponent } from '../../../ui/container/Container.component';

const DashboardContainer = ({ children }) => {

    return (
        <ContainerComponent>
            {children}
        </ContainerComponent>            
    )
};

export default withDashboardContext(DashboardContainer);