import React from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../contexts/auth/Auth.context';

import { getDailyReport } from "../../redux/actions/actions";

import { selectDailyReport } from "../../redux/selectors/selectors";

import FormProvider from '../../components/form/provider';
import Form from '../../components/form/ui';

import { AsyncSelectMultiple } from '../../components/form/ui/select/async/multiple';
import { CalendarPicker } from '../../components/form/ui/calendar';
import { NewDailyReportConf } from '../../config/medicalArea/NewDailyReportConf';

import { BodyComponent } from '../../components/ui/page/body/Body.component';
import { GridComponent } from '../../components/ui/grid/Grid.component';
import BoxComponent from '../../components/ui/box/Box.component';

import Report from '../../components/report';

import { GET } from '../../services/Service.context';
import API from '../../services/axios/api';

import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';

import { appColor } from '../../config/colors/colors'

const DailyReport = ({
    authContext,
    getDailyReport,
    dailyReport
}) => {

    const {
        body,
        footer
    } = NewDailyReportConf;

    const onSubmit = values => {
        getDailyReport({ access_token: authContext.access_token, values: values })
    };

    return (
        <>
            <ToolbarComponent title="Daily Report" />
            <BodyComponent>
                <FormProvider
                    onSubmit={onSubmit}
                    body={body}
                    footer={footer}
                    initialValues={dailyReport.filter}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={4} style={{ padding: '8px' }}>
                                <CalendarPicker item={body.date} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.player.getPlayers(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.players} node={body.players.name} others={body.players.others} />
                                </GET>
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BodyComponent>
            <BoxComponent width={'100%'} mt={2}>
                {/* <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Medical Notes</BoxComponent> */}
                {dailyReport.data.length > 0 &&
                    dailyReport.data.map(report =>
                        <BoxComponent key={report.id} borderColor={appColor.lightGrey} borderTop={1}>
                            <Report report={report} />
                        </BoxComponent>
                    )}
            </BoxComponent>
        </>
    )
}

const mapStateToProps = state => ({
    dailyReport: selectDailyReport(state)
});

const mapDispatchToProps = dispatch => ({
    getDailyReport: (params) => {
        dispatch(getDailyReport(params))
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(DailyReport));