import { put, all, call, takeLatest } from 'redux-saga/effects';
// import _groupBy from 'lodash/groupBy';
import {
    getGoalKeeperWorkProposals,
    getGoalKeeperWorkProposalsError,
    getGoalKeeperWorkProposalsSuccess,

    orderGoalKeeperWorkProposals,

    getGoalKeeperWorkProposal,
    addGoalKeeperWorkProposal,
    updateGoalKeeperWorkProposal,
    deleteGoalKeeperWorkProposal,
    resetGoalKeeperWorkProposal,

    actionGoalKeeperWorkProposalReset,
    actionGoalKeeperWorkProposalError,
    actionGoalKeeperWorkProposalSuccess,

} from '../../actions/actions';

import API from '../../../services/axios/api';


function* refreshList({ access_token, id }) {

    try {
        // Fetch List
        let list = yield call(API.coaching.getGoalKeeperWorkProposals, { access_token, id });
        yield put(getGoalKeeperWorkProposalsSuccess({ data: list.data.data }))

    } catch (e) {
        yield put(getGoalKeeperWorkProposalsError(e))
    }
}


function* getGoalKeeperWorkProposalsSaga(action) {

    const { access_token, id } = action.payload;
    yield call(refreshList, { access_token, id })

}

function* getGoalKeeperWorkProposalSaga(action) {

    const { access_token, id, callback = () => { } } = action.payload;

    try {
        // GET 
        let { data } = yield call(API.coaching.getGoalKeeperWorkProposal, { access_token, id });        
        yield put(actionGoalKeeperWorkProposalSuccess(data.data))
    } catch (e) {
        yield put(actionGoalKeeperWorkProposalError(e))
    }    
    callback()
}


function* addGoalKeeperWorkProposalSaga(action) {

    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        yield call(API.coaching.addGoalKeeperWorkProposal, { access_token, values, id })
        yield put(actionGoalKeeperWorkProposalSuccess())

    } catch (e) {
        yield put(actionGoalKeeperWorkProposalError(e))
    }

    yield call(refreshList, { access_token, id })

    callback()
}

function* updateGoalKeeperWorkProposalSaga(action) {

    const { access_token, values, training, id, callback = () => { } } = action.payload;    

    try {
        yield call(API.coaching.updateGoalKeeperWorkProposal, { access_token, values, id , training})
        yield put(actionGoalKeeperWorkProposalSuccess())

    } catch (e) {
        yield put(actionGoalKeeperWorkProposalError(e))
    }

    yield call(refreshList, { access_token, id: training })

    callback()
}

function* deleteGoalKeeperWorkProposalSaga(action) {

    const { access_token, training, id } = action.payload;    

    try {
        
        yield call(API.coaching.deleteGoalKeeperWorkProposal, { access_token, training, id })
        yield put(actionGoalKeeperWorkProposalSuccess())

    } catch (e) {
        yield put(actionGoalKeeperWorkProposalError(e))
    }

    yield call(refreshList, { access_token, id: training })
}

function* orderGoalKeeperWorkProposalsSaga(action) {

    const { access_token, items, training } = action.payload

    const yields = items.map(({ number, id }) => call(API.coaching.orderGoalKeeperWorkProposal, { access_token, number, id }))
    yield all(yields)
    yield call(refreshList, { access_token, id: training })
}


function* resetGoalKeeperWorkProposalsSaga() {
    yield put(actionGoalKeeperWorkProposalReset())    
}


export function* goalKeeperWorkProposalsWatcherSaga() {
    yield takeLatest(getGoalKeeperWorkProposal, getGoalKeeperWorkProposalSaga);
    yield takeLatest(getGoalKeeperWorkProposals, getGoalKeeperWorkProposalsSaga);
    yield takeLatest(orderGoalKeeperWorkProposals, orderGoalKeeperWorkProposalsSaga);
    yield takeLatest(addGoalKeeperWorkProposal, addGoalKeeperWorkProposalSaga);
    yield takeLatest(updateGoalKeeperWorkProposal, updateGoalKeeperWorkProposalSaga);
    yield takeLatest(deleteGoalKeeperWorkProposal, deleteGoalKeeperWorkProposalSaga);
    yield takeLatest(resetGoalKeeperWorkProposal, resetGoalKeeperWorkProposalsSaga);
}
