import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Menu.component.style';
import List from '@material-ui/core/List';

const MenuTemplate = ({
    classes,
    children,
}) => {

    return (
        <List
            component="nav"
            className={classes.list}>
            {children}
        </List>
    )
}

export const MenuComponent = withStyles(styles)(MenuTemplate)