import { put, call, takeLatest, select, take } from 'redux-saga/effects';
import {
    accountSignIn,
    accountSignInSuccess,
    accountSignInFailure,
    accountSignOut,
    accountCreate,
    getTeams,
    checkToken,
    getTeamsSuccess,
} from '../../actions/actions';
import { push } from 'connected-react-router';

import API from '../../../services/axios/api';

function* loginSaga(action) {

    const { username, password } = action.payload

    try {

        // Login
        let login = yield call(API.auth.login, { username, password });

        // Me
        let me = yield call(API.auth.me, login.data);

        // Account
        yield put(accountCreate(me.data.data))

        // Teams
        yield put(getTeams(login.data.access_token));
        yield take(getTeamsSuccess);

        // Success
        yield put(accountSignInSuccess(login.data))

        // First Team
        const teams = yield select(state => state.team.teams.data);
        yield put(push(`/training_calendar/${teams[0].id}`))

    } catch (e) {
        yield put(accountSignInFailure(e))
    }
}


function* logoutSaga() {
    yield put(push('/login'))
}



function* checkTokenSaga(action) {

    try {

        // Me
        let me = yield call(API.auth.me, action.payload);

        // Account
        yield put(accountCreate(me.data.data))

        // Teams
        yield put(getTeams(action.payload.access_token));

        // Success
        yield put(accountSignInSuccess(action.payload))

    } catch (e) {
        yield put(push('/login'))
    }
}

export function* authWatcherSaga() {
    yield takeLatest(accountSignIn, loginSaga);
    yield takeLatest(accountSignOut, logoutSaga);
    yield takeLatest(checkToken, checkTokenSaga);
}
