import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CellImage.component.style';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';

const CellImageTemplate = ({
    classes,
    src
}) => {

    return (
        <TableCell padding="none" className={classes.cell}>
            <Avatar src={src} className={classes.avatar}/>
        </TableCell>
    )
}

export const CellImageComponent = withStyles(styles)(CellImageTemplate)

