import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';

const ExerciseLarge = ({
    children,
}) => {
    return (
        <BoxComponent
            textAlign="left"
            fontWeight="fontWeightBold"
            mb={2}
            fontSize={18}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseLarge;