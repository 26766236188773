import React from 'react'
import { Field } from 'react-final-form';
import { CalendarPickerComponent } from '../../../ui/form/calendar/Calendar.component';
import { Error } from '../error';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export const CalendarPicker = ({
    item,
    disabled,
    errors,
    calendarType = 'DateTimePicker'    
}) => {

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                // disabled={item.disabled}                
                error={errors ? errors[item.name] : null}
                calendarType={item.calendarType ? item.calendarType : calendarType}
                disabled={disabled}                
                component={CalendarPickerComponent} />
            <Error name={item.name} />
        </MuiPickersUtilsProvider>
    )
}