import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './EditText.component.style';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';


const EditTextTemplate = ({
    classes,
    label,
    required = false,
    disabled = false,
    type = 'text',
    inputRef,
    input,
    meta,
    ...rest
}) => {

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched; 
    
    return (
        <FormControl
            className={classes.formControl}
            error={showError}>
            <InputLabel
                required={required}>
                {label}
            </InputLabel>
            <Input                
                fullWidth
                disabled={disabled}
                inputRef={inputRef}                                               
                {...input}
                {...rest} />
        </FormControl>
    )
}

export const EditTextComponent = withStyles(styles)(EditTextTemplate)