import React from 'react';
import { withPopoverContext } from '../context';

import PopoverComponent from '../../ui/popover/Popover.component';

const Popover = ({
    children,
    popoverContext: { 
        open, 
        anchorEl,
        actions: {
            handleClose,
        }
    }
}) => {

    return (
        <PopoverComponent
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}>
            {children}
        </PopoverComponent>
    )
}

export default withPopoverContext(Popover);