import React, { useState } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Card.component.style';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { appColor } from '../../../config/colors/colors'

const CardComponent = ({
	classes,
	title,
	subheader,
	mainContent,
	otherContent,
	iconColor,
	src,
}) => {

	const [expanded, setExpanded] = useState(false);
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	return (
		<Card className={classes.card}>
			<CardHeader
				avatar={
					<Avatar
						aria-label="recipe"
						style={{ backgroundColor: appColor.lightGrey }}
						src={src}
					/>}
				action={
					<IconButton style={{backgroundColor: iconColor}}/>
				}
				title={title}
				subheader={subheader}
				className={classes.header} />
			<CardContent className={classes.content}>
				{mainContent}
			</CardContent>
			<CardActions disableSpacing>
				<Typography className={classes.moreDetails}>More details</Typography>
				<IconButton
					className={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more">
					<ExpandMoreIcon />
				</IconButton>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent className={classes.content}>
					{otherContent}
				</CardContent>
			</Collapse>
		</Card>
	);
}

export default withStyles(styles)(CardComponent);
