import React from 'react';
import { withListContext } from '../hoc';
import { TableComponent } from '../../ui/table/Table.component';

const List = ({
    children
}) => {

    return (
        <TableComponent>
            {children}
        </TableComponent>
    )
}


export default withListContext(List);