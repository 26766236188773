import React from 'react';
import { Field } from 'react-final-form';
import { SelectComponent } from '../../../../../ui/form/select/Select.component';
import { Error } from '../../../error';


const isUrl = (s) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
}

export const AsyncSelect = ({
    item,
    data,
    loading,
    disabled,
    errors,
    node,
    others,
    
}) => {

    const values = data ? data.data.map((item, index) => {

        // Url
        if (isUrl(item[node])) {
            return {
                label: item[node].substring(item[node].lastIndexOf('/') + 1),
                value: item.id
            }
        }


        // Others
        if (others) {
            return {
                label: item[node] + ' ' + item[others],
                value: item.id,
            }
        }
        

        // Default
        return {
            label: item[node],
            value: item.id,
        }
    }) : []
    

    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                loading={loading}
                error={errors ? errors[item.name] : null}                
                component={SelectComponent}
                values={values} />
            <Error name={item.name} />
        </>
    )
}
