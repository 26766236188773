import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import {
    getTeamFieldWorks,
} from "../../../redux/actions/actions"

import { selectTeamFieldWorks } from "../../../redux/selectors/selectors"
import { withAuthContext } from "../../../contexts/auth/Auth.context"

import ProgressComponent from "../../../components/ui/progress/Progress.component"

import { ListConf } from "../../../config/settings/TeamFieldWork.config"
import ListSettings from '../../../components/settings/list';
import FormModal from "../../../components/form/ui/modal"
import useModal from "../../../hooks/useModal"

import NewTeamFieldWorkPage from './NewTeamFieldWork.container';

const TeamFieldWorkPage = ({
    authContext,
    teamFieldWorks,
    enabled,
    getTeamFieldWorks,
}) => {


    const { openModal, closeModal, isModalShowing } = useModal()
    const [element, setElement] = useState(null)


    useEffect(() => {
        getTeamFieldWorks({ access_token: authContext.access_token })
    }, [])

    return (
        <>
            {teamFieldWorks.loading ? <ProgressComponent /> : null}
            <ListSettings
                data={teamFieldWorks.data}
                conf={ListConf}
                enabled={enabled}
                onAddElement={() => {
                    openModal()
                }}
                onEditElement={(ids) => {
                    setElement(ids.length > 0 ? teamFieldWorks.data.find(item => item.id === ids[0]) : null)
                    openModal()
                }} />
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setElement(null)
                    closeModal()
                }}
                onSave={() => {
                    setElement(null)
                    closeModal()
                }}
                title={element ? 'Update' : 'Add'}>
                <NewTeamFieldWorkPage
                    item={element}
                    onDismiss={() => {
                        setElement(null)
                        closeModal()
                    }}/>
            </FormModal>
        </>
    )
}

const mapStateToProps = state => ({
    teamFieldWorks: selectTeamFieldWorks(state)
})

const mapDispatchToProps = dispatch => ({
    getTeamFieldWorks: values => {
        dispatch(getTeamFieldWorks(values))
    },    
})

export default withAuthContext(
    connect(mapStateToProps, mapDispatchToProps)(TeamFieldWorkPage)
)
