import React from "react"
import BoxComponent from "../../components/ui/box/Box.component"
import { ToolbarComponent } from "../../components/ui/page/toolbar/Toolbar.component"

const TrainingTemplate = ({
    toolbar,
    trainingBox,
    notesBox,
    exercisesBox,
    exercisesPlayersBox,
    goalkepperExercisesBox,
    goalkepperExercisesPlayersBox,
    gpsBox,
    pdfBox
}) => {
    return (
        <>
            <BoxComponent width={1}>{toolbar}</BoxComponent>
            <BoxComponent width={1}>{trainingBox}</BoxComponent>
            <BoxComponent width={1} mt={3}>
                {notesBox}
            </BoxComponent>
            <BoxComponent width={1} mt={3}>
                {gpsBox}
            </BoxComponent>
            <BoxComponent width={1} mt={3}>
                {pdfBox}
            </BoxComponent>
            <BoxComponent width={1}>
                <ToolbarComponent title="TRAINING PLAYERS" />
            </BoxComponent>
            <BoxComponent
                width="100%"
                mt={2}
                alignItems="flex-start"
                display="flex"
            >
                <BoxComponent width={1 / 2}>{exercisesBox}</BoxComponent>
                <BoxComponent width={1 / 2}>{exercisesPlayersBox}</BoxComponent>
            </BoxComponent>
            <ToolbarComponent title="TRAINING GOALKEEPER" />
            <BoxComponent
                width="100%"
                mt={2}
                alignItems="flex-start"
                display="flex"
            >
                <BoxComponent width={1 / 2}>
                    {goalkepperExercisesBox}
                </BoxComponent>
                <BoxComponent width={1 / 2}>
                    {goalkepperExercisesPlayersBox}
                </BoxComponent>
            </BoxComponent>
        </>
    )
}

export default TrainingTemplate
