import React, { useState } from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../contexts/auth/Auth.context';

import { getTrainingDesk } from "../../redux/actions/actions";

import { selectTrainingDesk } from "../../redux/selectors/selectors";

import FormProvider from '../../components/form/provider';
import Form from '../../components/form/ui';

import { AsyncSelectMultiple } from '../../components/form/ui/select/async/multiple';
import { CalendarPicker } from '../../components/form/ui/calendar';
import { NewTrainingDeskConf } from '../../config/training/NewTrainingDesk.conf';
import { appColor } from '../../config/colors/colors';

import { BodyComponent } from '../../components/ui/page/body/Body.component';
import { GridComponent } from '../../components/ui/grid/Grid.component';
import BoxComponent from '../../components/ui/box/Box.component';

import { GET } from '../../services/Service.context';
import API from '../../services/axios/api';

import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';
import { IconExpandLessComponent, IconExpandMoreComponent } from '../../components/ui/icon/Icon.component';
import PlayerAppearences from '../../components/playerAppearences/PlayerAppearences';
import Training from '../../components/training';
// import { Checkbox } from '../../components/form/ui/checkbox';
import { Select } from '../../components/form/ui/select/single';


const renderHeader = (title, open, setOpen) => {

    return (
        <BoxComponent width='100%' mr={1} ml={1} mt={2} /*bgcolor={appColor.white}*/ boxShadow={1} display="flex" flexDirection="row" color={appColor.white} bgcolor={appColor.other.main}>
            <BoxComponent width="95%" textAlign="left" p={2} fontWeight="fontWeightBold" fontSize={18}>
                {title}
            </BoxComponent>
            <BoxComponent width="5%">
                {open ? <IconExpandLessComponent action={() => setOpen(!open)} /> : <IconExpandMoreComponent action={() => setOpen(!open)} />}
            </BoxComponent>
        </BoxComponent>
    )
}

const renderTrainings = (data, open, setOpen) => {
    if (!data) return null;

    const orderData = data.sort((a, b) => {
        return (new Date(a.date) - new Date(b.date))
    })

    return (
        <>
            {renderHeader(`Trainings (${orderData.length})`, open, setOpen)}
            {open ?
                <BoxComponent width={'100%'} mr={1} ml={1}>
                    {orderData.length > 0 && orderData.map(training =>
                        <BoxComponent key={training.id} borderColor={appColor.lightGrey} borderTop={1}>
                            <Training training={training} />
                        </BoxComponent>
                    )}
                </BoxComponent> : null}
        </>
    )
}

const renderPlayers = (data, open, setOpen) => {
    if (!data) return null;
    let appearances = data.map(item => { let result = item.player; result.appearance = item.available_appearances; return result; })
    return (
        <>
            {renderHeader('Players', open, setOpen)}
            {open ?
                <BoxComponent width={'100%'} mr={1} ml={1}>
                    <PlayerAppearences appearances={appearances} />
                </BoxComponent> : null}
        </>
    )
}


const TrainingDesk = ({
    authContext,
    trainingDesk,
    getTrainingDesk
}) => {

    const [openTrainings, setOpenTrainings] = useState(true)
    const [openPlayers, setOpenPlayers] = useState(true)

    const {
        body,
        footer
    } = NewTrainingDeskConf;

    const onSubmit = values => {
        getTrainingDesk({ access_token: authContext.access_token, values: values })
    };

    return (
        <>
            <ToolbarComponent title="Training Desk" />
            <BodyComponent>
                <FormProvider
                    onSubmit={onSubmit}
                    body={body}
                    footer={footer}
                    initialValues={trainingDesk.filter}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={body.startDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={body.endDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.coaching.getTrainingPlan(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.trainingPlan} node={body.trainingPlan.name} />
                                </GET>
                            </GridComponent>
                            <GridComponent item={true} xs={12}>
                                <Select item={body.trainingGroup} options={[ {value: false, label: 'No Group'}, {value: true, label: 'Group'}]} placeholder={'Group'} />
                            </GridComponent>
                            <GridComponent item={true} xs={12}>
                                <GET request={API.coaching.getPitchPosition(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.pitchPosition} node={body.pitchPosition.name} />
                                </GET>
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.coaching.getCombinedPitchPosition(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.combinedPitchPosition} node={body.combinedPitchPosition.name} />
                                </GET>
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.coaching.getTrainingRole(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.trainingRole} node={body.trainingRole.name} />
                                </GET>
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.coaching.getObjective(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.objective} node={body.objective.name} />
                                </GET>
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BodyComponent>
            <BoxComponent width={'100%'} mt={2} alignItems="flex-start" display='flex'>
                <BoxComponent width={1 / 2}>
                    {trainingDesk.data && renderTrainings(trainingDesk.data.trainings, openTrainings, setOpenTrainings)}
                </BoxComponent>
                <BoxComponent width={1 / 2} ml={2}>
                    {trainingDesk.data && renderPlayers(trainingDesk.data.appearances, openPlayers, setOpenPlayers)}
                </BoxComponent>
            </BoxComponent>
        </>
    )
}

const mapStateToProps = state => ({
    trainingDesk: selectTrainingDesk(state)
});

const mapDispatchToProps = dispatch => ({
    getTrainingDesk: (params) => {
        dispatch(getTrainingDesk(params))
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(TrainingDesk));