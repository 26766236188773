import React from 'react'
// import _omit from 'lodash/omit';

import FormProvider from '../../../components/form/provider';
import Form from '../../../components/form/ui';
import { EditArea } from '../../../components/form/ui/editArea';
import { AsyncSelect } from '../../../components/form/ui/select/async/single';
import { GridComponent } from '../../../components/ui/grid/Grid.component';

import { GET } from '../../../services/Service.context';
import API from '../../../services/axios/api';

import { withAuthContext } from '../../../contexts/auth/Auth.context';


const NoteForm = ({
    authContext,
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
}) => {


    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.player.xs}>
                        <GET request={API.player.getPlayers(authContext.access_token)}>
                            <AsyncSelect
                                item={body.player}
                                node={body.player.name}
                                others={body.player.others} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.description.xs}>
                        <EditArea item={body.description} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(NoteForm);