import { useState } from 'react';

const usePopover = (initialValue) => {
    const [openPopover, setOpenPopover] = useState(initialValue);
    const [anchorEl, setAncorEl] = useState(null);
    
    const handlePopoverOpen = (event) => {
        setAncorEl(event.currentTarget);
        setOpenPopover(true);
    };
    const handlePopoverClose = () => {
        setAncorEl(null);
        setOpenPopover(false);
    };

    return { openPopover, anchorEl, handlePopoverOpen, handlePopoverClose }
};

export default usePopover;