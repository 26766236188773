import React from "react"
import { withRouter } from "react-router"

import InjuryType from "./injurytype/InjuryType.container"
import WorkProgram from "./workprogram/WorkProgram.container"
import TeamFieldWork from "./teamfieldwork/TeamFieldWork.container"
import ExerciseCategory from "./exercisecategory/ExerciseCategory.container"
import ExerciseImage from "./exerciseimage/ExerciseImage.container"
import ExerciseName from "./exercisename/ExerciseName.container"



import { withAuthContext } from "../../contexts/auth/Auth.context"
import usePermissions from "../../hooks/usePermissions"

import BoxSettings from '../../components/settings/box';
import { ToolbarComponent } from "../../components/ui/page/toolbar/Toolbar.component"
import BoxComponent from "../../components/ui/box/Box.component"

import TabsProvider from '../../components/tabs/provider';
import Tabs from '../../components/tabs/ui';
import useTabs from '../../hooks/useTabs';


const renderTab = (index, isAuthEnabled) => {
    switch (index) {
        case 0:
            return (
                <>
                    <BoxSettings>
                        <InjuryType enabled={isAuthEnabled} />
                    </BoxSettings>
                    <BoxSettings>
                        <WorkProgram enabled={isAuthEnabled} />
                    </BoxSettings>
                </>
            )
        case 1:
            return (
                <>
                    <BoxSettings>
                        <TeamFieldWork enabled={isAuthEnabled} />
                    </BoxSettings>
                    <BoxSettings>
                        <ExerciseCategory enabled={isAuthEnabled} />
                    </BoxSettings>
                    <BoxSettings>
                        <ExerciseImage enabled={isAuthEnabled} />
                    </BoxSettings>
                    <BoxSettings>
                        <ExerciseName enabled={isAuthEnabled} />
                    </BoxSettings>
                </>
            )
        default:
            return <div />
    }
}


const SettingsPage = ({
    authContext
}) => {

    const { isAuthEnabled } = usePermissions("Settings", authContext.type)
    const { index, setIndex } = useTabs()

    const tabs = [{
        title: 'General',
    }, {
        title: 'Work Proposal',
    }, {
        title: 'Players',
    }]

    return (
        <>
            <BoxComponent width={1}>
                <ToolbarComponent title="SETTINGS" />
            </BoxComponent>

            <TabsProvider
                index={index}
                onChange={(index) => {
                    setIndex(index)
                }}
                tabs={tabs}>
                <Tabs />
                {renderTab(index, isAuthEnabled)}
            </TabsProvider>
        </>
    )
}

export default withRouter(withAuthContext(SettingsPage))
