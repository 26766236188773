export default {

    upsertTeamFieldWork: ({ values, id }) => {
        var body = new FormData();

        if (id) {
            body.set('id', id);
        }

        if (values.name) {
            body.set('name', values.name);
        }

        return body
    },
}