import React from 'react';
import BoxComponent from '../../ui/box/Box.component';

const BoxSettings = ({
    children
}) => {

    return (
        <BoxComponent
            width={1}            
            alignItems="flex-start"
            display="flex">
            <BoxComponent width={1} mb={8} boxShadow={1}>
                {children}
            </BoxComponent>
        </BoxComponent>
    )
}

export default BoxSettings;