import {
	ACCOUNT_CREATE,
} from '../../actions/actions';

const defaultState = {	
	data: null
};

const userReducer = (state = defaultState, action) => {

	switch (action.type) {

		case ACCOUNT_CREATE:
			return {
				...state,
				data: action.payload
			};
			
		default:
			return state;
	}
};

export default userReducer;