import {
  GET_EXERCISE_CATEGORIES,
  GET_EXERCISE_CATEGORIES_ERROR,
  GET_EXERCISE_CATEGORIES_SUCCESS
} from '../../actions/actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const exerciseCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {

      case GET_EXERCISE_CATEGORIES:
          return {
              ...state,
              loading: true
          };

      case GET_EXERCISE_CATEGORIES_SUCCESS:
          return {
              ...state,
              loading: false,
              error: null,
              data: action.payload.data
          };

      case GET_EXERCISE_CATEGORIES_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };

      default:
          return state

  }
};

export default exerciseCategoriesReducer;