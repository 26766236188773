import axios from "axios"
import { endpoints } from "../../config/api/Endpoints.conf"
import moment from "moment"

import Auth from "./user/api.auth"
import Training from "./training/api.coaching.training"
import WorkProposal from "./workProposal/api.coaching.workproposal"
import GoalKeeperWorkProposal from "./goalkeeper/api.coaching.goalkeeper"
import Note from "./notes/api.coaching.note"
import Player from "./club/api.player"
import MedicalNote from "./medical/api.medicals"


import CoachingTeamFieldWork from "./settings/api.coaching.teamfieldwork"
import CoachingExerciseCategory from "./settings/api.coaching.exercisecategory"
import CoachingExerciseImage from "./settings/api.coaching.exerciseimage"
import CoachingExerciseName from "./settings/api.coaching.exercisename"
import Club from "./club/api.club"

const API = (access_token, isMultiPart) => {
    var headers = {
        "Content-Type": isMultiPart ? 'multipart/form-data' : "application/json"
    }

    if (access_token) {
        headers.Authorization = "Bearer " + access_token
    }

    return axios.create({
        baseURL: endpoints.baseURL,
        headers: headers
    })
}

const auth = {
    login: ({ username, password }) =>
        API(undefined, true).post(endpoints.login, Auth.login({ username, password })),
    me: ({ access_token }) => API(access_token).get(endpoints.me)
}

const tools = {
    getWeather: access_token => API(access_token).get(endpoints.weather),
    getConditionField: access_token =>
        API(access_token).get(endpoints.conditionField),
    getTypologyField: access_token =>
        API(access_token).get(endpoints.typologyField)
}

const player = {
    getPlayers: access_token => API(access_token).get(endpoints.players),


    getTeams: access_token => API(access_token).get(endpoints.teams),
    getSquads: ({ access_token, date, teams }) =>
        API(access_token).get(
            endpoints.squad,
            Player.getSquads({ date, teams })
        )
}

const coaching = {
    // Training
    getTrainings: ({ access_token, team }) =>
        API(access_token).get(endpoints.training, Training.getTrainings(team)),
    getTrainingsFiltered: ({
        access_token,
        values: { start_date, end_date, team }
    }) =>
        API(access_token).get(
            endpoints.training,
            Training.getTrainingsFiltered({ start_date, end_date, team })
        ),
    addTraining: ({ access_token, values, team }) =>
        API(access_token, true).post(
            endpoints.training,
            Training.upsertTraining({ values, team })
        ),
    updateTraining: ({ access_token, values, team, id }) =>
        API(access_token, true).put(
            `${endpoints.training}${id}/`,
            Training.upsertTraining({ values, team })
        ),
    getTraining: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.training}${id}/`),
    deleteTraining: ({ access_token, id }) =>
        API(access_token).delete(`${endpoints.training}${id}/`),
    getTrainingPlan: access_token =>
        API(access_token).get(endpoints.trainingPlan),
    getObjective: access_token => API(access_token).get(endpoints.objective),
    getPitchPosition: access_token =>
        API(access_token).get(endpoints.pitchPosition),
    getCombinedPitchPosition: access_token =>
        API(access_token).get(endpoints.combinedPitchPosition),
    getTrainingRole: access_token =>
        API(access_token).get(endpoints.trainingRole),

    // Training Files
    patchTrainingFile: ({ access_token, field, file, id }) =>
        API(access_token).patch(
            `${endpoints.training}${id}/`,
            Training.patchTrainingFile({ field, file })
        ),

    // Work Proposal
    getWorkProposals: ({ access_token, id, work_proposal_type }) =>
        API(access_token).get(
            endpoints.workProposal,
            WorkProposal.getWorkProposals({ id, work_proposal_type })
        ),
    getAllWorkProposals: ({ access_token, id }) =>
        API(access_token).get(
            endpoints.workProposal,
            WorkProposal.getWorkProposals({ id })
        ),
    getExerciseCategories: access_token =>
        API(access_token).get(endpoints.exerciseCategory),
    getExerciseName: ({ access_token, categoryId }) =>
        API(access_token).get(
            endpoints.exerciseName,
            WorkProposal.getExerciseName({ categoryId })
        ),
    getExerciseImages: ({ access_token, exerciseId }) =>
        API(access_token).get(
            endpoints.exerciseImage,
            WorkProposal.getExerciseImages({ exerciseId })
        ),
    getProposals: access_token => API(access_token).get(endpoints.proposal),
    getActivities: access_token => API(access_token).get(endpoints.activity),
    getTheme: access_token => API(access_token).get(endpoints.theme),
    getWorkProposal: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.workProposal}${id}/`),
    addWorkProposal: ({ access_token, values, id }) =>
        API(access_token, true).post(
            endpoints.workProposal,
            WorkProposal.upsertWorkProposal({ values, training: id })
        ),
    updateWorkProposal: ({ access_token, values, training, id }) =>
        API(access_token, true).put(
            `${endpoints.workProposal}${id}/`,
            WorkProposal.upsertWorkProposal({ values, training, id })
        ),
    deleteWorkProposal: ({ access_token, id }) =>
        API(access_token).delete(`${endpoints.workProposal}${id}/`),
    orderWorkProposal: ({ access_token, number, id }) =>
        API(access_token).patch(
            `${endpoints.workProposal}${id}/`,
            WorkProposal.orderWorkProposal({ number })
        ),
    getWorkProposalFilter: ({ access_token, values }) =>
        API(access_token).get(
            endpoints.trainingDesk,
            WorkProposal.getWorkProposalFilter({ values })
        ),

    getExerciseDesk: ({ access_token, values }) =>
        API(access_token).get(
            endpoints.exerciseDesk,
            WorkProposal.getExerciseFilter({ values })
        ),
    getExerciseGoalkeeperDesk: ({ access_token, values }) =>
        API(access_token).get(
            endpoints.exerciseGoalkeeperDesk,
            WorkProposal.getExerciseGoalkeeperFilter({ values })
        ),



    // Notes
    getTrainingNotes: ({ access_token }) =>
        API(access_token).get(endpoints.trainingNote),
    getTrainingNote: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.trainingNote}${id}/`),
    getTrainingNotesFilter: ({ access_token, id }) =>
        API(access_token).get(
            endpoints.trainingNote,
            Note.getTrainingNotesFilter({ id })
        ),
    addTrainingNote: ({ access_token, values, id }) =>
        API(access_token, true).post(
            endpoints.trainingNote,
            Note.upsertTrainingNotes({ values, training: id })
        ),
    updateTrainingNote: ({ access_token, values, training, id }) =>
        API(access_token, true).put(
            `${endpoints.trainingNote}${id}/`,
            Note.upsertTrainingNotes({ values, id, training })
        ),
    deleteTraningNote: ({ access_token, id }) =>
        API(access_token).delete(`${endpoints.trainingNote}${id}/`),
    setReadTrainingNote: ({ access_token, values }) =>
        API(access_token).put(
            `${endpoints.trainingNote}${values.id}/`,
            Note.upsertTrainingNotes({
                values,
                id: values.id,
                training: values.training,
                isRead: true
            })
        ),

    // GoalKeepers
    getWorkoutGoalkeeper: access_token =>
        API(access_token).get(endpoints.workout),
    getThemeGoalkeeper: access_token => API(access_token).get(endpoints.theme),
    getWorkoutModalityGoalkeeper: access_token =>
        API(access_token).get(endpoints.workoutModality),
    getKickTypeGoalkeeper: access_token =>
        API(access_token).get(endpoints.kickType),
    getBallTypeGoalkeeper: access_token =>
        API(access_token).get(endpoints.ballType),
    getTeamFieldWorkGoalkeeper: access_token =>
        API(access_token).get(endpoints.teamField),
    getGymWorkGoalkeeper: access_token =>
        API(access_token).get(endpoints.gymWork),
    getLimbGoalkeeper: access_token => API(access_token).get(endpoints.limb),
    getEquipmentGoalkeeper: access_token =>
        API(access_token).get(endpoints.equipment),
    getPsychokineticLightWorkGoalkeeper: access_token =>
        API(access_token).get(endpoints.psychokineticLightWork),
    getSandWorkGoalkeeper: access_token =>
        API(access_token).get(endpoints.sandWork),
    getGoalKeeperWorkProposals: ({ access_token, id }) =>
        API(access_token).get(
            endpoints.workProposalGoalkeeper,
            GoalKeeperWorkProposal.getGoalKeeperWorkProposals({ id })
        ),
    addGoalKeeperWorkProposal: ({ access_token, values, id }) =>
        API(access_token, true).post(
            endpoints.workProposalGoalkeeper,
            GoalKeeperWorkProposal.upsertGoalKeeperWorkProposal({
                access_token,
                values,
                training: id
            })
        ),
    updateGoalKeeperWorkProposal: ({ access_token, values, training, id }) =>
        API(access_token, true).put(
            `${endpoints.workProposalGoalkeeper}${id}/`,
            GoalKeeperWorkProposal.upsertGoalKeeperWorkProposal({
                access_token,
                values,
                training,
                id
            })
        ),
    getGoalKeeperWorkProposal: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.workProposalGoalkeeper}${id}/`),
    deleteGoalKeeperWorkProposal: ({ access_token, id }) =>
        API(access_token).delete(`${endpoints.workProposalGoalkeeper}${id}/`),
    orderGoalKeeperWorkProposal: ({ access_token, number, id }) =>
        API(access_token, true).patch(
            `${endpoints.workProposal}${id}/`,
            GoalKeeperWorkProposal.orderGoalKeeperWorkProposal({ number })
        ),

    //settings
    addTeamFieldWork: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.teamField,
            CoachingTeamFieldWork.upsertTeamFieldWork({ values })
        ),
    updateTeamFieldWork: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.teamField}${id}/`,
            CoachingTeamFieldWork.upsertTeamFieldWork({ values, id })
        ),

    addExerciseCategory: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.exerciseCategory,
            CoachingExerciseCategory.upsertExerciseCategory({ values })
        ),
    updateExerciseCategory: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.exerciseCategory}${id}/`,
            CoachingExerciseCategory.upsertExerciseCategory({ values, id })
        ),

    addExerciseImage: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.exerciseImage,
            CoachingExerciseImage.upsertExerciseImage({ values })
        ),
    updateExerciseImage: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.exerciseImage}${id}/`,
            CoachingExerciseImage.upsertExerciseImage({ values, id })
        ),

    addExerciseName: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.exerciseName,
            CoachingExerciseName.upsertExerciseName({ values })
        ),
    updateExerciseName: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.exerciseName}${id}/`,
            CoachingExerciseName.upsertExerciseName({ values, id })
        )
}

const medicals = {
    // Medical Report
    getMedicalNotesFilter: ({ access_token, values }) =>
        API(access_token).get(
            endpoints.medicalNote,
            MedicalNote.getMedicalNotesFilter({ values })
        ),
    getMedicalNote: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.medicalNote}${id}/`),
    addMedicalNote: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.medicalNote,
            MedicalNote.upsertMedicalNote({ values })
        ),
    deleteMedicalNote: ({ access_token, id }) =>
        API(access_token).delete(`${endpoints.medicalNote}${id}/`),
    updateMedicalNote: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.medicalNote}${id}/`,
            MedicalNote.upsertMedicalNote({ values, id })
        ),

    getMedicalNotes: access_token =>
        API(access_token).get(endpoints.medicalNote),
    getTherapy: access_token => API(access_token).get(endpoints.therapy),
    getAvaibility: access_token => API(access_token).get(endpoints.avaibility),
    getWorkProgram: access_token =>
        API(access_token).get(endpoints.workProgram),
    getInjuryType: access_token => API(access_token).get(endpoints.injuryType),

    getInjuryStatistics: ({ access_token, values }) => {
        const startDate = moment(values.start_date).format("YYYY-MM-DD")
        const endDate = moment(values.end_date).format("YYYY-MM-DD")

        var params = new URLSearchParams()
        params.append("start_date", startDate + "T00:00:00Z")
        params.append("end_date", endDate + "T23:59:59Z")

        var request = {
            params: params
        }

        return API(access_token).get(endpoints.injuryStatistics, request)
    },

    //settings
    addWorkProgram: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.workProgram,
            MedicalNote.upsertWorkProgram({ values })
        ),
    updateWorkProgram: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.workProgram}${id}/`,
            MedicalNote.upsertWorkProgram({ values, id })
        ),

    addInjuryType: ({ access_token, values }) =>
        API(access_token, true).post(
            endpoints.injuryType,
            MedicalNote.upsertInjuryType({ values })
        ),
    updateInjuryType: ({ access_token, values, id }) =>
        API(access_token, true).put(
            `${endpoints.injuryType}${id}/`,
            MedicalNote.upsertInjuryType({ values, id })
        )
}

const club = {
    getCompetitions: access_token =>
        API(access_token).get(endpoints.clubCompetitions),
    getClubTeams: access_token => API(access_token).get(endpoints.clubTeams),
    getClubTeam: ({ access_token, id }) =>
        API(access_token).get(`${endpoints.clubTeams}${id}/`),
    getClubCategories: ({ access_token, team }) =>
        API(access_token).get(
            endpoints.clubCategories,
            Club.getClubCategories({ team })
        ),
    getClubLinks: ({ access_token, category }) => {
        return API(access_token).get(
            endpoints.clubLinks,
            Club.getClubLinks({ category })
        )
    }
}

export default {
    club,
    auth,
    tools,
    coaching,
    player,
    medicals,
}
