import React from 'react';
import { withFormContext } from '../../hoc';
import { ButtonComponent } from '../../../ui/button/Button.component';

const FormActions = ({
    formContext,
    onSubmit,
    onDelete,
    onPrint,
    onReset,
    submitting,
    pristine,
}) => {

    const {
        edit,
        footer,
    } = formContext;

    return (
        footer ?
            <div style={{ paddingRight: '16px', display: 'flex', justifyContent: 'flex-end' }}>
                {footer.columns.map((item, index) => {
                    switch (item.component) {
                        case 'ButtonSubmit':
                            return (
                                <ButtonComponent
                                    key={index}
                                    variant={item.variant}
                                    color={item.color}
                                    title={item.title}
                                    disabled={false}
                                    action={event => {
                                        onSubmit(event)
                                    }} />
                            )
                        case 'ButtonReset':
                            return (
                                <ButtonComponent
                                    key={index}
                                    variant={item.variant}
                                    color={item.color}
                                    title={item.title}
                                    disabled={false}
                                    action={event => {
                                        onReset(event)
                                    }} />
                            )
                        case 'ButtonDelete':
                            return (
                                edit && <ButtonComponent
                                    key={index}
                                    variant={item.variant}
                                    color={item.color}
                                    title={item.title}
                                    disabled={false}
                                    action={event => {
                                        onDelete(event)
                                    }} />
                            )
                        case 'ButtonPrint':
                            return (
                                edit && <ButtonComponent
                                    key={index}
                                    variant={item.variant}
                                    color={item.color}
                                    title={item.title}
                                    disabled={false}
                                    action={event => {
                                        onPrint(event)
                                    }} />
                            )
                        default:
                            return null
                    }
                })}
            </div> : null
    )
};

export default withFormContext(FormActions);
