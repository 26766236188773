import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Avatar.component.style';
import Avatar from '@material-ui/core/Avatar';

const AvatarComponent = ({
    classes,
    src,
    type = 'small'
}) => {

    return (
        <Avatar
            src={src}
            className={classes[type]}
        />
    );
}

export default withStyles(styles)(AvatarComponent);
