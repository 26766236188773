export const DrawerConf = {
    list: [
        {
            title: 'Training',
            icon: 'IconTrainingComponent',
            nested: [{
                title: 'Training Desk',
                icon: 'IconInjuryDeskComponent',
                link: '/training_desk'
            },
            {
                title: 'Exercise Desk',
                icon: 'IconInjuryDeskComponent',
                link: '/exercise_desk'
            },
            {
                title: 'Goalkeeper Desk',
                icon: 'IconInjuryDeskComponent',
                link: '/goalkeeper_exercise_desk'
            }]
        },
        // {
        //     title: 'Next Match',
        //     icon: 'IconNextMatchComponent',
        //     link: '/next_match'
        // },
        {
            title: 'Competitions',
            icon: 'IconEventsMatchComponent',
            link: '/competitions',
            divider: true
        },
        {
            title: 'Medical Area',
            icon: 'IconMedicalComponent',
            divider: true,
            nested: [
                {
                    title: 'Medical Notes',
                    icon: 'IconMedicalNotesComponent',
                    link: '/medical_area'
                },
                {
                    title: 'Injury Desk',
                    icon: 'IconInjuryDeskComponent',
                    link: '/injury_desk'
                },
                {
                    title: 'Daily Report',
                    icon: 'IconListComponent',
                    link: '/daily_report'
                },
                {
                    title: 'Statistics',
                    icon: 'IconStatisticsComponent',
                    link: '/medical_statistics'
                }
            ]
        },
        {
            title: 'Settings',
            icon: 'IconSettingsComponent',
            link: '/settings',            
        },
    ]
};