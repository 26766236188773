import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./CellText.component.style"
import TableCell from "@material-ui/core/TableCell"

const CellTextTemplate = ({ label, align }) => {
    return <TableCell align={align}>{label}</TableCell>
}

export const CellTextComponent = withStyles(styles)(CellTextTemplate)
