import React from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { accountSignIn } from "../../redux/actions/actions";
import { selectUser, selectAuth } from "../../redux/selectors/selectors";

import { LoginConf } from '../../config/login/Login.conf';

import { EditText } from '../../components/form/ui/editText';
import FormProvider from '../../components/form/provider';
import Form from '../../components/form/ui';
import { LoginTemplate } from '../../template/login/Login.template';
import ProgressComponent from '../../components/ui/progress/Progress.component';

const LoginPage = ({
	auth,
	user,
	signIn
}) => {

	const {
		body,
		footer
	} = LoginConf;

	const [cookies, setCookie] = useCookies(['access_token']);

	const onSubmit = values => {
		signIn(values)
	};

	if (user.data && auth.data) {
		setCookie('token', JSON.stringify(auth.data), { path: '/' })
	}

	return (
		<>
			{user.loading || auth.loading ? <ProgressComponent /> : null}
			<LoginTemplate>
				<FormProvider
					onSubmit={onSubmit}
					body={body}
					footer={footer}
					initialValues={{}}>
					<Form>
						<EditText item={body.username} />
						<EditText item={body.password} />
					</Form>
				</FormProvider>
			</LoginTemplate>
		</>
	);
};

const mapStateToProps = state => ({
	user: selectUser(state),
	auth: selectAuth(state),
});

const mapDispatchToProps = dispatch => ({
	signIn: (login) => {
		dispatch(accountSignIn(login));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
