import { appColor } from '../../../../config/colors/colors';

export const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-left',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),        
        padding: theme.spacing(1),        
        width: '100%'                
    },
    separator:{
        width: theme.spacing(0.8),
        background: appColor.other.main,
        height: theme.spacing(6)
    },
    title:{
        flexGrow: 1,
        marginLeft: theme.spacing(2),
        color: appColor.primary.main,
        fontWeight: 'bold',
        textAlign: 'left',
        fontSize: 'x-large'        
    },   
});