import { put, all, call, takeLatest } from 'redux-saga/effects';
// import _groupBy from 'lodash/groupBy';
import {
    getWorkProposals,
    getWorkProposalsError,
    getWorkProposalsSuccess,

    orderWorkProposals,

    getWorkProposal,
    addWorkProposal,
    updateWorkProposal,
    deleteWorkProposal,
    resetWorkProposal,

    actionWorkProposalReset,
    actionWorkProposalError,
    actionWorkProposalSuccess,

} from '../../actions/actions';

import API from '../../../services/axios/api';
import { getWorkProposalSettings, getWorkProposalSettingsSuccess, getWorkProposalSettingsError } from '../../actions/workProposal';
import { getGoalkeeperWorkProposalSettings, getGoalkeeperWorkProposalSettingsSuccess, getGoalkeeperWorkProposalSettingsError } from '../../actions/workProposal';


function* refreshList({ access_token, id }) {

    try {
        // Fetch List
        let list = yield call(API.coaching.getAllWorkProposals, { access_token, id });
        yield put(getWorkProposalsSuccess({ data: list.data.data }))

    } catch (e) {
        yield put(getWorkProposalsError(e))
    }
}

function* getWorkProposalsSaga(action) {

    const { access_token, id } = action.payload;
    yield call(refreshList, { access_token, id })
}


function* getWorkProposalsSettingsSaga(action) {

    const { access_token, } = action.payload

    try {
        const yields = [
            call(API.coaching.getExerciseCategories, access_token),
            call(API.coaching.getExerciseName, { access_token }),
            call(API.coaching.getTheme, access_token),
            call(API.player.getPlayers, access_token),
        ]
        const [categories, names, themes, players] = yield all(yields)
        yield put(getWorkProposalSettingsSuccess({
            data: {
                categories: categories.data.data,
                names: names.data.data,
                themes: themes.data.data,
                players: players.data.data.filter(player => player.role.id !== 1)
            }
        }))

    } catch (e) {
        yield put(getWorkProposalSettingsError(e))
    }
}

function* getWorkProposalsSettingsGoalkeeperSaga(action) {

    const { access_token, } = action.payload

    try {
        const yields = [
            call(API.coaching.getTheme, access_token),
            call(API.coaching.getWorkoutGoalkeeper, access_token),
            call(API.coaching.getWorkoutModalityGoalkeeper, access_token),
            call(API.coaching.getKickTypeGoalkeeper, access_token),
            call(API.coaching.getBallTypeGoalkeeper, access_token),
            call(API.coaching.getTeamFieldWorkGoalkeeper, access_token),
            call(API.coaching.getGymWorkGoalkeeper, access_token),
            call(API.coaching.getLimbGoalkeeper, access_token),
            call(API.coaching.getEquipmentGoalkeeper, access_token),
            call(API.coaching.getPsychokineticLightWorkGoalkeeper, access_token),
            call(API.coaching.getSandWorkGoalkeeper, access_token),
            call(API.player.getPlayers, access_token),
        ]
        const [themes, workout, workoutModality, kickType, ballType, teamField, gym, limb, equipment, psychokineticLight, sand, players] = yield all(yields)
        yield put(getGoalkeeperWorkProposalSettingsSuccess({
            data: {
                themes: themes.data.data,
                workout: workout.data.data,
                workoutModality: workoutModality.data.data,
                kickType: kickType.data.data,
                ballType: ballType.data.data,
                teamField: teamField.data.data,
                gym: gym.data.data,
                limb: limb.data.data,
                equipment: equipment.data.data,
                psychokineticLight: psychokineticLight.data.data,
                sand: sand.data.data,
                players: players.data.data.filter(player => player.role.id === 1)
            }
        }))

    } catch (e) {
        yield put(getGoalkeeperWorkProposalSettingsError(e))
    }
}


function* getWorkProposalSaga(action) {

    const { access_token, id, callback = () => { } } = action.payload;

    try {
        // GET 
        let { data } = yield call(API.coaching.getWorkProposal, { access_token, id });
        yield put(actionWorkProposalSuccess(data.data))
    } catch (e) {
        yield put(actionWorkProposalError(e))
    }
    callback()
}

function* addWorkProposalSaga(action) {

    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        // Add 
        let add = yield call(API.coaching.addWorkProposal, { access_token, values, id });
        yield put(actionWorkProposalSuccess(add.data.data))
    } catch (e) {
        yield put(actionWorkProposalError(e))
    }
    yield call(refreshList, { access_token, id })
    callback()
}

function* deleteWorkProposalSaga(action) {

    const { access_token, id, training } = action.payload;

    try {
        // Delete
        yield call(API.coaching.deleteWorkProposal, { access_token, id });
        yield put(actionWorkProposalSuccess())
    } catch (e) {
        yield put(actionWorkProposalError(e))
    }

    yield call(refreshList, { access_token, id: training })
}



function* updateWorkProposalSaga(action) {

    const { access_token, values, training, id, callback = () => { } } = action.payload;

    try {
        // Update 
        let update = yield call(API.coaching.updateWorkProposal, { access_token, values, training, id });
        yield put(actionWorkProposalSuccess(update.data.data))
    } catch (e) {
        yield put(actionWorkProposalError(e))
    }

    yield call(refreshList, { access_token, id: training })
    callback()
}

function* orderWorkProposalsSaga(action) {

    const { access_token, items, training } = action.payload

    const yields = items.map(({ number, id }) => call(API.coaching.orderWorkProposal, { access_token, number, id }))
    yield all(yields)
    yield call(refreshList, { access_token, id: training })
}

function* resetWorkProposalsSaga() {
    yield put(actionWorkProposalReset())
}




export function* workProposalsWatcherSaga() {
    yield takeLatest(getWorkProposals, getWorkProposalsSaga);
    yield takeLatest(orderWorkProposals, orderWorkProposalsSaga);
    yield takeLatest(getWorkProposal, getWorkProposalSaga);
    yield takeLatest(addWorkProposal, addWorkProposalSaga);
    yield takeLatest(updateWorkProposal, updateWorkProposalSaga);
    yield takeLatest(deleteWorkProposal, deleteWorkProposalSaga);
    yield takeLatest(resetWorkProposal, resetWorkProposalsSaga);
    yield takeLatest(getWorkProposalSettings, getWorkProposalsSettingsSaga);
    yield takeLatest(getGoalkeeperWorkProposalSettings, getWorkProposalsSettingsGoalkeeperSaga);
}
