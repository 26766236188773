import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';
import { appColor } from '../../../config/colors/colors'

const ExerciseRow = ({
    children,
}) => {
    return (
        <BoxComponent
            width="100%"
            display="flex"
            flexDirection="row"
            bgcolor={appColor.white}
            color={appColor.darkGrey}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseRow;