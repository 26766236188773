import {
    GET_TRAINING_NOTE,
    ADD_TRAINING_NOTE,
    UPDATE_TRAINING_NOTE,
    DELETE_TRAINING_NOTE,
    ACTION_TRAINING_NOTE_SUCCESS,
    ACTION_TRAINING_NOTE_ERROR,
    ACTION_TRAINING_NOTE_RESET
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: null
};

const noteReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_TRAINING_NOTE:
        case DELETE_TRAINING_NOTE:
        case GET_TRAINING_NOTE:
        case UPDATE_TRAINING_NOTE:
            return {
                ...state,
                loading: true
            };
        case ACTION_TRAINING_NOTE_RESET:
            return {
                ...state,
                data: null
            };
        case ACTION_TRAINING_NOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload? action.payload : null
            };

        case ACTION_TRAINING_NOTE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default noteReducer;