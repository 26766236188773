import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Checkbox.component.style';
import { FormControlLabel, Checkbox } from '@material-ui/core';


const CheckboxTemplate = ({
  classes,
  label,
  required = false,
  disabled = false,
  inputRef,
  input,
  meta,
  ...rest
}) => {

  return (
    <FormControlLabel
      className={classes.root}
      control={
        <Checkbox onChange={input.onChange} value={input.value} color="primary" checked={input.checked} />
      }
      label={label}
    />
  )
}

export const CheckboxComponent = withStyles(styles)(CheckboxTemplate)