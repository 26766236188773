import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Popover.component.style'
import Popover from '@material-ui/core/Popover';

const PopoverComponent = ({
    classes,
    handleClose,
    children,
    anchorEl,
    open,
}) => {

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}>
            {children}
        </Popover>
    )
}

export default withStyles(styles)(PopoverComponent);