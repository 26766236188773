import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Table.component.style';
import Table from '@material-ui/core/Table';

const TableTemplate = ({
    children,
}) => {

    return (
        <Table>
            {children}
        </Table>
    )
}

export const TableComponent = withStyles(styles)(TableTemplate)