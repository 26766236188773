export const ListConf = {
    toolbar: {
        title: "Excercise category"
    },
    head: {
        cells: [
            { label: "", component: "CellText" },
            { label: "Description", component: "CellText" }
        ]
    },
    body: {
        cells: [
            { key: "", component: "CellCheckbox" },
            { key: "name", component: "CellText" }
        ]
    },
    pagination: {
        rowsPerPageOptions: [],
        rowsPerPage: 5,
        page: 0
    }
}
