import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { styles } from './Modal.component.style';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const ModalTemplate = ({
    children,    
    fullWidth,
    maxWidth,
    fullScreen,
    open,
    onModalAction
}) => {

    return (
        <Dialog
            disableBackdropClick
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            open={open}
            onClose={onModalAction}>
            {children}
        </Dialog>
    )
}


const ModalTemplateWithHoc = compose(
    withMobileDialog(),
    withStyles(styles),
)(ModalTemplate);

export default ModalTemplateWithHoc;