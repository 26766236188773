import { takeLatest, put, call } from "redux-saga/effects"
import {
    getClubCategories,
    getClubCategoriesSuccess,
    getClubCategoriesError
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* getClubCategoriesSaga(action) {
    const { access_token, team } = action.payload
    try {
        let response = yield call(API.club.getClubCategories, {
            access_token,
            team
        })
        yield put(getClubCategoriesSuccess(response.data))
    } catch (e) {
        yield put(getClubCategoriesError(e))
    }
}

export function* clubCategoriesWatcherSaga() {
    yield takeLatest(getClubCategories, getClubCategoriesSaga)
}
