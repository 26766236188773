import React, { useState, useEffect } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable
} from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

const getListStyle = () => ({
    width: '100%'
});

const getItemStyle = (draggableStyle) => ({
    userSelect: "none",
    // padding: 8 * 2,
    margin: `${8}px 0 0 0 0`,
    ...draggableStyle
});

const DragAndDropList = ({
    rows= [],
    disabled= false,
    renderItem,
    dragEndCallback= () => {}
}) => {

    const [items, setItems] = useState([])    
    useEffect(() => {
        setItems(rows)
    }, [rows]);

    const onDragEnd = (result) => {        
        if (!result.destination) {
            return;
        }
        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );
        setItems(newItems)
        dragEndCallback(newItems)
    }


    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}>
                        {rows.map((item, index) => (
                            <Draggable key={item.id} draggableId={`${item.id}`} index={index} isDragDisabled={disabled}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            provided.draggableProps.style,
                                        )}>
                                        {renderItem(item)}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default DragAndDropList