import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { styles } from './Container.component.style';

const ContainerTemplate = ({
    classes,    
    children,
}) => {

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="xl" className={classes.container}>
                <Grid container>
                    {children}
                </Grid>
            </Container>
        </main>
    );
}

export const ContainerComponent = withStyles(styles)(ContainerTemplate);