import React from 'react';
import { withListContext } from '../../hoc';
import { PaginationComponent } from '../../../ui/table/pagination/Pagination.component';

const ListPagination = ({ listContext }) => {

    const {        
        actions: {
            onChangePage,
            onChangeRowsPerPage,
        },
        rowsPerPageOptions,
        rowsPerPage,
        page,
        count,
    } = listContext;

    return (
        <PaginationComponent
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage} />
    )
};

export default withListContext(ListPagination);