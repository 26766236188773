import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './MenuIcon.component.style';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const MenuItemIconTemplate = ({
    children,
    classes,
    selected
}) => {

    return (
        <ListItemIcon className={classes.icon}>
            {children}
        </ListItemIcon>
    )
}

export const MenuItemIconComponent = withStyles(styles)(MenuItemIconTemplate)