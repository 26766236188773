import {
    GET_PLAYERS,
    GET_PLAYERS_ERROR,
    GET_PLAYERS_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: []
};

const playersReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_PLAYERS:
            return {
                ...state,
                loading: true
            };

        case GET_PLAYERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_PLAYERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default playersReducer;