import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getTeamFieldWorks,
    getTeamFieldWorksSuccess,
    getTeamFieldWorksError,
    addTeamFieldWork,
    updateTeamFieldWork ,
    actionTeamFieldWorkSuccess,
    actionTeamFieldWorkError
} from '../../actions/actions';

import API from '../../../services/axios/api';

function* refreshList(access_token) {

    try {
        // Fetch List
        let response = yield call(API.coaching.getTeamFieldWorkGoalkeeper, access_token);
        yield put(getTeamFieldWorksSuccess(response.data))

    } catch (e) {
        yield put(getTeamFieldWorksError(e))
    }
}

function* getTeamFieldWorksSaga(action) {
    const { access_token } = action.payload

    yield call(refreshList, access_token);
}


function* addTeamFieldWorkSaga(action) {

    const { access_token, values, callback = () => { } } = action.payload;

    try {
        // Add Work Program
        let add = yield call(API.coaching.addTeamFieldWork, { access_token, values });
        yield put(actionTeamFieldWorkSuccess(add.data.data))
    } catch (e) {
        yield put(actionTeamFieldWorkError(e))
    }
    
    yield call(refreshList, access_token);
    callback()
}

function* updateTeamFieldWorkSaga(action) {

    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        // Update Training
        let update = yield call(API.coaching.updateTeamFieldWork, { access_token, values, id });
        yield put(actionTeamFieldWorkSuccess(update.data.data))
    } catch (e) {
        yield put(actionTeamFieldWorkError(e))
    }
    
    yield call(refreshList, access_token);
    callback()
}

export function* teamFieldWorksWatcherSaga() {
    yield takeLatest(getTeamFieldWorks, getTeamFieldWorksSaga);
    yield takeLatest(addTeamFieldWork, addTeamFieldWorkSaga);
    yield takeLatest(updateTeamFieldWork, updateTeamFieldWorkSaga);
}