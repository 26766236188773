//import Home from '../../../assets/home.png'
//import HomeFootureDesk from '../../assets/footuredesk.png'
import HomeFootureDesk from '../../assets/footuredesk.png'

export const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${HomeFootureDesk})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.primary.main,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});