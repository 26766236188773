import React from 'react';
import { connect } from 'react-redux';

import { addTraining } from "../../redux/actions/actions";
import { selectTraining } from "../../redux/selectors/selectors";

import { NewTrainingConf } from '../../config/training/NewTraining.conf';
import TrainingForm from '../../components/training/form';
import ProgressComponent from '../../components/ui/progress/Progress.component';


const NewTrainingPage = ({
    training,
    onSubmit = () => { },
    onDelete = () => { },
    initialValues,
}) => {

    const {
        body,
        footer,
    } = NewTrainingConf;

    return (
        <>
            {training.loading ? <ProgressComponent /> : null}
            <TrainingForm
                body={body}
                footer={footer}
                onSubmit={onSubmit}
                onDelete={onDelete}
                initialValues={initialValues}
            />
        </>
    );
};

const mapStateToProps = state => ({
    training: selectTraining(state),
});

const mapDispatchToProps = dispatch => ({
    addTraining: (body) => {
        dispatch(addTraining(body));
    },
});


export default connect(mapStateToProps, mapDispatchToProps)(NewTrainingPage);