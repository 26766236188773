import React from 'react';
import { withModalContext } from '../../hoc';
import ModalContentComponent from '../../../ui/modal/content/ModalContent.component';

const ModalContent = ({
    children
}) => {

    return (
        <ModalContentComponent>
            {children}
        </ModalContentComponent>
    )
}

export default withModalContext(ModalContent);