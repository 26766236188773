import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import {
    getWorkPrograms,
} from "../../../redux/actions/actions"

import { selectWorkPrograms } from "../../../redux/selectors/selectors"
import { withAuthContext } from "../../../contexts/auth/Auth.context"

import ProgressComponent from "../../../components/ui/progress/Progress.component"

import { ListConf } from "../../../config/settings/WorkProgram.config"

import ListSettings from '../../../components/settings/list';
import FormModal from "../../../components/form/ui/modal"
import useModal from "../../../hooks/useModal"

import NewWorkProgramPage from './NewWorkProgram.container';




const WorkProgramPage = ({
    authContext,
    workPrograms,
    enabled,
    getWorkPrograms,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal()
    const [element, setElement] = useState(null)

    useEffect(() => {
        getWorkPrograms({ access_token: authContext.access_token })
    }, [])

    return (
        <>
            {workPrograms.loading ? <ProgressComponent /> : null}
            <ListSettings
                data={workPrograms.data}
                conf={ListConf}
                enabled={enabled}
                onAddElement={() => {
                    openModal()
                }}
                onEditElement={(ids) => {
                    setElement(ids.length > 0 ? workPrograms.data.find(item => item.id === ids[0]) : null)
                    openModal()
                }} />
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setElement(null)
                    closeModal()
                }}
                onSave={() => {
                    setElement(null)
                    closeModal()
                }}
                title={element ? 'Update' : 'Add'}>
                <NewWorkProgramPage
                    item={element}
                    onDismiss={() => {
                        setElement(null)
                        closeModal()
                    }} />
            </FormModal>
        </>
    )
}

const mapStateToProps = state => ({
    workPrograms: selectWorkPrograms(state)
})

const mapDispatchToProps = dispatch => ({
    getWorkPrograms: values => {
        dispatch(getWorkPrograms(values))
    },
})

export default withAuthContext(
    connect(mapStateToProps, mapDispatchToProps)(WorkProgramPage)
)
