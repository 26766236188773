import React, { PureComponent } from "react"
import { useCookies } from "react-cookie"

// Context
export const AuthContext = React.createContext()

export const UserType = {
    Admin: 0,
    Technician: 1,
    Medical: 2,
    Manager: 3,
    DataCoach: 4,
    GoalkeeperCoach: 5,
    SecondTeamManager: 6,
    None: 7
}

// Provider
export const AuthProvider = ({ children, user }) => {
    const [cookies, setCookie] = useCookies(["access_token"])

    // console.log("user", user)

    let userType = UserType.None

    if (user && user.data && user.data.group) {
        // console.log("group", user.data.group.id)
        switch (user.data.group.id) {
            case 1:
                userType = UserType.Technician
                break
            case 2:
                userType = UserType.Medical
                break
            case 3:
                userType = UserType.Manager
                break
            case 4:
                userType = UserType.DataCoach
                break
            case 5:
                userType = UserType.GoalkeeperCoach
                break
            case 6:
                userType = UserType.SecondTeamManager
                break
            default:
                userType = UserType.None
                break
        }
    } else {
        userType = UserType.Admin
    }

    return (
        <AuthContext.Provider
            value={{
                authContext: {
                    access_token: cookies.token.access_token,
                    type: userType
                }
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

// Consumer
export const AuthConsumer = AuthContext.Consumer

// HOC
export function withAuthContext(WrappedComponent) {
    return class extends PureComponent {
        render() {
            return (
                <AuthContext.Consumer>
                    {contexts => (
                        <WrappedComponent {...contexts} {...this.props} />
                    )}
                </AuthContext.Consumer>
            )
        }
    }
}
