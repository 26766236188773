import {
	ACCOUNT_SIGNIN,
	ACCOUNT_SIGNIN_SUCCESS,
	ACCOUNT_SIGNIN_FAILURE,
	ACCOUNT_SIGNOUT,	
} from '../../actions/actions';

const defaultState = {
	loading: false,
	error: null,
	data: null
};

const authReducer = (state = defaultState, action) => {

	switch (action.type) {

		case ACCOUNT_SIGNIN:
			return {
				...state,
				loading: true
			};

		case ACCOUNT_SIGNIN_SUCCESS:

			return {
				...state,
				loading: false,
				error: null,
				data: action.payload
			};

		case ACCOUNT_SIGNIN_FAILURE:
			
			return {
				...state,
				loading: false,
				error: action.payload,
				data: null
			};

		case ACCOUNT_SIGNOUT:
			return {
				...state,
				loading: false,
				error: null,
				data: null
			};		
			
		default:
			return state;
	}
};

export default authReducer;
