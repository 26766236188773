import _groupBy from 'lodash/groupBy'
import {
    GET_WORK_PROPOSAL_SETTINGS,
    GET_WORK_PROPOSAL_SETTINGS_SUCCESS,
    GET_WORK_PROPOSAL_SETTINGS_ERROR
} from '../../actions/actions'


const initialState = {
    loading: false,
    error: null,
    data: {}
};

const workProposalSettingsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_WORK_PROPOSAL_SETTINGS:
            return {
                ...state,
                loading: true
            };

        case GET_WORK_PROPOSAL_SETTINGS_SUCCESS:
            const { data } = action.payload;
            
            return {
                ...state,
                loading: false,
                error: null,
                data: data
            };

        case GET_WORK_PROPOSAL_SETTINGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default workProposalSettingsReducer;