import React, { useEffect, useState } from "react"

import {Document, Page, pdfjs} from "react-pdf"

import { Box } from "@material-ui/core"

import {
    IconPrevComponent,
    IconNextComponent
} from "../../ui/icon/Icon.component"

const FilePdfRender = ({ file }) => {
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.js`
    }, [])

    const [numPages, setNumPages] = useState(null)
    const [pageNum, setPageNumber] = useState(1)
    const [pageHeight, setPageHeight] = useState(800)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    const onDocumentLoadError = error => {
        console.log("FilePdfRender error", error)
    }

    return (
        <>
            <Box
                width="100%"
                height={pageHeight}
                display="flex"
                overflow="hidden"
            >
                <Document
                    file={file.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={onDocumentLoadError}
                >
                    <Page
                        pageNumber={pageNum}
                        onLoadSuccess={({ originalHeight }) => {
                            setPageHeight(originalHeight)
                        }}
                        height={pageHeight}
                    />
                </Document>
            </Box>
            {numPages > 1 && (
                <Box p={2}>
                    <IconPrevComponent
                        action={() => setPageNumber(pageNum - 1)}
                        disabled={pageNum > 1 ? false : true}
                    />
                    <span>
                        Page {pageNum} of {numPages}
                    </span>
                    <IconNextComponent
                        action={() => setPageNumber(pageNum + 1)}
                        disabled={pageNum < numPages ? false : true}
                    />
                </Box>
            )}
        </>
    )
}

export default FilePdfRender
