import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getExerciseCategories,
    getExerciseCategoriesSuccess,
    getExerciseCategoriesError,
    addExerciseCategory,
    updateExerciseCategory,
    actionExerciseCategoryError,
    actionExerciseCategorySuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';

function* refreshList(access_token) {

    try {
        // Fetch List
        let response = yield call(API.coaching.getExerciseCategories, access_token);
        yield put(getExerciseCategoriesSuccess(response.data))

    } catch (e) {
        yield put(getExerciseCategoriesError(e))
    }
}

function* getExerciseCategoriesSaga(action) {
    const { access_token } = action.payload

    yield call(refreshList, access_token);
}

function* addExerciseCategorySaga(action) {

    const { access_token, values, callback = () => { } } = action.payload;

    try {
        let add = yield call(API.coaching.addExerciseCategory, { access_token, values });
        yield put(actionExerciseCategorySuccess(add.data.data))
    } catch (e) {
        yield put(actionExerciseCategoryError(e))
    }

    yield call(refreshList, access_token);
    callback()
}

function* updateExerciseCategorySaga(action) {

    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        let update = yield call(API.coaching.updateExerciseCategory, { access_token, values, id });
        yield put(actionExerciseCategorySuccess(update.data.data))
    } catch (e) {
        yield put(actionExerciseCategoryError(e))
    }

    yield call(refreshList, access_token);
    callback()
}

export function* exerciseCategoriesWatcherSaga() {
    yield takeLatest(getExerciseCategories, getExerciseCategoriesSaga);
    yield takeLatest(addExerciseCategory, addExerciseCategorySaga);
    yield takeLatest(updateExerciseCategory, updateExerciseCategorySaga);
}