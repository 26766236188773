import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';
import { appColor } from '../../../config/colors/colors'

const ExerciseAction = ({
    children,
}) => {
    return (
        <BoxComponent
            width="10%"
            border={1}
            borderColor={appColor.lightGrey}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseAction;