import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import {
    getExerciseImages,
} from "../../../redux/actions/actions"

import { selectExerciseImages } from "../../../redux/selectors/selectors"
import { withAuthContext } from "../../../contexts/auth/Auth.context"

import ProgressComponent from "../../../components/ui/progress/Progress.component"

import { ListConf } from "../../../config/settings/ExerciseImage.config"
import ListSettings from '../../../components/settings/list';
import FormModal from "../../../components/form/ui/modal"
import useModal from "../../../hooks/useModal"

import NewExerciseImageForm from './NewExerciseImage.container';


const ExerciseImagePage = ({
    authContext,
    exerciseImages,
    enabled,
    getExerciseImages,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal()
    const [element, setElement] = useState(null)

    useEffect(() => {
        getExerciseImages({ access_token: authContext.access_token })
    }, [])

    return (
        <>
            {exerciseImages.loading ? <ProgressComponent /> : null}
            <ListSettings
                data={exerciseImages.data}
                conf={ListConf}
                enabled={enabled}
                onAddElement={() => {
                    openModal()
                }}
                onEditElement={(ids) => {
                    setElement(ids.length > 0 ? exerciseImages.data.find(item => item.id === ids[0]) : null)
                    openModal()
                }} />
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setElement(null)
                    closeModal()
                }}
                onSave={() => {
                    setElement(null)
                    closeModal()
                }}
                title={element ? 'Update' : 'Add'}>
                <NewExerciseImageForm
                    item={element}
                    onDismiss={() => {
                        setElement(null)
                        closeModal()
                    }}/>
            </FormModal>
        </>
    )
}

const mapStateToProps = state => ({
    exerciseImages: selectExerciseImages(state)
})

const mapDispatchToProps = dispatch => ({
    getExerciseImages: values => {
        dispatch(getExerciseImages(values))
    },    
})

export default withAuthContext(
    connect(mapStateToProps, mapDispatchToProps)(ExerciseImagePage)
)
