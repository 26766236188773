export default {
    upsertExerciseName: ({ values, id }) => {
        var body = new FormData()

        if (id) {
            body.set("id", id)
        }

        if (values.name) {
            body.set("name", values.name)
        }

        if (values.category) {
            body.set("category", values.category)
        }

        return body
    }
}
