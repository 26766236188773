import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getDailyReport,
    getDailyReportSuccess,
    getDailyReportError,
} from '../../actions/actions';

import API from '../../../services/axios/api';


function* getDailyReportSaga(action) {

    const { access_token, values } = action.payload

    try {
        // Fetch List
        let response = yield call(API.medicals.getMedicalNotesFilter, { access_token, values });
        response.data.filter = values;
        yield put(getDailyReportSuccess(response.data))

    } catch (e) {
        yield put(getDailyReportError(e))
    }
}


export function* dailyReportWatcherSaga() {
    yield takeLatest(getDailyReport, getDailyReportSaga);
}