export const TypeEvent = {
    events: [
        {
            value: 1,
            label: 'Match'
        },
        {
            value: 2,
            label: 'Training'
        }
    ]
} 

export const TypeTrauma = {
    trauma_types: [
        {
            value: 1,
            label: 'Direct Trauma'
        },
        {
            value: 2,
            label: 'Indirect Trauma'
        }
    ]
} 