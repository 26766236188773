import React from 'react';
import BoxComponent from '../../components/ui/box/Box.component';
import {
    IconEditComponent,
    IconDeleteComponent
} from '../../components/ui/icon/Icon.component';

import { appColor } from '../../config/colors/colors'

const Note = ({
    item,
    onDeleteNote,
    onUpdateNote,
    disabled
}) => {

    const player = `Player: ${item.player.name}  ${item.player.surname}`

    return (
        <BoxComponent width="100%" display="flex" flexDirection="row" bgcolor={appColor.white} color={appColor.darkGrey}>

            <BoxComponent width="90%">
                <BoxComponent flexDirection="row" border={1} borderRight={0} borderColor={appColor.lightGrey} p={2} >
                    <BoxComponent textAlign="left" fontWeight="fontWeightNormal" mb={2} fontSize={18} >
                        {player}
                    </BoxComponent>
                    <BoxComponent textAlign="left" fontWeight="fontWeightRegular" fontStyle="italic" mb={2} fontSize={16}>
                        {item.description}
                    </BoxComponent>
                </BoxComponent>
            </BoxComponent>

            <BoxComponent width="10%" border={1} borderColor={appColor.lightGrey}>
                {disabled ? null :
                    <>
                        <IconDeleteComponent action={() => onDeleteNote(item.id)} />
                        <IconEditComponent action={() => onUpdateNote(item.id)} />
                    </>
                }
            </BoxComponent>


        </BoxComponent>
    )
}

export default Note;