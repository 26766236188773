import React from 'react';
import ModalProvider from '../../../modal/provider';
import Modal from '../../../modal/ui';
import ModalToolbar from '../../../modal/ui/toolbar';
import ModalContent from '../../../modal/ui/contents';

const FormModal = ({
    open,
    onCancel = () => { },
    children,
    title,
}) => {
    return (
        <ModalProvider
            disableBackdropClick={true}
            fullWidth={true}
            maxWidth={'sm'}
            open={open}
            onModalAction={() => onCancel()}
            title={title}>
            <Modal>
                <ModalToolbar />
                <ModalContent>
                    {children}
                </ModalContent>
            </Modal>
        </ModalProvider>
    )
}

export default FormModal;