export default {

    getGoalKeeperWorkProposals: ({ id }) => {
        var params = new URLSearchParams();
        params.append("training", id);
        return {
            params: params
        }
    },

    upsertGoalKeeperWorkProposal: ({ values, id, training }) => {
        var body = new FormData();

        // ID
        if (id) {
            body.set('id', id);
        }

        // Training
        if (training) {
            body.set('training', training);
        }

        // Number
        if (values.number) {
            body.set('number', values.number);
        }

        // Rest
        if (values.rest) {
            body.set('rest', values.rest);
        }

        // Duration
        if (values.duration) {
            body.set('duration', values.duration);
        }

        // WorkoutGoalkeeper
        if (values.workout_goalkeeper) {
            values.workout_goalkeeper.map(item =>
                body.set('workout_goalkeeper', item.value)
            )
        }

        // Theme
        if (values.theme) {
            values.theme.map(item =>
                body.set('theme', item.value)
            )
        }

        // Workout Modality
        if (values.workout_modality) {
            values.workout_modality.map(item =>
                body.set('workout_modality', item.value)
            )
        }

        // Kick Type
        if (values.kick_type) {
            values.kick_type.map(item =>
                body.set('kick_type', item.value)
            )
        }

        // Ball Type
        if (values.ball_type) {
            values.ball_type.map(item =>
                body.set('ball_type', item.value)
            )
        }

        // Workout Coach Group
        if (values.workout_coach_group) {
            body.set('workout_coach_group', values.workout_coach_group);
        }

        // Workout Coach Description
        if (values.workout_coach_description) {
            body.set('workout_coach_description', values.workout_coach_description);
        }

        // Team Field Work
        if (values.team_field_work) {
            values.team_field_work.map(item =>                
                body.set('team_field_work', item.value)
            )            
        }

        // Workout Team Description
        if (values.workout_team_description) {
            body.set('workout_team_description', values.workout_team_description);
        }

        // Gym work
        if (values.gym_work) {
            values.gym_work.map(item =>
                body.set('gym_work', item.value)
            )
        }

        // Limb
        if (values.limb) {
            body.set('limb', values.limb);
        }

        // Equipment
        if (values.equipment) {
            body.set('equipment', values.equipment);
        }

        // Psychokinetic light work
        if (values.psychokinetic_light_work) {
            body.set('psychokinetic_light_work', values.psychokinetic_light_work);
        }

        // Sand work
        if (values.sand_work) {
            body.set('sand_work', values.sand_work);
        }

        // Workout specific description
        if (values.workout_specific_description) {
            body.set('workout_specific_description', values.workout_specific_description);
        }

        // Players
        if (values.surname) {
            values.surname.map(item =>
                body.append('players', item.value)
            )
        }

        return body
    },

    orderGoalKeeperWorkProposal: ({ number }) => {
        var body = new FormData();
        body.set('number', number)
        return body
    },
}