import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { styles } from './ModalContent.component.style';
import DialogContent from '@material-ui/core/DialogContent';

const ModalContent = ({
    children,
    classes,
}) => {

    return (
        <DialogContent
            dividers
            className={classes.root}>
            {children}
        </DialogContent>
    )
}

const ModalContentWithHoc = compose(
    withStyles(styles),
)(ModalContent);

export default ModalContentWithHoc;