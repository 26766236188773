import { takeLatest, put, call } from "redux-saga/effects"
import {
    getExerciseImages,
    getExerciseImagesSuccess,
    getExerciseImagesError,
    addExerciseImage,
    updateExerciseImage,
    actionExerciseImageError,
    actionExerciseImageSuccess
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* refreshList(access_token) {
    try {
        // Fetch List
        let response = yield call(API.coaching.getExerciseImages, {
            access_token
        })
        yield put(getExerciseImagesSuccess(response.data))
    } catch (e) {
        yield put(getExerciseImagesError(e))
    }
}

function* getExerciseImagesSaga(action) {
    const { access_token } = action.payload

    yield call(refreshList, access_token)
}

function* addExerciseImageSaga(action) {
    const { access_token, values, callback = () => { } } = action.payload

    try {
        let add = yield call(API.coaching.addExerciseImage, {
            access_token,
            values
        })
        yield put(actionExerciseImageSuccess(add.data.data))
    } catch (e) {
        yield put(actionExerciseImageError(e))
    }

    yield call(refreshList, access_token)
    callback()
}

function* updateExerciseImageSaga(action) {
    const { access_token, values, id, callback = () => { } } = action.payload

    try {
        let update = yield call(API.coaching.updateExerciseImage, {
            access_token,
            values,
            id
        })
        yield put(actionExerciseImageSuccess(update.data.data))
    } catch (e) {
        yield put(actionExerciseImageError(e))
    }

    yield call(refreshList, access_token)
    callback()
}

export function* exerciseImagesWatcherSaga() {
    yield takeLatest(getExerciseImages, getExerciseImagesSaga)
    yield takeLatest(addExerciseImage, addExerciseImageSaga)
    yield takeLatest(updateExerciseImage, updateExerciseImageSaga)
}
