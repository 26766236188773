import {
    ADD_TRAINING,
    UPDATE_TRAINING,
    GET_TRAINING,
    ACTION_TRAINING_SUCCESS,
    ACTION_TRAINING_ERROR
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: null
};

const trainingReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TRAINING:
        case ADD_TRAINING:
        case UPDATE_TRAINING:
            return {
                ...state,
                loading: true,                
            };
        case ACTION_TRAINING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case ACTION_TRAINING_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default trainingReducer;