export const ListConf = {
    toolbar: {
        title: "Injury Type"
    },
    head: {
        cells: [
            { label: "", component: "CellText" },
            { label: "Description", component: "CellText" },            
        ]
    },
    body: {
        cells: [
            { key: "", component: "CellCheckbox" },
            { key: "injury_type", component: "CellText" },            
        ]
    },
    pagination: {
        rowsPerPageOptions: [],
        rowsPerPage: 5,
        page: 0
    }
}
