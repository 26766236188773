import React from 'react';
import BoxComponent from '../../ui/box/Box.component';
import { IconAddComponent } from '../../ui/icon/Icon.component';
import { appColor } from '../../../config/colors/colors';


// Toolbar
const TrainingBox = ({
    children,
    title,
    action,
    disabled
}) => {
    return (
        <BoxComponent
            mr={1}
            bgcolor={appColor.white}
            boxShadow={1}>
            <BoxComponent
                width='100%'
                display="flex"
                flexDirection="row"
                bgcolor={appColor.other.main}>
                <BoxComponent
                    width="90%"
                    textAlign="left"
                    fontWeight="fontWeightBold"
                    p={2}
                    fontSize={18}
                    color={appColor.white}>
                    {title}
                </BoxComponent>
                {action ?
                    <BoxComponent width="10%">
                        {disabled ? null : <IconAddComponent action={action} />}
                    </BoxComponent> : null
                }
            </BoxComponent>
            {children}
        </BoxComponent>
    )
}

export default TrainingBox