export const AppearanceGroupsConf = {
    toolbar: {
        title: 'Appearances'
    },
    head: {
        cells: [
            { label: '', component: 'CellText' },
            { label: 'N°', component: 'CellText' },
            { label: 'Name', component: 'CellText' },            
            { label: 'Role', component: 'CellText' },
            { label: 'Appearence', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'image', component: 'CellImage' },
            { key: 'number', component: 'CellText', },
            { key: 'name', otherKey:'surname', component: 'CellText' },
            { key: 'role.role', component: 'CellText' },
            { key: 'appearance', component: 'CellText' },
        ]
    },
};