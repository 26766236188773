import React from 'react';
import BoxComponent from '../../components/ui/box/Box.component';

const CalendarTemplate = ({
    toolbar,
    calendarBox,
}) => {

    return (
        <>
            <BoxComponent width={1}>
                {toolbar}
            </BoxComponent>
            <BoxComponent width={1}>
                {calendarBox}
            </BoxComponent>            
        </>
    )
}

export default CalendarTemplate