export const NewDailyReportConf = {
    body: {
        date: {
            component: 'CalendarPicker',
            name: 'start_date',
            label: 'Date',
            required: true,
            disabled: false, 
            calendarType: 'DatePicker',                       
            validation: {
                message: "This field is Required"
            },
        },
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name', 
            label: 'Players',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        },            
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};