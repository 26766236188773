import { appColor } from '../../../../config/colors/colors' 

export const styles = theme => ({
    cell: {
        textAlign: '-webkit-center',   
        width: theme.spacing(2)             
    },
    avatar: {
        backgroundColor:  appColor.lightGrey 
    }
});