import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './EditError.component.style';
import FormHelperText from '@material-ui/core/FormHelperText';

const EditErrorTemplate = ({
    classes,
    message
}) => {

    return (
        <FormHelperText className={classes.error}>
            {message}
        </FormHelperText>
    )
}

export const EditErrorComponent = withStyles(styles)(EditErrorTemplate)