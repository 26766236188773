import {
    GET_STATISTICS,
    GET_STATISTICS_ERROR,
    GET_STATISTICS_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: {
        players: [],
        overall: null,
    },
    filter: {}
};

const statisticsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_STATISTICS:
            return {
                ...state,
                loading: true
            };

        case GET_STATISTICS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data,
                filter: action.payload.filter
            };

        case GET_STATISTICS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default statisticsReducer;