import moment from "moment"

export default {
    getTrainings: ({ team }) => {
        var params = new URLSearchParams()
        params.append("team", team)
        return {
            params: params
        }
    },

    getTrainingsFiltered: ({ start_date, end_date, team }) => {
        var params = new URLSearchParams()
        params.append(
            "start_date",
            moment(start_date).format("YYYY-MM-DD")
        )
        params.append(
            "end_date",
            moment(end_date).format("YYYY-MM-DD")
        )
        params.append("team", team)

        return {
            params: params
        }
    },

    upsertTraining: ({ values, team }) => {
        var body = new FormData()

        // Date
        if (values.date) {
            body.set("date", moment(values.date).format("YYYY-MM-DD HH:mm"))
        }

        // Training Group
        body.set(
            "training_group",
            values.training_group ? values.training_group : false
        )

        // Duration
        if (values.duration) {
            body.set("duration", values.duration)
        }

        // Training Plan
        if (values.training_plan) {
            body.set("training_plan", values.training_plan)
        }

        // Pitch Position
        if (values.pitch_position) {
            values.pitch_position &&
                values.pitch_position.map(pitch_position =>
                    body.append("pitch_position", pitch_position.value)
                )
        }

        // Combined Pitch Position
        if (values.combined_pitch_position) {
            values.combined_pitch_position &&
                values.combined_pitch_position.map(combined_pitch_position =>
                    body.append(
                        "combined_pitch_position",
                        combined_pitch_position.value
                    )
                )
        }

        // Training Role
        if (values.training_role) {
            values.training_role &&
                values.training_role.map(training_role =>
                    body.append("training_role", training_role.value)
                )
        }

        // Objective
        if (values.objective) {
            values.objective &&
                values.objective.map(objective =>
                    body.append("objective", objective.value)
                )
        }

        // Note
        if (values.note) {
            body.set("note", values.note)
        }

        // Team
        body.set("team", team)

        return body
    },

    patchTrainingFile: ({ field, file }) => {
        var body = new FormData()

        body.set(field, "")
        if (file && file.length > 0) {
            body.set(field, file[0])
        }

        return body
    }
}
