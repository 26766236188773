import {
    GET_TRAINING_NOTES,
    GET_TRAINING_NOTES_ERROR,
    GET_TRAINING_NOTES_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: []
};

const notesReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TRAINING_NOTES:
            return {
                ...state,
                loading: true
            };

        case GET_TRAINING_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_TRAINING_NOTES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default notesReducer;