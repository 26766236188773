import { takeLatest, put, call } from "redux-saga/effects"
import {
    getExerciseNames,
    getExerciseNamesSuccess,
    getExerciseNamesError,
    addExerciseName,
    updateExerciseName,
    actionExerciseNameError,
    actionExerciseNameSuccess
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* refreshList(access_token) {
    try {
        let response = yield call(API.coaching.getExerciseName, {
            access_token
        })

        yield put(getExerciseNamesSuccess(response.data))
    } catch (e) {
        yield put(getExerciseNamesError(e))
    }
}

function* getExerciseNamesSaga(action) {
    const { access_token } = action.payload

    yield call(refreshList, access_token)
}

function* addExerciseNameSaga(action) {
    const { access_token, values, callback = () => { } } = action.payload

    try {
        let add = yield call(API.coaching.addExerciseName, {
            access_token,
            values
        })
        yield put(actionExerciseNameSuccess(add.data.data))
    } catch (e) {
        yield put(actionExerciseNameError(e))
    }

    yield call(refreshList, access_token)
    callback()
}

function* updateExerciseNameSaga(action) {
    const { access_token, values, id , callback = () => { }} = action.payload

    try {
        let update = yield call(API.coaching.updateExerciseName, {
            access_token,
            values,
            id
        })
        yield put(actionExerciseNameSuccess(update.data.data))
    } catch (e) {
        yield put(actionExerciseNameError(e))
    }

    yield call(refreshList, access_token)
    callback()
}

export function* exerciseNamesWatcherSaga() {
    yield takeLatest(getExerciseNames, getExerciseNamesSaga)
    yield takeLatest(addExerciseName, addExerciseNameSaga)
    yield takeLatest(updateExerciseName, updateExerciseNameSaga)
}
