import React from 'react';
import { ModalContext } from '../context';

const ModalProvider = ({
    children,
    disableBackdropClick,
    fullWidth,
    maxWidth,
    fullScreen,
    open,
    title,
    onModalAction,
    onSave,
    onCancel,
    onDelete,    
}) => {

    return (
        <ModalContext.Provider
            value={{
                modalContext: {
                    disableBackdropClick: disableBackdropClick,
                    fullWidth: fullWidth,
                    maxWidth: maxWidth,
                    fullScreen: fullScreen,
                    open: open,
                    title: title,
                    actions: {
                        onClose: onModalAction,
                        onSave: onSave,
                        onCancel: onCancel,
                        onDelete: onDelete,
                    }
                }
            }}>
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider;