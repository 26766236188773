import React from 'react';
import {
    PieChart,
    ResponsiveContainer,
    Pie,
    Cell,
    Tooltip,
    Legend,
} from 'recharts';


// Custom Label
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const PieChartComponent = ({
    data,
    colors,
    dataKey
}) => {

    return (
        <ResponsiveContainer>
            <PieChart>                
                <Pie
                    isAnimationActive={false}
                    dataKey={dataKey}
                    data={data}
                    labelLine={false}
                    label={renderCustomizedLabel}>
                    {data.map((entry, index) => <Cell fill={colors[index % colors.length]} key={index} />)}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default PieChartComponent;