import {
    GET_TRAININGS,
    GET_TRAININGS_ERROR,
    GET_TRAININGS_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: []
};

const trainingsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TRAININGS:
            return {
                ...state,
                loading: true,
                data: []
            };

        case GET_TRAININGS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_TRAININGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default trainingsReducer;