import {
  GET_WORK_PROGRAMS,
  GET_WORK_PROGRAMS_ERROR,
  GET_WORK_PROGRAMS_SUCCESS
} from '../../actions/actions'

const initialState = {
  loading: false,
  error: null,
  data: []
};

const workProgramsReducer = (state = initialState, action) => {
  switch (action.type) {

      case GET_WORK_PROGRAMS:
          return {
              ...state,
              loading: true
          };

      case GET_WORK_PROGRAMS_SUCCESS:
          return {
              ...state,
              loading: false,
              error: null,
              data: action.payload.data
          };

      case GET_WORK_PROGRAMS_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };

      default:
          return state

  }
};

export default workProgramsReducer;