import {
    GET_WORK_PROPOSAL,
    ADD_WORK_PROPOSAL,
    UPDATE_WORK_PROPOSAL,
    DELETE_WORK_PROPOSAL,
    ACTION_WORK_PROPOSAL_SUCCESS,
    ACTION_WORK_PROPOSAL_ERROR,
    ACTION_WORK_PROPOSAL_RESET
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: null
};

const workProposalReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_WORK_PROPOSAL:            
        case DELETE_WORK_PROPOSAL:
        case GET_WORK_PROPOSAL:    
        case UPDATE_WORK_PROPOSAL:
            return {
                ...state,
                loading: true
            };
        case ACTION_WORK_PROPOSAL_RESET:
            return {
                ...state,
                data: null
            };
        case ACTION_WORK_PROPOSAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload? action.payload : null
            };

        case ACTION_WORK_PROPOSAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default workProposalReducer;