import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';
import { appColor } from '../../../config/colors/colors'

const ExerciseContent = ({
    children,
}) => {
    return (
        <BoxComponent
            width="85%"
            flexDirection="row"
            border={1}
            borderRight={0}
            borderColor={appColor.lightGrey}
            p={2}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseContent;