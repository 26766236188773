import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../../../contexts/auth/Auth.context';
import ProgressComponent from '../../../../components/ui/progress/Progress.component';
import { ToolbarComponent } from '../../../../components/ui/page/toolbar/Toolbar.component';

import { selectWorkProposalGoalkeeperSettings } from "../../../../redux/selectors/selectors";
import { getGoalkeeperWorkProposalSettings } from '../../../../redux/actions/actions'

import BoxComponent from '../../../../components/ui/box/Box.component';
import GoalkeeperExerciseDeskPlayers from './GoalkeeperExerciseDeskPlayers.container';

const GoalkeeperExerciseDesk = ({
    authContext,
    settings,
    getGoalkeeperWorkProposalSettings
}) => {

    useEffect(() => {
        getGoalkeeperWorkProposalSettings({
            access_token: authContext.access_token
        })
    }, [])

    return (
        <>
            <ToolbarComponent title="Goalkeeper Desk" />
            {settings.loading ? <ProgressComponent /> : null}
            <BoxComponent mt={2} width={'100%'}>
                <GoalkeeperExerciseDeskPlayers />
            </BoxComponent>            
        </>
    )
}

const mapStateToProps = state => ({
    settings: selectWorkProposalGoalkeeperSettings(state)
});

const mapDispatchToProps = dispatch => ({
    getGoalkeeperWorkProposalSettings: (params) => {
        dispatch(getGoalkeeperWorkProposalSettings(params));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(GoalkeeperExerciseDesk));