import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./GridList.component.style"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import GridListTileBar from "@material-ui/core/GridListTileBar"
import Link from "@material-ui/core/Link"
// import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from "@material-ui/core/IconButton"
import InfoIcon from "@material-ui/icons/Info"

const GridListTemplate = ({
    classes,
    items,
    cellHeight = 250,
    onSelectItem
}) => {
    return (
        <div className={classes.root}>
            <GridList cellHeight={cellHeight} cols={3}>
                {items.map((tile, index) => (
                    <GridListTile key={index} className={classes.gridItem}>
                        <img
                            src={tile.img}
                            alt={tile.title}
                            style={{ maxHeight: "65%", maxWidth: "65%" }}
                        />
                        <GridListTileBar
                            title={tile.title}
                            actionIcon={
                                <IconButton
                                    onClick={() => {
                                        onSelectItem(tile)
                                    }}
                                    aria-label={`info about ${tile.title}`}
                                    className={classes.icon}
                                >
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    )
}

export const GridListComponent = withStyles(styles)(GridListTemplate)
