import {
    GET_COMPETITIONS,
    GET_COMPETITIONS_ERROR,
    GET_COMPETITIONS_SUCCESS
} from "../../actions/actions"

const initialState = {
    loading: false,
    error: null,
    data: []
}

const competitionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPETITIONS:
            return {
                ...state,
                loading: true,
                data: []
            }

        case GET_COMPETITIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            }

        case GET_COMPETITIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export default competitionsReducer
