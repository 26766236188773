import React from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from "../../../contexts/auth/Auth.context"
import { FormConf } from "../../../config/settings/form/WorkProgram.config"
import WorkProgramForm from '../../../components/settings/form/workProgram';

import {
    addWorkProgram,
    updateWorkProgram
} from "../../../redux/actions/actions"

const getInitialValue = (data) => {
    return {
        id: data.id,
        work_program: data.work_program,
    }
}


const NewWorkProgramPage = ({
    authContext,
    addWorkProgram,
    updateWorkProgram,
    onDismiss,
    item,
}) => {

    const {
        body,
        footer
    } = FormConf;

    const update = values => {
        updateWorkProgram({
            access_token: authContext.access_token,
            values: values,
            id: item.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addWorkProgram({
            access_token: authContext.access_token,
            values: values,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        item !== null ? update(values) : add(values)
    };

    return (
        <WorkProgramForm
            body={body}
            footer={footer}
            onSubmit={onSubmit}
            initialValues={item ? getInitialValue(item) : {}}
            disabled={false} />
    );
};


const mapDispatchToProps = dispatch => ({
    addWorkProgram: body => {
        dispatch(addWorkProgram(body))
    },
    updateWorkProgram: body => {
        dispatch(updateWorkProgram(body))
    }
});

export default withAuthContext(connect(null, mapDispatchToProps)(NewWorkProgramPage));