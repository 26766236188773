import React from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from "../../../contexts/auth/Auth.context"
import { FormConf } from "../../../config/settings/form/TeamFieldWork.config"
import TeamFieldWorkForm from '../../../components/settings/form/teamFieldWork';

import {
    addTeamFieldWork,
    updateTeamFieldWork
} from "../../../redux/actions/actions"

const getInitialValue = (data) => {
    return {
        id: data.id,
        name: data.name,
    }
}


const NewTeamFieldWorkPage = ({
    authContext,
    addTeamFieldWork,
    updateTeamFieldWork,
    onDismiss,
    item,
}) => {

    const {
        body,
        footer
    } = FormConf;

    const update = values => {
        updateTeamFieldWork({
            access_token: authContext.access_token,
            values: values,
            id: item.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addTeamFieldWork({
            access_token: authContext.access_token,
            values: values,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        item !== null ? update(values) : add(values)
    };

    return (
        <TeamFieldWorkForm
            body={body}
            footer={footer}
            onSubmit={onSubmit}
            initialValues={item ? getInitialValue(item) : {}}
            disabled={false} />
    );
};


const mapDispatchToProps = dispatch => ({
    addTeamFieldWork: body => {
        dispatch(addTeamFieldWork(body))
    },
    updateTeamFieldWork: body => {
        dispatch(updateTeamFieldWork(body))
    }
});

export default withAuthContext(connect(null, mapDispatchToProps)(NewTeamFieldWorkPage));