export const FormConf = {
    body: {
        category: {
            component: "Select",
            name: "category",
            label: "Category",
            node: "name",
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            }
        },
        description: {
            component: "EditArea",
            name: "name",
            label: "Name",
            required: true,
            disabled: false,
            rows: 4,
            type: "text",
            xs: 12,
            validation: {
                message: "This field is Required"
            }
        }
    },
    footer: {
        columns: [
            {
                component: "ButtonSubmit",
                title: "Save",
                variant: "contained",
                color: "primary"
            }
        ]
    }
}
