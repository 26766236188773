import React from 'react';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Divider.component.style';

const DividerComponent = ({
    classes
}) =>{

    return <Divider />
}

export default withStyles(styles)(DividerComponent);