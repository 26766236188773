import { createAction } from 'redux-actions';


// Notification
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const getNotifications = createAction(GET_NOTIFICATIONS);
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';
export const getNotificationsError = createAction(GET_NOTIFICATIONS_ERROR);
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const getNotificationsSuccess = createAction(GET_NOTIFICATIONS_SUCCESS);

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const updateNotification = createAction(UPDATE_NOTIFICATION);

// export const ACTION_NOTIFICATION_SUCCESS = 'ACTION_NOTIFICATION_SUCCESS';
// export const actionNotificationSuccess = createAction(ACTION_NOTIFICATION_SUCCESS);
// export const ACTION_NOTIFICATION_ERROR = 'ACTION_NOTIFICATION_ERROR';
// export const actionNotificationError = createAction(ACTION_NOTIFICATION_ERROR);
