export const NewMedicalNoteConf = {
    body: {
        start: {
            component: 'CalendarPicker',
            name: 'start',
            label: 'Start Date',
            required: true,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        end: {
            component: 'CalendarPicker',
            name: 'end',
            label: 'End Date',
            required: true,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        player: {            
            component: 'Select',
            name: 'surname',
            others: 'name', 
            label: 'Player',
            required: true,
            disabled: false,  
            xs: 6,          
            validation: {                
                message: "This field is Required"
            },
        },
        injury_type: {
            component: 'Select',
            name: 'injury_type',
            label: 'Injury Type',
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        therapies: {
            component: 'Select',
            name: 'therapy',
            label: 'Therapy',
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        work_programs: {
            component: 'Select',
            name: 'work_program',
            label: 'Work Program',
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        availability: {
            component: 'Select',
            name: 'availability',
            label: 'Availability',
            required: true,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        field_typology: {
            component: 'Select',
            name: 'field_typology',
            label: 'Field Typology',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        event_type: {
            component: 'Select',
            name: 'event_type',
            label: 'Event Type',
            required: false,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        trauma_type: {
            component: 'Select',
            name: 'trauma_type',
            label: 'Trauma Type',
            required: false,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        note: {
            component: 'EditArea',
            name: 'note',
            label: 'Note',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonDelete',
                title: 'Delete',
                variant: 'contained',
                color: 'secondary',
            },
            {
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};