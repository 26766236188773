import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment';

import {
    getTrainings,
    addTraining
} from "../../redux/actions/actions";

import {
    selectTrainings,
    selectTeams
} from "../../redux/selectors/selectors";

import { withAuthContext } from '../../contexts/auth/Auth.context';

import usePermissions from '../../hooks/usePermissions';
import useModal from '../../hooks/useModal';

import NewTraining from './NewTraining.container';

import BigCalendar from '../../components/calendar/ui';
import ProgressComponent from '../../components/ui/progress/Progress.component';
import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';
import FormModal from '../../components/form/ui/modal';

import CalendarTemplate from '../../template/calendar/Calendar.template';


const TrainingCalendarPage = ({
    history,
    authContext,
    getTrainings,
    addTraining,
    trainings,
    match,
    teams,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal();
    const [initialValues, setInitialValues] = useState({});
    const [initialDate, setInitialDate] = useState({
        start_date: moment(new Date()).startOf('month').subtract(7, 'days'),
        end_date: moment(new Date()).endOf('month').add(7, 'days')
    });
    const { isAuthEnabled } = usePermissions('Training', authContext.type)

    useEffect(() => {
        getTrainings({
            access_token: authContext.access_token,
            team: match.params.team,
            start_date: initialDate.start_date,
            end_date: initialDate.end_date,
        })
    }, [match.params.team, initialDate]);

    const onSelectSlot = event => {
        if (isAuthEnabled) {
            openModal();
        }
    };

    const onSelectEvent = event => {
        history.push(`/training/${event.resource.id}`)
    }

    const onEventPropGetter = event => {
        return {
            style: {
                backgroundColor: `#${event.resource.training_plan.color}`
            }
        }
    }

    const onRangeChange = range => {
        setInitialDate({
            start_date: range.start,
            end_date: range.end
        })
    }

    const handleSubmitTraining = values => {
        addTraining({
            access_token: authContext.access_token,
            values: values,
            start_date: initialDate.start_date,
            end_date: initialDate.end_date,
            team: match.params.team,
            callback: () => closeModal()
        })
    };

    const events = trainings.data ? trainings.data.map(item => {
        return ({
            title: `${moment(item.date, 'YYYY-MM-DD - HH:mm:ss').format('HH:mm')}`,
            subtitle: ` ${item.training_plan.training_plan}`,
            start: moment(item.date, 'YYYY-MM-DD - HH:mm:ss').format('YYYY/MM/DD'),
            end: moment(item.date, 'YYYY-MM-DD - HH:mm:ss').format('YYYY/MM/DD'),
            resource: item
        })
    }) : []
    const team = teams.find(function (element) { return element.id.toString() === match.params.team })
    const title = `Trainings - ${team ? team.name : ''}`;

    return (
        <>
            {trainings.loading ? <ProgressComponent /> : null}
            <CalendarTemplate
                toolbar={
                    <ToolbarComponent title={title} />
                }
                calendarBox={
                    <BigCalendar
                        onSelectSlot={(slot) => {
                            onSelectSlot(slot);
                            setInitialValues({ date: slot.start })
                        }}
                        onSelectEvent={(event) =>
                            onSelectEvent(event)
                        }
                        onEventPropGetter={(event) =>
                            onEventPropGetter(event)
                        }
                        onRangeChange={(range) =>
                            onRangeChange(range)
                        }
                        events={events} />
                } />
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    closeModal()
                }}
                onSave={() => {
                    closeModal()
                }}
                title={'New Training'}>
                <NewTraining
                    onSubmit={handleSubmitTraining}
                    initialValues={initialValues} />
            </FormModal>
        </>
    );
};


const mapStateToProps = state => ({
    trainings: selectTrainings(state),
    teams: selectTeams(state)
});

const mapDispatchToProps = dispatch => ({
    getTrainings: (item) => {
        dispatch(getTrainings(item));
    },
    addTraining: (item) => {
        dispatch(addTraining(item))
    }
});

export default withRouter(withAuthContext(connect(mapStateToProps, mapDispatchToProps)(TrainingCalendarPage)));