import React, { PureComponent } from 'react';
import { FormContext } from '../context';

export function withFormContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <FormContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props}/>
                    }
                </FormContext.Consumer>
            )
        }
    }
}