import { createAction } from 'redux-actions';

//Medicals
export const GET_MEDICAL_NOTES = 'GET_MEDICAL_NOTES';
export const getMedicalNotes = createAction(GET_MEDICAL_NOTES);
export const GET_MEDICAL_NOTES_SUCCESS = 'GET_MEDICAL_NOTES_SUCCESS';
export const getMedicalNotesSuccess = createAction(GET_MEDICAL_NOTES_SUCCESS);
export const GET_MEDICAL_NOTES_ERROR = 'GET_MEDICAL_NOTES_ERROR';
export const getMedicalNotesError = createAction(GET_MEDICAL_NOTES_ERROR);

export const GET_MEDICAL_NOTE = 'GET_MEDICAL_NOTE';
export const getMedicalNote = createAction(GET_MEDICAL_NOTE);
export const RESET_GET_MEDICAL_NOTE = 'RESET_GET_MEDICAL_NOTE';
export const resetMedicalNote = createAction(RESET_GET_MEDICAL_NOTE);
export const ADD_MEDICAL_NOTE = 'ADD_MEDICAL_NOTE';
export const addMedicalNote = createAction(ADD_MEDICAL_NOTE);
export const DELETE_MEDICAL_NOTE = 'DELETE_MEDICAL_NOTE';
export const deleteMedicalNote = createAction(DELETE_MEDICAL_NOTE);
export const UPDATE_MEDICAL_NOTE = 'UPDATE_MEDICAL_NOTE';
export const updateMedicalNote = createAction(UPDATE_MEDICAL_NOTE);

export const ACTION_MEDICAL_NOTE_RESET = 'ACTION_MEDICAL_NOTE_RESET';
export const actionMedicalNoteReset = createAction(ACTION_MEDICAL_NOTE_RESET);
export const ACTION_MEDICAL_NOTE_SUCCESS = 'ACTION_MEDICAL_NOTE_SUCCESS';
export const actionMedicalNoteSuccess = createAction(ACTION_MEDICAL_NOTE_SUCCESS);
export const ACTION_MEDICAL_NOTE_ERROR = 'ACTION_MEDICAL_NOTE_ERROR';
export const actionMedicalNoteError = createAction(ACTION_MEDICAL_NOTE_ERROR);