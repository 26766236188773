import {
    GET_CLUB_LINKS,
    GET_CLUB_LINKS_SUCCESS,
    GET_CLUB_LINKS_ERROR
} from "../../actions/actions"

const initialState = {
    loading: false,
    error: null,
    data: [],
    filter: {}
}

const clubLinksReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLUB_LINKS:
            return {
                ...state,
                loading: true
            }

        case GET_CLUB_LINKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data
            }

        case GET_CLUB_LINKS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export default clubLinksReducer
