import React from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from "../../../contexts/auth/Auth.context"
import { FormConf } from "../../../config/settings/form/InjuryType.config"
import InjuryForm from '../../../components/settings/form/injuryType';

import {
    addInjuryType,
    updateInjuryType
} from "../../../redux/actions/actions"

const getInitialValue = (data) => {
    return {
        id: data.id,
        injury_type: data.injury_type,
    }
}


const NewInjuryTypePage = ({
    authContext,
    addInjuryType,
    updateInjuryType,
    onDismiss,
    item,
}) => {

    const {
        body,
        footer
    } = FormConf;

    const update = values => {
        updateInjuryType({
            access_token: authContext.access_token,
            values: values,
            id: item.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addInjuryType({
            access_token: authContext.access_token,
            values: values,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        item !== null ? update(values) : add(values)
    };

    return (
        <InjuryForm
            body={body}
            footer={footer}
            onSubmit={onSubmit}
            initialValues={item ? getInitialValue(item) : {}}
            disabled={false} />
    );
};


const mapDispatchToProps = dispatch => ({
    addInjuryType: body => {
        dispatch(addInjuryType(body))
    },
    updateInjuryType: body => {
        dispatch(updateInjuryType(body))
    }
});

export default withAuthContext(connect(null, mapDispatchToProps)(NewInjuryTypePage));