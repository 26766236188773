import { put, call, takeLatest } from "redux-saga/effects"

import {
    getClubTeams,
    getClubTeamsError,
    getClubTeamsSuccess,
    getClubTeam,
    getClubTeamError,
    getClubTeamSuccess
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* getClubTeamsSaga(action) {
    const { access_token } = action.payload
    try {
        let list = yield call(API.club.getClubTeams, {
            access_token
        })

        yield put(getClubTeamsSuccess(list.data.data))
    } catch (e) {
        yield put(getClubTeamsError(e))
    }
}

function* getClubTeamSaga(action) {
    const { access_token, id } = action.payload
    try {
        let list = yield call(API.club.getClubTeam, {
            access_token,
            id
        })

        yield put(getClubTeamSuccess(list.data.data))
    } catch (e) {
        yield put(getClubTeamError(e))
    }
}

export function* clubTeamsWatcherSaga() {
    yield takeLatest(getClubTeams, getClubTeamsSaga)
    yield takeLatest(getClubTeam, getClubTeamSaga)
}
