import React, { PureComponent } from 'react';

// Context
export const NotificationsContext = React.createContext();

// Provider
export const NotificationsProvider = ({
    children,
    notifications,
    handleDelete = () => { },
    handleUpdate = () => { },
}) => {

    return (
        <NotificationsContext.Provider
            value={{
                notificationsContext: {
                    notifications,
                    actions: {
                        handleDelete,
                        handleUpdate,
                    }
                }
            }}>
            {children}
        </NotificationsContext.Provider>
    )
}


// Consumer
export const NotificationsConsumer = NotificationsContext.Consumer;


// HOC
export function withNotificationsContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <NotificationsContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props} />
                    }
                </NotificationsContext.Consumer>
            )
        }
    }
}