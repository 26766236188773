import { put, call, takeLatest } from "redux-saga/effects"

import {
    getCompetitions,
    getCompetitionsError,
    getCompetitionsSuccess
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* geCompetitionsSaga(action) {
    const { access_token } = action.payload
    try {
        let list = yield call(API.club.getCompetitions, access_token)
        yield put(getCompetitionsSuccess(list.data.data))
    } catch (e) {
        yield put(getCompetitionsError(e))
    }
}

export function* competitionsWatcherSaga() {
    yield takeLatest(getCompetitions, geCompetitionsSaga)
}
