import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './EditArea.component.style';
import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';


const EditAreaTemplate = ({
    classes,
    label,
    required = false,
    disabled = false,
    type = 'text',    
    inputRef,
    name,
    rows,
    rowsMax,
    input,
    meta,
    ...rest
}) => {

    const [labelWidth, setLabelWidth] = useState(0);
    const labelRef = React.useRef(null);
    useEffect(() => {
        setLabelWidth(labelRef.current.offsetWidth);
    }, []);

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched; 

    return (
        <FormControl
            className={classes.formControl}
            error={showError}
            variant="outlined" >
            <InputLabel 
                ref={labelRef}
                required={required}>
                {label}
            </InputLabel>
            <OutlinedInput
                name={name}
                type={type}
                fullWidth
                multiline
                rows={rows}
                rowsMax={rowsMax}
                required={required}
                disabled={disabled}
                inputRef={inputRef}
                labelWidth={labelWidth} 
                {...input} 
                {...rest}/>
        </FormControl>
    )
}

export const EditAreaComponent = withStyles(styles)(EditAreaTemplate)