import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CellActions.component.style';
import TableCell from '@material-ui/core/TableCell';

import { IconDescriptionComponent, IconEditComponent } from '../../icon/Icon.component';

const CellActionsTemplate = ({
    classes,
    onDetailAction,
    onEditAction,
    edit,
    detail,
}) => {

    return (
        <TableCell className={classes.action}>
            <div className={classes.content}>
                {detail ?
                    <IconDescriptionComponent
                        action={onDetailAction} /> :
                    <div />
                }
                {edit ?
                    <IconEditComponent
                        action={onEditAction} /> :
                    <div />
                }
            </div>
        </TableCell>
    )
}

export const CellActionsComponent = withStyles(styles)(CellActionsTemplate)