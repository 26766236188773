import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { styles } from './Drawer.component.style';

const DrawerTemplate = ({
    classes,
    open,
    onCloseDrawer,
    children,
}) => {

    return (
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}>
            <div className={classes.toolbarIcon}>
                <div className={classes.toolbarLogo} />
                <IconButton onClick={onCloseDrawer}>
                    <ChevronLeftIcon className={classes.icon} />
                </IconButton>
            </div>
            <Divider />
            {children}
            <Divider />
        </Drawer>
    );
}

export const DrawerComponent = withStyles(styles)(DrawerTemplate);