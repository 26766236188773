import React from 'react'

import FormProvider from '../../../components/form/provider';
import Form from '../../../components/form/ui';
import { EditArea } from '../../../components/form/ui/editArea';
import { AsyncSelect } from '../../../components/form/ui/select/async/single';
import { AsyncSelectMultiple } from '../../../components/form/ui/select/async/multiple';
import { GridComponent } from '../../../components/ui/grid/Grid.component';
import { Select } from '../../../components/form/ui/select/single';
import { CalendarPicker } from '../../../components/form/ui/calendar';

import { TypeEvent, TypeTrauma } from '../../../config/medicalArea/CommonTypeConf';

import { GET } from '../../../services/Service.context';
import API from '../../../services/axios/api';

import { withAuthContext } from '../../../contexts/auth/Auth.context';



const MedicalNoteForm = ({
    authContext,
    body,
    footer,
    onSubmit,
    onDelete,
    initialValues,
    disabled = false,
    multiSlot = false,
    edit
}) => {

    return (
        <FormProvider
            onSubmit={onSubmit}
            onDelete={onDelete}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}
            edit={edit}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.start.xs}>
                        <CalendarPicker
                            item={body.start}
                            disabled={disabled}
                            calendarType="DatePicker" />
                    </GridComponent>
                    <GridComponent item={true} xs={body.end.xs}>
                        {multiSlot &&
                            <CalendarPicker
                                item={body.end}
                                disabled={disabled}
                                calendarType="DatePicker" />}
                    </GridComponent>
                    <GridComponent item={true} xs={body.player.xs}>
                        <GET request={API.player.getPlayers(authContext.access_token)}>
                            <AsyncSelect
                                item={body.player}
                                node={body.player.name}
                                others={body.player.others}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.availability.xs}>
                        <GET request={API.medicals.getAvaibility(authContext.access_token)}>
                            <AsyncSelect
                                item={body.availability}
                                node={body.availability.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.injury_type.xs}>
                        <GET request={API.medicals.getInjuryType(authContext.access_token)}>
                            <AsyncSelect
                                item={body.injury_type}
                                node={body.injury_type.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.therapies.xs}>
                        <GET request={API.medicals.getTherapy(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.therapies}
                                node={body.therapies.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.work_programs.xs}>
                        <GET request={API.medicals.getWorkProgram(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.work_programs}
                                node={body.work_programs.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.field_typology.xs}>
                        <GET request={API.tools.getTypologyField(authContext.access_token)}>
                            <AsyncSelect
                                item={body.field_typology}
                                node={body.field_typology.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.event_type.xs}>
                        <Select
                            item={body.event_type}
                            options={TypeEvent.events}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.trauma_type.xs}>
                        <Select
                            item={body.trauma_type}
                            options={TypeTrauma.trauma_types}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.note.xs}>
                        <EditArea
                            item={body.note}
                            disabled={disabled} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(MedicalNoteForm);