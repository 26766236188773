import { createAction } from "redux-actions"

//// Injury types
export const GET_INJURY_TYPES = "GET_INJURY_TYPES"
export const getInjuryTypes = createAction(GET_INJURY_TYPES)
export const GET_INJURY_TYPES_SUCCESS = "GET_INJURY_TYPES_SUCCESS"
export const getInjuryTypesSuccess = createAction(GET_INJURY_TYPES_SUCCESS)
export const GET_INJURY_TYPES_ERROR = "GET_INJURY_TYPES_ERROR"
export const getInjuryTypesError = createAction(GET_INJURY_TYPES_ERROR)

export const ADD_INJURY_TYPE = "ADD_INJURY_TYPE"
export const addInjuryType = createAction(ADD_INJURY_TYPE)
export const UPDATE_INJURY_TYPE = "UPDATE_INJURY_TYPE"
export const updateInjuryType = createAction(UPDATE_INJURY_TYPE)

export const ACTION_INJURY_TYPE_SUCCESS = "ACTION_INJURY_TYPE_SUCCESS"
export const actionInjuryTypeSuccess = createAction(ACTION_INJURY_TYPE_SUCCESS)
export const ACTION_INJURY_TYPE_ERROR = "ACTION_INJURY_TYPE_ERROR"
export const actionInjuryTypeError = createAction(ACTION_INJURY_TYPE_ERROR)

//// Work Programs
export const GET_WORK_PROGRAMS = "GET_WORK_PROGRAMS"
export const getWorkPrograms = createAction(GET_WORK_PROGRAMS)
export const GET_WORK_PROGRAMS_SUCCESS = "GET_WORK_PROGRAMS_SUCCESS"
export const getWorkProgramsSuccess = createAction(GET_WORK_PROGRAMS_SUCCESS)
export const GET_WORK_PROGRAMS_ERROR = "GET_WORK_PROGRAMS_ERROR"
export const getWorkProgramsError = createAction(GET_WORK_PROGRAMS_ERROR)

export const ADD_WORK_PROGRAM = "ADD_WORK_PROGRAM"
export const addWorkProgram = createAction(ADD_WORK_PROGRAM)
export const UPDATE_WORK_PROGRAM = "UPDATE_WORK_PROGRAM"
export const updateWorkProgram = createAction(UPDATE_WORK_PROGRAM)

export const ACTION_WORK_PROGRAM_SUCCESS = "ACTION_WORK_PROGRAM_SUCCESS"
export const actionWorkProgramSuccess = createAction(ACTION_WORK_PROGRAM_SUCCESS)
export const ACTION_WORK_PROGRAM_ERROR = "ACTION_WORK_PROGRAM_ERROR"
export const actionWorkProgramError = createAction(ACTION_WORK_PROGRAM_ERROR)

//// Team field works
export const GET_TEAM_FIELD_WORKS = "GET_TEAM_FIELD_WORKS"
export const getTeamFieldWorks = createAction(GET_TEAM_FIELD_WORKS)
export const GET_TEAM_FIELD_WORKS_SUCCESS = "GET_TEAM_FIELD_WORKS_SUCCESS"
export const getTeamFieldWorksSuccess = createAction(GET_TEAM_FIELD_WORKS_SUCCESS)
export const GET_TEAM_FIELD_WORKS_ERROR = "GET_TEAM_FIELD_WORKS_ERROR"
export const getTeamFieldWorksError = createAction(GET_TEAM_FIELD_WORKS_ERROR)

export const ADD_TEAM_FIELD_WORK = "ADD_TEAM_FIELD_WORK"
export const addTeamFieldWork = createAction(ADD_TEAM_FIELD_WORK)
export const UPDATE_TEAM_FIELD_WORK = "UPDATE_TEAM_FIELD_WORK"
export const updateTeamFieldWork = createAction(UPDATE_TEAM_FIELD_WORK)

export const ACTION_TEAM_FIELD_WORK_SUCCESS = "ACTION_TEAM_FIELD_WORK_SUCCESS"
export const actionTeamFieldWorkSuccess = createAction(ACTION_TEAM_FIELD_WORK_SUCCESS)
export const ACTION_TEAM_FIELD_WORK_ERROR = "ACTION_TEAM_FIELD_WORK_ERROR"
export const actionTeamFieldWorkError = createAction(ACTION_TEAM_FIELD_WORK_ERROR)

//// Exercise category
export const GET_EXERCISE_CATEGORIES = "GET_EXERCISE_CATEGORIES"
export const getExerciseCategories = createAction(GET_EXERCISE_CATEGORIES)
export const GET_EXERCISE_CATEGORIES_SUCCESS = "GET_EXERCISE_CATEGORIES_SUCCESS"
export const getExerciseCategoriesSuccess = createAction(GET_EXERCISE_CATEGORIES_SUCCESS)
export const GET_EXERCISE_CATEGORIES_ERROR = "GET_EXERCISE_CATEGORIES_ERROR"
export const getExerciseCategoriesError = createAction(GET_EXERCISE_CATEGORIES_ERROR)

export const ADD_EXERCISE_CATEGORY = "ADD_EXERCISE_CATEGORY"
export const addExerciseCategory = createAction(ADD_EXERCISE_CATEGORY)
export const UPDATE_EXERCISE_CATEGORY = "UPDATE_EXERCISE_CATEGORY"
export const updateExerciseCategory = createAction(UPDATE_EXERCISE_CATEGORY)

export const ACTION_EXERCISE_CATEGORY_SUCCESS ="ACTION_EXERCISE_CATEGORY_SUCCESS"
export const actionExerciseCategorySuccess = createAction(ACTION_EXERCISE_CATEGORY_SUCCESS)
export const ACTION_EXERCISE_CATEGORY_ERROR = "ACTION_EXERCISE_CATEGORY_ERROR"
export const actionExerciseCategoryError = createAction(ACTION_EXERCISE_CATEGORY_ERROR)

//// Exercise Name
export const GET_EXERCISE_NAMES = "GET_EXERCISE_NAMES"
export const getExerciseNames = createAction(GET_EXERCISE_NAMES)
export const GET_EXERCISE_NAMES_SUCCESS = "GET_EXERCISE_NAMES_SUCCESS"
export const getExerciseNamesSuccess = createAction(GET_EXERCISE_NAMES_SUCCESS)
export const GET_EXERCISE_NAMES_ERROR = "GET_EXERCISE_NAMES_ERROR"
export const getExerciseNamesError = createAction(GET_EXERCISE_NAMES_ERROR)

export const ADD_EXERCISE_NAME = "ADD_EXERCISE_NAME"
export const addExerciseName = createAction(ADD_EXERCISE_NAME)
export const UPDATE_EXERCISE_NAME = "UPDATE_EXERCISE_NAME"
export const updateExerciseName = createAction(UPDATE_EXERCISE_NAME)

export const ACTION_EXERCISE_NAME_SUCCESS = "ACTION_EXERCISE_NAME_SUCCESS"
export const actionExerciseNameSuccess = createAction(ACTION_EXERCISE_NAME_SUCCESS)
export const ACTION_EXERCISE_NAME_ERROR = "ACTION_EXERCISE_NAME_ERROR"
export const actionExerciseNameError = createAction(ACTION_EXERCISE_NAME_ERROR)

//// Exercise image
export const GET_EXERCISE_IMAGES = "GET_EXERCISE_IMAGES"
export const getExerciseImages = createAction(GET_EXERCISE_IMAGES)
export const GET_EXERCISE_IMAGES_SUCCESS = "GET_EXERCISE_IMAGES_SUCCESS"
export const getExerciseImagesSuccess = createAction(GET_EXERCISE_IMAGES_SUCCESS)
export const GET_EXERCISE_IMAGES_ERROR = "GET_EXERCISE_IMAGES_ERROR"
export const getExerciseImagesError = createAction(GET_EXERCISE_IMAGES_ERROR)

export const ADD_EXERCISE_IMAGE = "ADD_EXERCISE_IMAGE"
export const addExerciseImage = createAction(ADD_EXERCISE_IMAGE)
export const UPDATE_EXERCISE_IMAGE = "UPDATE_EXERCISE_IMAGE"
export const updateExerciseImage = createAction(UPDATE_EXERCISE_IMAGE)

export const ACTION_EXERCISE_IMAGE_SUCCESS = "ACTION_EXERCISE_IMAGE_SUCCESS"
export const actionExerciseImageSuccess = createAction(ACTION_EXERCISE_IMAGE_SUCCESS)
export const ACTION_EXERCISE_IMAGE_ERROR = "ACTION_EXERCISE_IMAGE_ERROR"
export const actionExerciseImageError = createAction(ACTION_EXERCISE_IMAGE_ERROR)
