import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from '../../contexts/auth/Auth.context';

import { BodyComponent } from '../../components/ui/page/body/Body.component';
import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';
import { GridComponent } from '../../components/ui/grid/Grid.component';

import BoxComponent from '../../components/ui/box/Box.component';
import AvatarComponent from '../../components/ui/avatar/Avatar.component';
import DividerComponent from '../../components/ui/divider/Divider.component';
import Report from '../../components/report';
import Training from '../../components/training';

import { selectNextMatch } from "../../redux/selectors/selectors";

import { getNextMatch } from "../../redux/actions/actions";
import { appColor } from '../../config/colors/colors';
import {    
    IconExpandLessComponent,
    IconExpandMoreComponent,    
} from '../../components/ui/icon/Icon.component';

import moment from 'moment';

import TeamA from '../../assets/Guangzhou_Evergrande_Taobao.png'
import TeamB from '../../assets/Shenzhen.png'


const renderMatchHeader = () => {
	return (
		<GridComponent container={true}>
			<GridComponent item={true} xs={2}>
				<AvatarComponent type='big' src={TeamA}/>
			</GridComponent>
			<GridComponent item={true} xs={4}>
				<BoxComponent fontWeight="fontWeightMedium" fontSize="h6.fontSize" textAlign="left">Guangzhou Evergrande</BoxComponent>
			</GridComponent>
			<GridComponent item={true} xs={4}>
				<BoxComponent fontWeight="fontWeightMedium" fontSize="h6.fontSize" textAlign="right">Shenzhen</BoxComponent>
			</GridComponent>
			<GridComponent item={true} xs={2}>
				<AvatarComponent type='big' src={TeamB}/>
			</GridComponent>
			<GridComponent item={true} xs={12}>
				<DividerComponent />
				<BoxComponent mt={2} fontWeight="fontWeightLight" fontStyle="italic" textAlign="center">{moment().format('YYYY-MM-dddd')}</BoxComponent>
			</GridComponent>
		</GridComponent>
	)
}

const renderHeader = (title, open, setOpen) => {

	return (
		<BoxComponent width='100%' mr={1} ml={1} mt={2} /*bgcolor={appColor.white}*/ boxShadow={1} display="flex" flexDirection="row" color={appColor.white} bgcolor={appColor.other.main}>
			<BoxComponent width="95%" textAlign="left" p={2} fontWeight="fontWeightBold" fontSize={18}>
				{title}
			</BoxComponent>
			<BoxComponent width="5%">
				{open? <IconExpandLessComponent action={() => setOpen(!open)}/> : <IconExpandMoreComponent action={() => setOpen(!open)}/>}
            </BoxComponent>
		</BoxComponent>
	)
}

const renderMedicalNotes = (data, openMedicalNotes, setOpenMedicalNotes) => {

	return (
		<>
			{renderHeader('Medical Notes', openMedicalNotes, setOpenMedicalNotes)}
			{openMedicalNotes ?
				<BoxComponent width={'100%'} mr={1} ml={1}>
					{data.length > 0 && data.map(report =>
						<BoxComponent key={report.id} borderColor={appColor.lightGrey} borderTop={1}>
							<Report report={report} />
						</BoxComponent>)}
				</BoxComponent> : null}
		</>
	)
}

const renderTrainings = (data, openTrainings, setOpenTraining) => {

	return (
		<>
			{renderHeader('Trainings',openTrainings, setOpenTraining)}
			{openTrainings ?
				<BoxComponent width={'100%'} mr={1} ml={1}>
					{data.length > 0 && data.map(training =>
						<BoxComponent key={training.id} borderColor={appColor.lightGrey} borderTop={1}>
							<Training training={training} />
						</BoxComponent>
					)}
				</BoxComponent> : null}
		</>
	)
}


const NextMatchPage = ({
	authContext,
	nextMatch,
	getNextMatch
}) => {

	const [openTrainings, setOpenTraining] = useState(true)
	const [openMedicalNotes, setOpenMedicalNotes] = useState(true)

	useEffect(() => {
		const start_date = moment().subtract(7, 'days');
		const end_date = moment();
		getNextMatch({ access_token: authContext.access_token, start_date, end_date })
	}, []);

	return (
		<>
			<ToolbarComponent title='Next Match' />
			<GridComponent container={true}>
				<BodyComponent>
					{renderMatchHeader()}
				</BodyComponent>
			</GridComponent>
			{renderMedicalNotes(nextMatch.data.medicalNotes, openMedicalNotes, setOpenMedicalNotes)}
			{renderTrainings(nextMatch.data.trainings, openTrainings, setOpenTraining)}
		</>

	);
};

const mapStateToProps = state => ({
	nextMatch: selectNextMatch(state)
});

const mapDispatchToProps = dispatch => ({
	getNextMatch: (params) => {
		dispatch(getNextMatch(params))
	}
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NextMatchPage));