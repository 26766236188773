export const NewExerciseConf = {
    body: {
        proposal: {
            component: 'Select',
            name: 'proposal',
            label: 'Name',
            required: false,
            disabled: false,
            xs: 12,            
            validation: {
                message: "This field is Required"
            },                        
        },
        category: {
            component: 'Select',
            name: 'category',
            label: 'Category',
            node:'name',
            required: true,
            disabled: false, 
            xs: 12,           
            validation: {
                message: "This field is Required"
            },                        
        },
        name: {
            component: 'Select',
            name: 'name',
            label: 'Name',
            node:'name',
            required: true,
            disabled: false,
            xs: 12,            
            validation: {
                message: "This field is Required"
            },                        
        },
        image: {
            component: 'Select',
            name: 'images',
            label: 'Image',
            node:'image',
            required: false,
            disabled: false,
            xs: 12,            
            validation: {
                message: "This field is Required"
            },                        
        },
        theme: {
            component: 'Select',
            name: 'theme',
            label: 'Tema',
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        description:{
            component: 'EditArea',
            name: 'description',
            label: 'Description',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {                
                message: "This field is Required"
            },
        },
        duration: {
            component: 'EditText',
            name: 'duration',
            label: 'Duration',
            required: true,
            disabled: false,
            type: 'text',
            xs: 6,
            validation: {                
                message: "This field is Required"
            },
        },        
        rest: {
            component: 'EditText',
            name: 'rest',
            label: 'Recovery',
            required: true,
            disabled: false,
            type: 'text',
            xs: 6,
            validation: {                
                message: "This field is Required"
            },
        },        
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name', 
            label: 'Players',
            required: true,
            disabled: false, 
            xs: 12,           
            validation: {                
                message: "This field is Required"
            },
        },           
    },
    footer: {
        columns: [            
            {                
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },
                        
        ]
    }
};