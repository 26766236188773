import React, { PureComponent } from 'react';

// Context
export const PopoverContext = React.createContext();

// Provider
export const PopoverProvider = ({
    children,
    open,
    anchorEl,
    handleClose,    
}) => {

    return (
        <PopoverContext.Provider
            value={{
                popoverContext: {
                    open,  
                    anchorEl,                  
                    actions: {
                        handleClose,
                    }
                }
            }}>
            {children}
        </PopoverContext.Provider>
    )
}


// Consumer
export const PopoverConsumer = PopoverContext.Consumer;


// HOC
export function withPopoverContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <PopoverContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props}/>
                    }
                </PopoverContext.Consumer>
            )
        }
    }
}