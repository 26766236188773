import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"

import { Box } from "@material-ui/core"

import { withAuthContext } from "../../contexts/auth/Auth.context"
import { ToolbarComponent } from "../../components/ui/page/toolbar/Toolbar.component"
import BoxComponent from "../../components/ui/box/Box.component"
import { IconFileComponent } from "../../components/ui/icon/Icon.component"

import { appColor } from "../../config/colors/colors"

import { getClubCategories, getClubTeam } from "../../redux/actions/actions"
import {
    selectClubCategories,
    selectClubTeam
} from "../../redux/selectors/selectors"

import { GET } from "../../services/Service.context"
import API from "../../services/axios/api"

const TeamPage = ({
    match,
    authContext,
    getClubTeam,
    team,
    getClubCategories,
    categories
}) => {
    useEffect(() => {
        getClubTeam({
            access_token: authContext.access_token,
            id: match.params.team
        })
        getClubCategories({
            access_token: authContext.access_token,
            team: match.params.team
        })
    }, [match.params.team])

    //const { isAuthEnabled } = usePermissions("Teams", authContext.type)

    const FileBox = ({ data, id, name }) => {
        if (!data || data.data.length === 0) return null

        return (
            <BoxComponent mr={1} mt={1} bgcolor={appColor.white} boxShadow={1}>
                <BoxComponent
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    bgcolor={appColor.other.main}
                >
                    <BoxComponent
                        textAlign="left"
                        fontWeight="fontWeightBold"
                        p={2}
                        fontSize={18}
                        color={appColor.white}
                    >
                        {name}
                    </BoxComponent>
                </BoxComponent>
                <BoxComponent
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    bgcolor={appColor.white}
                    color={appColor.darkGrey}
                >
                    <BoxComponent>
                        <BoxComponent flexDirection="row" p={2}>
                            <BoxComponent
                                textAlign="left"
                                fontWeight="fontWeightRegular"
                                fontStyle="italic"
                                mb={2}
                                fontSize={16}
                            >
                                {data.data.map((item, index) => (
                                    <Box key={index}>
                                        <a href={item.link} target="_blank">
                                            <IconFileComponent />
                                        </a>
                                        <span>{item.name}</span>
                                    </Box>
                                ))}
                            </BoxComponent>
                        </BoxComponent>
                    </BoxComponent>
                </BoxComponent>
            </BoxComponent>
        )
    }

    return (
        <>
            <BoxComponent width={1}>
                <ToolbarComponent title={"REPORT " + team.name} />
            </BoxComponent>
            <BoxComponent
                width="100%"
                mt={2}
                alignItems="flex-start"
                display="flex"
            >
                <BoxComponent width={1} mt={2}>
                    {categories.map(category => (
                        <GET
                            request={API.club.getClubLinks({
                                access_token: authContext.access_token,
                                category: category.id
                            })}
                            key={category.id}
                        >
                            <FileBox id={category.id} name={category.name} />
                        </GET>
                    ))}
                </BoxComponent>
            </BoxComponent>
        </>
    )
}

const mapStateToProps = state => ({
    team: selectClubTeam(state),
    categories: selectClubCategories(state)
})

const mapDispatchToProps = dispatch => ({
    getClubTeam: team => {
        dispatch(getClubTeam(team))
    },
    getClubCategories: team => {
        dispatch(getClubCategories(team))
    }
})

export default withRouter(
    withAuthContext(connect(mapStateToProps, mapDispatchToProps)(TeamPage))
)
