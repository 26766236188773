import React from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../contexts/auth/Auth.context';

import { getInjuryDesk } from "../../redux/actions/actions";

import { selectInjuryDesk } from "../../redux/selectors/selectors";

import FormProvider from '../../components/form/provider';
import Form from '../../components/form/ui';

import { AsyncSelectMultiple } from '../../components/form/ui/select/async/multiple';
import { CalendarPicker } from '../../components/form/ui/calendar';
import { NewInjuryDeskConf } from '../../config/medicalArea/NewInjuryDeskConf';
import { appColor } from '../../config/colors/colors';

import { BodyComponent } from '../../components/ui/page/body/Body.component';
import { GridComponent } from '../../components/ui/grid/Grid.component';
import BoxComponent from '../../components/ui/box/Box.component';

import Report from '../../components/report';

import { GET } from '../../services/Service.context';
import API from '../../services/axios/api';

import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';


const InjuryDesk = ({
    authContext,
    injuryDesk,
    getInjuryDesk
}) => {

    const {
        body,
        footer
    } = NewInjuryDeskConf;

    const onSubmit = values => {
        getInjuryDesk({ access_token: authContext.access_token, values: values })
    };

    return (
        <>
            <ToolbarComponent title="Injury Desk" />
            <BodyComponent>
                <FormProvider
                    onSubmit={onSubmit}
                    body={body}
                    footer={footer}
                    initialValues={injuryDesk.filter}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={body.startDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={body.endDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <GET request={API.player.getPlayers(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.player} node={body.player.name} others={body.player.others} />
                                </GET>
                                <GET request={API.medicals.getInjuryType(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.injury} node={body.injury.name} />
                                </GET>
                                <GET request={API.medicals.getTherapy(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.therapy} node={body.therapy.name} />
                                </GET>
                                <GET request={API.medicals.getWorkProgram(authContext.access_token)}>
                                    <AsyncSelectMultiple item={body.workProgram} node={body.workProgram.name} />
                                </GET>
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BodyComponent>
            <BoxComponent width={'100%'} mt={2} >
                {/* <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkGrey} p={2}>Medical Notes</BoxComponent> */}
                {injuryDesk.data.length > 0 &&
                    injuryDesk.data.map(report =>
                        <BoxComponent key={report.id} borderColor={appColor.lightGrey} borderTop={1}>
                            <Report report={report} />
                        </BoxComponent>
                    )}
            </BoxComponent>
        </>
    )
}

const mapStateToProps = state => ({
    injuryDesk: selectInjuryDesk(state)
});

const mapDispatchToProps = dispatch => ({
    getInjuryDesk: (params) => {
        dispatch(getInjuryDesk(params))
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(InjuryDesk));