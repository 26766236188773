export const FormConf = {
    body: {
        exercise: {
            component: "Select",
            name: "exercise",
            label: "Exercise",
            node: "name",
            required: true,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            }
        },
        description: {
            component: "UploadFile",
            name: "uploadFile",
            label: "Upload Image",
            required: true,
            disabled: false,
            rows: 4,
            type: "file",
            xs: 12,
            validation: {
                message: "This field is Required"
            }
        }
    },
    footer: {
        columns: [
            {
                component: "ButtonSubmit",
                title: "Save",
                variant: "contained",
                color: "primary"
            }
        ]
    }
}
