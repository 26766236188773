import { takeLatest, put, call } from "redux-saga/effects"
import {
    getClubLinks,
    getClubLinksSuccess,
    getClubLinksError
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* getClubLinksSaga(action) {
    const { access_token, values } = action.payload

    try {
        let response = yield call(API.club.getClubLinks, {
            access_token,
            values
        })
        yield put(getClubLinksSuccess(response.data))
    } catch (e) {
        yield put(getClubLinksError(e))
    }
}

export function* clubLinksWatcherSaga() {
    yield takeLatest(getClubLinks, getClubLinksSaga)
}
