import {
    GET_TEAMS,
    GET_TEAMS_ERROR,
    GET_TEAMS_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: []
};

const teamsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_TEAMS:
            return {
                ...state,
                loading: true
            };

        case GET_TEAMS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_TEAMS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default teamsReducer;