import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import { styles } from './Pagination.component.style';

const PaginationTemplate = ({
    rowsPerPageOptions,
    rowsPerPage,
    page,
    count,
    onChangePage,
    onChangeRowsPerPage
}) => {
    
    return (
        <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
                'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
                'aria-label': 'Next Page',
            }}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
    )
}
export const PaginationComponent = withStyles(styles)(PaginationTemplate)




