import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import _orderBy from 'lodash/orderBy';

import useModal from '../../../hooks/useModal';

import {
    getGoalKeeperWorkProposals,
    deleteGoalKeeperWorkProposal,
    orderGoalKeeperWorkProposals,
    resetGoalKeeperWorkProposal,    
} from "../../../redux/actions/actions";

import { selectGoalKeeperWorkProposals } from "../../../redux/selectors/selectors";
import { withAuthContext } from '../../../contexts/auth/Auth.context';

import ProgressComponent from '../../../components/ui/progress/Progress.component';
import TrainingBox from '../../../components/training/box';
import FormModal from '../../../components/form/ui/modal';
import NewGoalKeeperExercise from './NewGoalKeeperExercise.container';
import DragAndDropList from '../../../components/dragAndDrop'
import BoxComponent from '../../../components/ui/box/Box.component';
import GoalKeeperExercise from '../../../components/exercise/goalkeeper';


const GoalKeeperExercisesPage = ({
    authContext,
    trainingId,
    exercises,
    enabled,
    getGoalKeeperWorkProposals,
    deleteGoalKeeperWorkProposal,
    orderGoalKeeperWorkProposals,
    resetGoalKeeperWorkProposal,
}) => {

    const { openModal, closeModal, isModalShowing } = useModal();
    const [currentExercise, setCurrentExercise] = useState(null)

    useEffect(() => {
        getGoalKeeperWorkProposals({
            access_token: authContext.access_token,
            id: trainingId,
        })
    }, []);

    const onDragEndCallback = (items) => {
        
        let actions = { access_token: authContext.access_token, training: trainingId, items: [] }
        items.forEach((element, index) =>
            actions.items.push({ number: index + 1 , id: element.id })
        )
        orderGoalKeeperWorkProposals(actions)
    }

    const onAddExercise = () => {
        setCurrentExercise(null)
        openModal()
    }


    const onDeleteExercise = (id) => {       
        deleteGoalKeeperWorkProposal({
            access_token: authContext.access_token,
            id: id,
            training: trainingId,            
        })
    }

    const onUpdateExercise = (id) => {
        setCurrentExercise(id)
        openModal()
    }


    const workProposals = exercises.data.sort((a, b) => (a.number > b.number) ? 1 : -1)

    return (
        <TrainingBox
            title='GoalKeeper Exercises'
            action={() => onAddExercise()} 
            disabled={!enabled}>            
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setCurrentExercise(null)
                    resetGoalKeeperWorkProposal()
                    closeModal()
                }}
                onSave={() => {
                    setCurrentExercise(null)
                    resetGoalKeeperWorkProposal()
                    closeModal()
                }}
                title={currentExercise ? 'Update Exercise' : 'Add Exercise'}>
                <NewGoalKeeperExercise
                    exerciseId={currentExercise}
                    onDismiss={() => {
                        setCurrentExercise(null)
                        resetGoalKeeperWorkProposal()
                        closeModal()
                    }}
                />
            </FormModal>
            {exercises.loading ?
                <ProgressComponent /> :
                <BoxComponent height={550} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <DragAndDropList
                        rows={workProposals}
                        disabled={!enabled}
                        dragEndCallback={(items) => onDragEndCallback(items)}
                        renderItem={(item) =>
                            <GoalKeeperExercise
                                item={item}
                                onDeleteExercise={onDeleteExercise}
                                onUpdateExercise={onUpdateExercise}
                                disabled={!enabled} />
                        }
                    />
                </BoxComponent>
            }
        </TrainingBox>
    )
};

const mapStateToProps = state => ({
    exercises: selectGoalKeeperWorkProposals(state)
});

const mapDispatchToProps = dispatch => ({
    getGoalKeeperWorkProposals: (item) => {
        dispatch(getGoalKeeperWorkProposals(item));
    },
    orderGoalKeeperWorkProposals: (item) => {
        dispatch(orderGoalKeeperWorkProposals(item))
    },
    deleteGoalKeeperWorkProposal: (item) => {
        dispatch(deleteGoalKeeperWorkProposal(item))
    },
    resetGoalKeeperWorkProposal: () => {
        dispatch(resetGoalKeeperWorkProposal())
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(GoalKeeperExercisesPage));