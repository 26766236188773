import moment from 'moment';

export default {

    getSquads: ({ date, teams }) => {            
        var params = new URLSearchParams();
        params.append("teams", teams);
        params.append("date", moment(date, 'YYYY-MM-DD - HH:mm:ss').format('YYYY-MM-DD'))
        return {
            params: params
        }
    },
}