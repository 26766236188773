import React from 'react'
// import _omit from 'lodash/omit';

import FormProvider from '../../../components/form/provider';
import Form from '../../../components/form/ui';
import { EditText } from '../../../components/form/ui/editText';
import { EditArea } from '../../../components/form/ui/editArea';
import { AsyncSelect } from '../../../components/form/ui/select/async/single';
import { AsyncSelectMultiple } from '../../../components/form/ui/select/async/multiple';
import { SelectMultiple } from '../../../components/form/ui/select/multiple';
import { GridComponent } from '../../../components/ui/grid/Grid.component';
import BoxComponent from '../../../components/ui/box/Box.component';
import { Checkbox } from '../../../components/form/ui/checkbox';

import { GET } from '../../../services/Service.context';
import API from '../../../services/axios/api';

import { withAuthContext } from '../../../contexts/auth/Auth.context';

const GoalKeeperExerciseForm = ({
    authContext,
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
    players = []
}) => {

    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>

                    <GridComponent item={true} xs={body.duration.xs}>
                        <EditText item={body.duration} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.rest.xs}>
                        <EditText item={body.rest} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.theme.xs}>
                        <GET request={API.coaching.getTheme(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.theme}
                                node={body.theme.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.players.xs}>
                        <SelectMultiple
                            options={players}
                            item={body.players}
                            node={body.players.name}
                            others={body.players.others} />
                    </GridComponent>


                    <GridComponent item={true} xs={12}>
                        <BoxComponent
                            fontWeight='fontWeightBold'
                            textAlign="left"
                            fontSize={18}>Lavoro campo preparatore</BoxComponent>
                    </GridComponent>
                    <GridComponent item={true} xs={body.workoutGoalkeeper.xs}>
                        <GET request={API.coaching.getWorkoutGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.workoutGoalkeeper}
                                node={body.workoutGoalkeeper.node} />
                        </GET>
                    </GridComponent>                    
                    <GridComponent item={true} xs={body.workoutModality.xs}>
                        <GET request={API.coaching.getWorkoutModalityGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.workoutModality}
                                node={body.workoutModality.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.kickType.xs}>
                        <GET request={API.coaching.getKickTypeGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.kickType}
                                node={body.kickType.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.ballType.xs}>
                        <GET request={API.coaching.getBallTypeGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.ballType}
                                node={body.ballType.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.workoutCoachGroup.xs}>
                        <Checkbox
                            item={body.workoutCoachGroup}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.workoutCoachDescription.xs}>
                        <EditArea item={body.workoutCoachDescription} />
                    </GridComponent>


                    <GridComponent item={true} xs={12}>
                        <BoxComponent
                            fontWeight='fontWeightBold'
                            textAlign="left"
                            fontSize={18}>Lavoro campo squadra</BoxComponent>
                    </GridComponent>
                    <GridComponent item={true} xs={body.teamFieldWork.xs}>
                        <GET request={API.coaching.getTeamFieldWorkGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.teamFieldWork}
                                node={body.teamFieldWork.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.workoutTeamDescription.xs}>
                        <EditArea item={body.workoutTeamDescription} />
                    </GridComponent>



                    <GridComponent item={true} xs={12}>
                        <BoxComponent
                            fontWeight='fontWeightBold'
                            textAlign="left"
                            fontSize={18}>Lavoro specifico</BoxComponent>
                    </GridComponent>
                    <GridComponent item={true} xs={body.gymWork.xs}>
                        <GET request={API.coaching.getGymWorkGoalkeeper(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.gymWork}
                                node={body.gymWork.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.limb.xs}>
                        <GET request={API.coaching.getLimbGoalkeeper(authContext.access_token)}>
                            <AsyncSelect
                                item={body.limb}
                                node={body.limb.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.equipment.xs}>
                        <GET request={API.coaching.getEquipmentGoalkeeper(authContext.access_token)}>
                            <AsyncSelect
                                item={body.equipment}
                                node={body.equipment.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.psychokineticLightWork.xs}>
                        <GET request={API.coaching.getPsychokineticLightWorkGoalkeeper(authContext.access_token)}>
                            <AsyncSelect
                                item={body.psychokineticLightWork}
                                node={body.psychokineticLightWork.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.sandWork.xs}>
                        <GET request={API.coaching.getSandWorkGoalkeeper(authContext.access_token)}>
                            <AsyncSelect
                                item={body.sandWork}
                                node={body.sandWork.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.workoutSpecificDescription.xs}>
                        <EditArea item={body.workoutSpecificDescription} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(GoalKeeperExerciseForm);