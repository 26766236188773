export const NewExerciseDeskConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end',
            label: 'End Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        category: {
            component: 'SelectMultiple',
            name: 'category',
            label: 'Category',
            node: 'name',
            required: true,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        },
        theme: {
            component: 'SelectMultiple',
            name: 'theme',
            label: 'Theme',
            node: 'theme',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        },
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name',
            label: 'Players',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};


export const NewExercisePlayersDeskConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end',
            label: 'End Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name',
            label: 'Players',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};

export const NewExerciseGoalkeeperDeskConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end',
            label: 'End Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        theme: {
            component: 'Select',
            name: 'themes',
            node: 'theme',
            label: 'Tema esercizio',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name',
            label: 'Giocatori',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutGoalkeeper: {
            component: 'SelectMultiple',
            name: 'workout_goalkeepers',
            node: 'name',
            label: 'Categoria esercizio',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutModality: {
            component: 'SelectMultiple',
            name: 'workout_modalities',
            node: 'name',
            label: 'Modalità esercizio',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        kickType: {
            component: 'SelectMultiple',
            name: 'kick_types',
            node: 'name',
            label: 'Tipologia tiro',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        ballType: {
            component: 'SelectMultiple',
            name: 'ball_types',
            node: 'name',
            label: 'Tipologia palla',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        teamFieldWork: {
            component: 'SelectMultiple',
            name: 'team_field_works',
            node: 'name',
            label: 'Lavoro con la squadra',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        gymWork: {
            component: 'SelectMultiple',
            name: 'gym_works',
            node: 'name',
            label: 'Lavoro in palestra',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        limb: {
            component: 'SelectMultiple',
            name: 'limbs',
            node: 'name',
            label: 'Arti',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        equipment: {
            component: 'SelectMultiple',
            name: 'equipments',
            node: 'name',
            label: 'Macchinari',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        psychokineticLightWork: {
            component: 'SelectMultiple',
            name: 'psychokinetic_light_works',
            node: 'name',
            label: 'Lavoro psicocinetico luci',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        sandWork: {
            component: 'SelectMultiple',
            name: 'sand_works',
            node: 'name',
            label: 'Sabbia',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};