import React from 'react';
import { withDashboardContext } from '../hoc';

const DashboardComponent = ({
    children,
}) => {

    return (
        <div style={{display: 'flex'}}>
            {children}
        </div>
    )
}


export default withDashboardContext(DashboardComponent);