export const NewNoteConf = {
    body: {        
        player: {
            component: 'Select',
            name: 'surname',
            others: 'name', 
            label: 'Player',
            required: true,
            disabled: false,  
            xs: 12,          
            validation: {                
                message: "This field is Required"
            },
        }, 
        description:{
            component: 'EditArea',
            name: 'description',
            label: 'Description',
            required: true,
            disabled: false,
            rows: 4,
            type: 'text', 
            xs: 12,           
            validation: {                
                message: "This field is Required"
            },
        },                   
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};