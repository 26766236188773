import { createAction } from 'redux-actions';

// Notes
export const GET_TRAINING_NOTES = 'GET_TRAINING_NOTES';
export const getTrainingNotes = createAction(GET_TRAINING_NOTES);
export const GET_TRAINING_NOTES_ERROR = 'GET_TRAINING_NOTES_ERROR';
export const getTrainingNotesError = createAction(GET_TRAINING_NOTES_ERROR);
export const GET_TRAINING_NOTES_SUCCESS = 'GET_TRAINING_NOTES_SUCCESS';
export const getTrainingNotesSuccess = createAction(GET_TRAINING_NOTES_SUCCESS);
export const GET_TRAINING_NOTE = 'GET_TRAINING_NOTE';
export const getTrainingNote = createAction(GET_TRAINING_NOTE);

export const ADD_TRAINING_NOTE = 'ADD_TRAINING_NOTE';
export const addTrainingNote = createAction(ADD_TRAINING_NOTE);
export const UPDATE_TRAINING_NOTE = 'UPDATE_TRAINING_NOTE';
export const updateTrainingNote = createAction(UPDATE_TRAINING_NOTE);
export const DELETE_TRAINING_NOTE = 'DELETE_TRAINING_NOTE';
export const deleteTrainingNote = createAction(DELETE_TRAINING_NOTE);
export const RESET_TRAINING_NOTE = 'RESET_TRAINING_NOTE';
export const resetTrainingNote = createAction(RESET_TRAINING_NOTE);

export const ACTION_TRAINING_NOTE_RESET = 'ACTION_TRAINING_NOTE_RESET';
export const actionTrainingNoteReset = createAction(ACTION_TRAINING_NOTE_RESET);
export const ACTION_TRAINING_NOTE_SUCCESS = 'ACTION_TRAINING_NOTE_SUCCESS';
export const actionTrainingNoteSuccess = createAction(ACTION_TRAINING_NOTE_SUCCESS);
export const ACTION_TRAINING_NOTE_ERROR = 'ACTION_TRAINING_NOTE_ERROR';
export const actionTrainingNoteError = createAction(ACTION_TRAINING_NOTE_ERROR);
