import React, { PureComponent } from 'react';
import { ModalContext } from '../context';

export function withModalContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <ModalContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props}/>
                    }
                </ModalContext.Consumer>
            )
        }
    }
}