export default {


    getTrainingNotesFilter: ({ id }) => {
        var params = new URLSearchParams();
        params.append("training", id);
        return {
            params: params
        }
    },

    upsertTrainingNotes: ({ values, id, training, isRead }) => {  
     
        var body = new FormData();

        // ID
        if (id) {
            body.set('id', id);
        }

        // Training
        if (training) {
            body.set('training', training);
        }

        // Player
        if (values.surname) {
            body.set('player', values.surname);
        }   

        // Description
        if (values.description) {
            body.set('description', values.description);
        }   

        // isRead     
        if (isRead) {
            body.set('isRead', isRead);
        }   

        return body
    },
}