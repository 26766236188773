import React from "react"
import _slice from "lodash/slice"

import ListProvider from "../../list/provider"
import List from "../../list/ui"
import ListHeader from "../../list/ui/header"
import ListPagination from "../../list/ui/pagination"
import ListHead from "../../list/ui/head"
import ListBody from "../../list/ui/body"

import useListPagination from "../../../hooks/useListPagination"
import useListSelection from "../../../hooks/useListSelection"


const ListSettings = ({
    data,
    conf,
    enabled,
    onAddElement,
    onEditElement,
}) => {
    const { toolbar, head, body, pagination } = conf
    const { page, setPage, rowsPerPage, setRowsPerPage } = useListPagination(pagination.page, pagination.rowsPerPage)
    const { selected, setSelectedItem, setSelectedAll } = useListSelection([])

    return (
        <ListProvider
            toolbar={toolbar.title}
            count={data.length}
            data={_slice(data, rowsPerPage * page, rowsPerPage * (page + 1))}
            page={page}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            selected={selected}
            head={head}
            body={body}
            onSelect={id => setSelectedItem(id)}
            onSelectAll={event => setSelectedAll(event.target.checked ? data.map(item => item.id) : [])}
            onChangePage={(event, page) => setPage(page)}
            onChangeRowsPerPage={event => setRowsPerPage(event.target.value)}
            onDeleteAction={null}
            onEditAction={enabled ? () => onEditElement(selected) : null}
            onAddAction={enabled ? onAddElement : null}>
            <ListHeader />
            <List>
                <ListHead />
                <ListBody />
            </List>
            <ListPagination />
        </ListProvider>
    )
}

export default ListSettings
