import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../contexts/auth/Auth.context';
import { getStatistics } from "../../redux/actions/actions";
import { selectStatistics } from "../../redux/selectors/selectors";

import FormProvider from '../../components/form/provider';
import Form from '../../components/form/ui';
import { CalendarPicker } from '../../components/form/ui/calendar';

import ListProvider from '../../components/list/provider';
import List from '../../components/list/ui';
import ListHead from '../../components/list/ui/head';
import ListBody from '../../components/list/ui/body';

import {
    StatisticsFormConf,
    StatisticsListConf
} from '../../config/medicalArea/StatisticsConf';
import { colors, appColor } from '../../config/colors/colors';

import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';
import { BodyComponent } from '../../components/ui/page/body/Body.component';
import { GridComponent } from '../../components/ui/grid/Grid.component';
import BoxComponent from '../../components/ui/box/Box.component';
import PieChartComponent from '../../components/graph/ui/pie';
import ProgressComponent from '../../components/ui/progress/Progress.component';

function createData(name, totDays, inWorkOut, inMatch, direct, indirect, fieldNatural, fieldSintetic) {
    return {
        name,
        totDays,
        inWorkOut,
        inMatch,
        direct,
        indirect,
        fieldNatural,
        fieldSintetic
    };
}

// Data
const GraphPlayerDays = (data) => {
    const items = data.slice();
    items.pop()
    return items.map(item => ({
        name: item.name,
        value: item.totDays
    }))
}

const GraphEventsType = (data) => {
    const items = data.slice();
    items.pop()
    return items.map(item => ({
        name: item.name,
        value: item.inWorkOut + item.inMatch
    }))
}

const GraphTraumaType = (data) => {
    const items = data.slice();
    items.pop()
    return items.map(item => ({
        name: item.name,
        value: item.direct + item.indirect
    }))
}

const GraphFieldType = (data) => {
    const items = data.slice();
    items.pop()
    return items.map(item => ({
        name: item.name,
        value: item.fieldNatural + item.fieldSintetic
    }))
}


const MedicalStatistics = ({
    authContext,
    getStatistics,
    statistics
}) => {

    const {
        body: formBody,
        footer: formFooter
    } = StatisticsFormConf;


    const {
        toolbar: toolbarList,
        head: headList,
        body: bodyList,
    } = StatisticsListConf;

    const [rows, setRows] = useState([])

    useEffect(() => {

        let rowsTemp = []
        let sumTotDays = 0
        let sumInWorkOut = 0
        let sumInMatch = 0
        let sumDirect = 0
        let sumIndirect = 0
        let sumFieldNatural = 0
        let sumFieldSintetic = 0

        // Data    
        statistics.data.players.forEach(element => {

            let temp = 0

            // Name
            const name = `${element.player.name} ${element.player.surname}`

            // Total Days        
            temp = element.num_injuries_days.find(item => item.type === 'total_days')
            const totDays = temp ? temp.total : 0
            sumTotDays = sumTotDays + totDays;

            if (totDays > 0) {
                // In Work Out
                temp = element.num_injuries_by_event.find(item => item.type === 'ALLENAMENTO')
                const inWorkOut = temp ? temp.total : 0
                sumInWorkOut = sumInWorkOut + inWorkOut

                // In Match
                temp = element.num_injuries_by_event.find(item => item.type === 'PARTITA')
                const inMatch = temp ? temp.total : 0
                sumInMatch = sumInMatch + inMatch

                // Direct
                temp = element.num_injuries_by_trauma.find(item => item.type === 'DIRETTO')
                const direct = temp ? temp.total : 0
                sumDirect = sumDirect + direct

                // Indirect
                temp = element.num_injuries_by_trauma.find(item => item.type === 'INDIRETTO')
                const indirect = temp ? temp.total : 0
                sumIndirect = sumIndirect + indirect

                // Natural
                temp = element.num_injuries_by_field_typology.find(item => item.type.field_typology === 'Naturale')
                const fieldNatural = temp ? temp.total : 0
                sumFieldNatural = sumFieldNatural + fieldNatural

                // Sintetic
                temp = element.num_injuries_by_field_typology.find(item => item.type.field_typology === 'Sintetico')
                const fieldSintetic = temp ? temp.total : 0
                sumFieldSintetic = sumFieldSintetic + fieldSintetic

                rowsTemp.push(createData(name, totDays, inWorkOut, inMatch, direct, indirect, fieldNatural, fieldSintetic))
            }
        });

        rowsTemp.push(createData('TOT.', sumTotDays, sumInWorkOut, sumInMatch, sumDirect, sumIndirect, sumFieldNatural, sumFieldSintetic))
        setRows(rowsTemp)

    }, [statistics.loading]);


    const onSubmit = values => {
        getStatistics({ access_token: authContext.access_token, values: values })
    };


    return (
        <>
            <ToolbarComponent title="Statistics" />            
            <BodyComponent>
                {statistics.loading ? <ProgressComponent /> : null}
                <FormProvider
                    onSubmit={onSubmit}
                    body={formBody}
                    footer={formFooter}
                    initialValues={statistics.filter}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={4} style={{ padding: '8px' }}>
                                <CalendarPicker item={formBody.startDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={4} style={{ padding: '8px' }}>
                                <CalendarPicker item={formBody.endDate} />
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BodyComponent>
            <BoxComponent width={'100%'} mt={1} bgcolor={appColor.white} boxShadow={1}>
                <ListProvider
                    toolbar={toolbarList.title}
                    count={rows.length}
                    data={rows}
                    head={headList}
                    body={bodyList}>
                    <List>
                        <ListHead />
                        <ListBody />
                    </List>
                </ListProvider>
            </BoxComponent>
            <BoxComponent width={'100%'} mt={1} alignItems="flex-start" display='flex'>
                <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                    <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Total days</BoxComponent>
                    {statistics.data.players.length === 0 ? null :
                        <div style={{ width: '100%', height: 400, padding: '10px' }}>
                            <PieChartComponent
                                data={GraphPlayerDays(rows)}
                                colors={colors}
                                dataKey='value' />
                        </div>
                    }
                </BoxComponent>
                <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} ml={1}>
                    <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Events Types</BoxComponent>
                    {statistics.data.players.length === 0 ? null :
                        <div style={{ width: '100%', height: 400, padding: '10px' }}>
                            <PieChartComponent
                                data={GraphEventsType(rows)}
                                colors={colors}
                                dataKey='value' />
                        </div>
                    }
                </BoxComponent>
            </BoxComponent>
            <BoxComponent width={'100%'} mt={1} alignItems="flex-start" display='flex'>
                <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                    <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Trauma Type</BoxComponent>
                    {statistics.data.players.length === 0 ? null :
                        <div style={{ width: '100%', height: 400, padding: '10px' }}>
                            <PieChartComponent
                                data={GraphTraumaType(rows)}
                                colors={colors}
                                dataKey='value' />
                        </div>
                    }
                </BoxComponent>
                <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} ml={1}>
                    <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Field Typology</BoxComponent>
                    {statistics.data.players.length === 0 ? null :
                        <div style={{ width: '100%', height: 400, padding: '10px' }}>
                            <PieChartComponent
                                data={GraphFieldType(rows)}
                                colors={colors}
                                dataKey='value' />
                        </div>
                    }
                </BoxComponent>
            </BoxComponent>
        </>
    )
}

const mapStateToProps = state => ({
    statistics: selectStatistics(state)
});

const mapDispatchToProps = dispatch => ({
    getStatistics: (params) => {
        dispatch(getStatistics(params))
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(MedicalStatistics));