import {
  GET_TEAM_FIELD_WORKS,
  GET_TEAM_FIELD_WORKS_ERROR,
  GET_TEAM_FIELD_WORKS_SUCCESS
} from '../../actions/actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const teamFieldWorksReducer = (state = initialState, action) => {
  switch (action.type) {

      case GET_TEAM_FIELD_WORKS:
          return {
              ...state,
              loading: true
          };

      case GET_TEAM_FIELD_WORKS_SUCCESS:
          return {
              ...state,
              loading: false,
              error: null,
              data: action.payload.data
          };

      case GET_TEAM_FIELD_WORKS_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };

      default:
          return state

  }
};

export default teamFieldWorksReducer;