import React from "react"
import { withListContext } from "../../hoc"
import { BodyComponent } from "../../../ui/table/body/Body.component"
import { RowComponent } from "../../../ui/table/row/Row.component"
import { CellCheckboxComponent } from "../../../ui/table/checkbox/CellCheckbox.component"
import { CellTextComponent } from "../../../ui/table/text/CellText.component"
import { CellActionsComponent } from "../../../ui/table/actions/CellActions.component"
import { CellBooleanComponent } from "../../../ui/table/boolean/CellBoolean.component"
import { CellCalendarComponent } from "../../../ui/table/calendar/CellCalendar.component"
import { CellImageComponent } from "../../../ui/table/image/CellImage.component"
import { CellEmptyComponent } from "../../../ui/table/empty/CellEmpty.component"

import _split from "lodash/split"

function getValue(key, data) {
    let value = ""
    if (!!data) {
        const split = _split(key, ".", 2)
        if (split.length > 1) {
            value = data[split[0]][split[1]]
        } else {
            value = data[split[0]]
        }
    }

    return value
}

const ListBody = ({ listContext }) => {
    const {
        data,
        selected,
        rowsPerPage,
        count,
        page,
        body: {
            cells
        },
        actions: {
            onEditAction,
            onDetailAction,
            onSelect
        }
    } = listContext

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
        <BodyComponent>
            {data.map((item, index) => {
                return (
                    <RowComponent key={index}>
                        {cells.map(
                            ({ key, component, align, otherKey }, index) => {
                                switch (component) {
                                    case "CellCheckbox":
                                        return (
                                            <CellCheckboxComponent
                                                key={index}
                                                onChange={() =>
                                                    onSelect(item.id)
                                                }
                                                checked={
                                                    selected.indexOf(
                                                        item.id
                                                    ) !== -1
                                                }
                                            />
                                        )
                                    case "CellText":
                                        const label = `${getValue(key, item)} ${
                                            otherKey
                                                ? getValue(otherKey, item)
                                                : ""
                                            }`
                                        return (
                                            <CellTextComponent
                                                key={index}
                                                label={label}
                                                align={align}
                                            />
                                        )
                                    case "CellActions":
                                        return (
                                            <CellActionsComponent
                                                key={index}
                                                onDetailAction={() =>
                                                    onDetailAction(item.id)
                                                }
                                                onEditAction={() =>
                                                    onEditAction(item.id)
                                                }
                                                edit
                                                detail
                                            />
                                        )
                                    case "CellBoolean":
                                        return (
                                            <CellBooleanComponent
                                                key={index}
                                                label={item[key]}
                                            />
                                        )
                                    case "CellCalendar":
                                        return (
                                            <CellCalendarComponent
                                                key={index}
                                                label={item[key]}
                                            />
                                        )
                                    case "CellImage":
                                        return (
                                            <CellImageComponent
                                                key={index}
                                                src={item[key]}
                                            />
                                        )
                                    default:
                                        return null
                                }
                            }
                        )}
                    </RowComponent>
                )
            })}            
            {emptyRows > 0 && <CellEmptyComponent emptyRows={emptyRows}/>}
        </BodyComponent>
    )
}

export default withListContext(ListBody)
