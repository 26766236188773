import { createAction } from 'redux-actions';

// Work Proposal
export const GET_WORK_PROPOSALS = 'GET_WORK_PROPOSALS';
export const getWorkProposals = createAction(GET_WORK_PROPOSALS);
export const GET_WORK_PROPOSALS_ERROR = 'GET_WORK_PROPOSALS_ERROR';
export const getWorkProposalsError = createAction(GET_WORK_PROPOSALS_ERROR);
export const GET_WORK_PROPOSALS_SUCCESS = 'GET_WORK_PROPOSALS_SUCCESS';
export const getWorkProposalsSuccess = createAction(GET_WORK_PROPOSALS_SUCCESS);

export const GET_WORK_PROPOSAL = 'GET_WORK_PROPOSAL';
export const getWorkProposal = createAction(GET_WORK_PROPOSAL);
export const RESET_WORK_PROPOSAL = 'RESET_WORK_PROPOSAL';
export const resetWorkProposal = createAction(RESET_WORK_PROPOSAL);
export const ADD_WORK_PROPOSAL = 'ADD_WORK_PROPOSAL';
export const addWorkProposal = createAction(ADD_WORK_PROPOSAL);
export const UPDATE_WORK_PROPOSAL = 'UPDATE_WORK_PROPOSAL';
export const updateWorkProposal = createAction(UPDATE_WORK_PROPOSAL);
export const DELETE_WORK_PROPOSAL = 'DELETE_WORK_PROPOSAL';
export const deleteWorkProposal = createAction(DELETE_WORK_PROPOSAL);

export const ACTION_WORK_PROPOSAL_RESET = 'ACTION_WORK_PROPOSAL_RESET';
export const actionWorkProposalReset = createAction(ACTION_WORK_PROPOSAL_RESET);
export const ACTION_WORK_PROPOSAL_SUCCESS = 'ACTION_WORK_PROPOSAL_SUCCESS';
export const actionWorkProposalSuccess = createAction(ACTION_WORK_PROPOSAL_SUCCESS);
export const ACTION_WORK_PROPOSAL_ERROR = 'ACTION_WORK_PROPOSAL_ERROR';
export const actionWorkProposalError = createAction(ACTION_WORK_PROPOSAL_ERROR);

export const ORDER_WORK_PROPOSALS = 'ORDER_WORK_PROPOSALS';
export const orderWorkProposals = createAction(ORDER_WORK_PROPOSALS);

export const GET_WORK_PROPOSAL_SETTINGS = 'GET_WORK_PROPOSAL_SETTINGS';
export const getWorkProposalSettings = createAction(GET_WORK_PROPOSAL_SETTINGS);
export const GET_WORK_PROPOSAL_SETTINGS_SUCCESS = 'GET_WORK_PROPOSAL_SETTINGS_SUCCESS';
export const getWorkProposalSettingsSuccess = createAction(GET_WORK_PROPOSAL_SETTINGS_SUCCESS);
export const GET_WORK_PROPOSAL_SETTINGS_ERROR = 'GET_WORK_PROPOSAL_SETTINGS_ERROR';
export const getWorkProposalSettingsError = createAction(GET_WORK_PROPOSAL_SETTINGS_ERROR);


export const GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS = 'GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS';
export const getGoalkeeperWorkProposalSettings = createAction(GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS);
export const GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_SUCCESS = 'GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_SUCCESS';
export const getGoalkeeperWorkProposalSettingsSuccess = createAction(GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_SUCCESS);
export const GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_ERROR = 'GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_ERROR';
export const getGoalkeeperWorkProposalSettingsError = createAction(GET_GOALKEEPER_WORK_PROPOSAL_SETTINGS_ERROR);
