import React from 'react';
import { IconEditComponent, IconDeleteComponent } from '../../../components/ui/icon/Icon.component';

import ExerciseLarge from '../large'
import ExerciseMedium from '../medium'
import ExerciseSmall from '../small'
import ExerciseNumber from '../number'
import ExerciseRow from '../row'
import ExerciseAction from '../action'
import ExerciseContent from '../content'

const Exercise = ({
    item,
    onDeleteExercise,
    onUpdateExercise,
    disabled,
}) => {

    return (
        <ExerciseRow>
            <ExerciseNumber>
                {item.number}
            </ExerciseNumber>
            <ExerciseContent>
                <ExerciseLarge>
                    {item.name? item.name.name.toUpperCase() : ''}
                </ExerciseLarge>
                <ExerciseMedium>
                    {`Category: ${item.name? item.name.category? item.name.category.name: '' : ''}`}
                </ExerciseMedium>
                <ExerciseMedium>
                    {`Theme: ${item.theme.theme}`}
                </ExerciseMedium>
                <ExerciseSmall>
                    {item.description? item.description: ''}
                </ExerciseSmall>
            </ExerciseContent>
            <ExerciseAction>
                {disabled ? null :
                    <>
                        <IconDeleteComponent action={() => onDeleteExercise(item.id)} />
                        <IconEditComponent action={() => onUpdateExercise(item.id)} />
                    </>
                }
            </ExerciseAction>

        </ExerciseRow>
    )
}

export default Exercise;