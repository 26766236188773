export const FormConf = {
    body: {  
        description:{
            component: 'EditArea',
            name: 'name',
            label: 'Exercise category',
            required: true,
            disabled: false,
            rows: 4,
            type: 'text', 
            xs: 12,           
            validation: {                
                message: "This field is Required"
            },
        },                   
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};