import React from "react"
import { withFormContext } from "../hoc"
import { Form, FormSpy } from "react-final-form"
import FormActions from "./actions"

import DividerComponent from "../../ui/divider/Divider.component"

const FormComponent = ({ children, formContext }) => {
    const {
        body,
        actions: { onSubmit, onDelete, onPrint, onChange = () => {} },
        initialValues
    } = formContext

    const onValidate = values => {
        const errors = {}
        Object.keys(body).forEach(key => {
            const { required, name, validation } = body[key]

            if (required && values[name] === undefined) {
                errors[name] = validation.message
            } else {
                if (validation.regex && !validation.regex.test(values[name])) {
                    errors[name] = validation.message
                }
            }
        })
        return errors
    }

    const childrenWithProps = (children, values) => {
        return React.Children.map(children, child =>
            React.cloneElement(child, { values: values })
        )
    }

    return (
        <Form
            //subscription={{ submitting: true, pristine: true }}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={onValidate}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
                return (
                    <>
                        <form
                            onSubmit={handleSubmit}
                            noValidate
                            style={{ padding: "24px" }}
                            //encType="multipart/form-data"
                        >
                            {childrenWithProps(children, values)}
                        </form>
                        <DividerComponent />
                        <FormActions
                            onSubmit={handleSubmit}
                            onDelete={onDelete}
                            onPrint={onPrint}
                            onReset={form.reset}
                            submitting={submitting}
                            pristine={pristine}
                        />
                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                        <FormSpy subscription={{ values: true }}>
                            {props => {
                                onChange(props.values)
                                return <></>
                            }}
                        </FormSpy>
                    </>
                )
            }}
        />
    )
}

export default withFormContext(FormComponent)
