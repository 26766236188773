export const NewGoalKeeperExerciseConf = {
    body: {
        duration: {
            component: 'EditText',
            name: 'duration',
            label: 'Durata',
            required: false,
            disabled: false,
            type: 'text',
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        rest: {
            component: 'EditText',
            name: 'rest',
            label: 'Recupero',
            required: false,
            disabled: false,
            type: 'text',
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        players: {
            component: 'SelectMultiple',
            name: 'surname',
            others: 'name',
            label: 'Giocatori',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutGoalkeeper: {
            component: 'Select',
            name: 'workout_goalkeeper',
            node: 'name',
            label: 'Categoria esercizio',
            required: false,
            disabled: false,            
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        theme: {
            component: 'Select',
            name: 'theme',
            node: 'theme',
            label: 'Tema esercizio',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutModality: {
            component: 'Select',
            name: 'workout_modality',
            node: 'name',
            label: 'Modalità esercizio',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        kickType: {
            component: 'Select',
            name: 'kick_type',
            node: 'name',
            label: 'Tipologia tiro',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        ballType: {
            component: 'Select',
            name: 'ball_type',
            node: 'name',
            label: 'Tipologia palla',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutCoachGroup: {
            component: 'Checkbox',
            name: 'workout_coach_group',
            node: 'name',
            label: 'Gruppo',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutCoachDescription: {
            component: 'EditArea',
            name: 'workout_coach_description',
            node: 'name',
            label: 'Descrizione',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },

        teamFieldWork: {
            component: 'Select',
            name: 'team_field_work',
            node: 'name',
            label: 'Lavoro con la squadra',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutTeamDescription: {
            component: 'EditArea',
            name: 'workout_team_description',
            node: 'name',
            label: 'Descrizione',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },


        gymWork: {
            component: 'Select',
            name: 'gym_work',
            node: 'name',
            label: 'Lavoro in palestra',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        limb: {
            component: 'Select',
            name: 'limb',
            node: 'name',
            label: 'Arti',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        equipment: {
            component: 'Select',
            name: 'equipment',
            node: 'name',
            label: 'Macchinari',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        psychokineticLightWork: {
            component: 'Select',
            name: 'psychokinetic_light_work',
            node: 'name',
            label: 'Lavoro psicocinetico luci',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        sandWork: {
            component: 'Select',
            name: 'sand_work',
            node: 'name',
            label: 'Sabbia',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        workoutSpecificDescription: {
            component: 'EditArea',
            name: 'workout_specific_description',
            node: 'name',
            label: 'Descrizione',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },

        ]
    }
};