import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Body.component.style';
import TableBody from '@material-ui/core/TableBody';

const BodyTemplate = ({
    children,
}) => {

    return (
        <TableBody>
            {children}
        </TableBody>
    )
}

export const BodyComponent = withStyles(styles)(BodyTemplate)