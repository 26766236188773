import {
    GET_NEXT_MATCH,
    GET_NEXT_MATCH_ERROR,
    GET_NEXT_MATCH_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: {
        trainings: [],
        medicalNotes: []
    }
};

const nextMatchReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_NEXT_MATCH:
            return {
                ...state,
                loading: true
            };

        case GET_NEXT_MATCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_NEXT_MATCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default nextMatchReducer;