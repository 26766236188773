export const StatisticsFormConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start_date',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',            
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end_date',
            label: 'End Date',
            required: true,
            disabled: false, 
            calendarType: 'DatePicker',           
            validation: {
                message: "This field is Required"
            },
        },                  
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};

export const StatisticsListConf = {
    toolbar: {
        title: ''
    },
    head: {
        cells: [            
            { label: 'Player', component: 'CellText', align:'left' },
            { label: 'Tot. days', component: 'CellText', align:'center' },
            { label: 'In Work Out', component: 'CellText', align:'center' },
            { label: 'In Match', component: 'CellText', align:'center' },
            { label: 'Direct', component: 'CellText', align:'center' },
            { label: 'Indirect', component: 'CellText', align:'center' },
            { label: 'Field Natural', component: 'CellText', align:'center' },
            { label: 'Field Sintetic', component: 'CellText', align:'center' },
        ]
    },
    body: {
        cells: [
            { key: 'name', component: 'CellText', align:'left' },
            { key: 'totDays', component: 'CellText', align:'center' },
            { key: 'inWorkOut', component: 'CellText', align:'center' },
            { key: 'inMatch', component: 'CellText', align:'center' },
            { key: 'direct', component: 'CellText', align:'center' },
            { key: 'indirect', component: 'CellText', align:'center' },
            { key: 'fieldNatural', component: 'CellText', align:'center' },
            { key: 'fieldSintetic', component: 'CellText', align:'center' },
        ]
    },        
};

