import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import { rootReducer } from './redux/reducers/root.reducer';
import creteSagaMiddleware from 'redux-saga';
import { rootSaga } from './redux/sagas/rootSaga'
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from './serviceWorker';

import StyleProvider from './Style';

export const history = createBrowserHistory();
const sagaMiddleware = creteSagaMiddleware();
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
);
sagaMiddleware.run(rootSaga);


const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <StyleProvider>
        <App />
      </StyleProvider>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
