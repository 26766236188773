export const styles = theme => ({
    card: {
        width: '100%'
    },
    title: {
        display: 'flex',
    },
    content: {
        paddingTop: 0,
    },
    header: {
        paddingBottom: 0,
    },
    moreDetails:{
        width: '100%',
        textAlign: 'end',
        fontSize: theme.spacing(1.8),
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});