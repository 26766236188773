import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getStatistics,
    getStatisticsError,
    getStatisticsSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';


function* getStatisticsSaga(action) {

    const { access_token, values } = action.payload

    try {
        // Fetch List
        let response = yield call(API.medicals.getInjuryStatistics, { access_token, values });
        response.data.filter = values;
        yield put(getStatisticsSuccess(response.data))

    } catch (e) {
        yield put(getStatisticsError(e))
    }
}


export function* statisticsWatcherSaga() {
    yield takeLatest(getStatistics, getStatisticsSaga);
}