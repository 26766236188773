import React from 'react';
import BoxComponent from '../../components/ui/box/Box.component';
import CardComponent from '../../components/ui/card/Card.component';
import moment from 'moment';
// import { appColor } from '../../config/colors/colors';

const mainContent = (training_group, pitch_position, combined_pitch_position, training_role, objective, note) => {
    const titles = [
        'Training Group:',
        'Pitch Position:',
        'Combined Pitch Position:',
        'Training Role:',
        'Objective:',
        'Note:'
    ]

    const values = [
        training_group,
        pitch_position.map(position => ` | ${position.pitch_position} `),
        combined_pitch_position.map(position => ` | ${position.combined_pitch_position} `),
        training_role.map(role => ` | ${role.training_role} `),
        objective.map(obj => ` | ${obj.objective} `),
        note
    ]

    return content(titles, values)
}

const otherContent = (work_proposal) => {

    const titles = [
        'Excercises:'
    ]

    const values = [
        work_proposal.map(excercise => ` | ${excercise.name.name} `)
    ]


    return content(titles, values)
}

const content = (titles, values) => {

    return (
        titles.map((item, index) =>
            <BoxComponent key={item} width="100%" display="flex" flexDirection="row" mt={2}>
                <BoxComponent
                    fontWeight='fontWeightRegular'
                    textAlign="left"
                    mr={1}>{item}</BoxComponent>
                <BoxComponent
                    textAlign="left"
                    fontWeight='fontWeightLight'>{values[index]}</BoxComponent>
            </BoxComponent>
        )
    )
}



const Training = ({
    training,
}) => {

    const {
        date,
        duration,
        training_plan,
        training_group,
        pitch_position,
        combined_pitch_position,
        training_role,
        objective,
        work_proposal,
        note
    } = training;

    const title = `${training_plan.training_plan}`;
    const subheader = `${moment(date).format('YYYY/MM/DD')} - ${duration}`;

    // const colors = [
    //     appColor.black,
    //     appColor.green,
    //     appColor.deepPurple,
    //     appColor.red,
    //     appColor.orange,
    // ]

    return (
        <CardComponent
            title={title}
            subheader={subheader}
            iconColor={`#${training_plan.color}`}
            mainContent={mainContent(training_group, pitch_position, combined_pitch_position, training_role, objective, note)}
            otherContent={otherContent(work_proposal)}>
        </CardComponent>
    )
}

export default Training;