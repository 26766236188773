import React, { useState } from 'react';
import { withDashboardContext } from '../../hoc';
import { MenuItemComponent } from '../../../ui/menu/item/MenuItem.component';
import { MenuItemTextComponent } from '../../../ui/menu/text/MenuText.component';
import { MenuItemIconComponent } from '../../../ui/menu/icon/MenuIcon.component';
import { Collapse } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import { appColor } from '../../../../config/colors/colors'

import shortid from "shortid";

import {
    IconTrainingComponent,
    IconNextMatchComponent,
    IconEventsMatchComponent,
    IconMedicalComponent,
    IconExpandLessComponent,
    IconExpandMoreComponent,
    IconCalendarComponent,
    IconListComponent,
    IconTeamComponent,
    IconStatisticsComponent,
    IconInjuryDeskComponent,
    IconMedicalNotesComponent,
    IconSettingsComponent
} from '../../../ui/icon/Icon.component';

const icon_types = {
    IconTrainingComponent,
    IconNextMatchComponent,
    IconEventsMatchComponent,
    IconMedicalComponent,
    IconCalendarComponent,
    IconListComponent,
    IconTeamComponent,
    IconStatisticsComponent,
    IconInjuryDeskComponent,
    IconMedicalNotesComponent,
    IconSettingsComponent
};

const DrawerMenu = ({
    dashboardContext,
    item,
    index,
    selected,
    onClick,
}) => {

    const {
        actions: {
            handleGoToLink,
        },
    } = dashboardContext;

    const [openMenu, setOpenMenu] = useState(false)
    const toggleCollapse = () => {
        setOpenMenu(!openMenu);
    }

    const goTolink = (link) => {
        handleGoToLink(link)
        onClick(index)
    }


    const renderItem = (item, nested = false, subItem = false) => {
        const Icon = icon_types[item.icon];
        return (
            <React.Fragment key={shortid.generate()}>
                <MenuItemComponent
                    //key={shortid.generate()}
                    action={() => item.nested ? toggleCollapse() : goTolink(item.link)}
                    nested={nested}>
                    <MenuItemIconComponent >
                        <Icon action={() => { }} selected={subItem ? false : selected} />
                    </MenuItemIconComponent>
                    <MenuItemTextComponent text={item.title.toUpperCase()} selected={subItem ? false : selected} />
                    {item.nested ? openMenu ? <IconExpandLessComponent selected={subItem ? false : selected} /> : <IconExpandMoreComponent selected={subItem ? false : selected} /> : null}
                </MenuItemComponent>
                {item.divider && <Divider />}
            </React.Fragment>

        )
    }

    return (
        <>
            {renderItem(item)}
            {item.nested ?
                <Collapse in={openMenu} timeout="auto" unmountOnExit style={{ backgroundColor: appColor.darkBlue }}>
                    {item.nested.map(nestedItem => renderItem(nestedItem, true, true))}
                </Collapse>
                : null}

        </>
    )
};

export default withDashboardContext(DrawerMenu);