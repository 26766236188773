export const styles = theme => ({
    action: {
        textAlign: 'right',
        padding: 0,
    },
    content: {
        // display: 'flex',
        // alignItems: 'right',
        // justifyContent: 'flex-right',
    },
});