import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import ListProvider from '../../../components/list/provider';
import List from '../../../components/list/ui';
import ListHead from '../../../components/list/ui/head';
import ListBody from '../../../components/list/ui/body';
import BoxComponent from '../../../components/ui/box/Box.component';
import ProgressComponent from '../../../components/ui/progress/Progress.component';
import TabsProvider from '../../../components/tabs/provider';
import Tabs from '../../../components/tabs/ui';

import useTabs from '../../../hooks/useTabs';

import { getSquad } from "../../../redux/actions/actions";
import {
    selectSquad,
    selectTraining
} from "../../../redux/selectors/selectors";
import { withAuthContext } from '../../../contexts/auth/Auth.context';

import { GroupsConf } from '../../../config/training/GroupsExercise.conf';

import TrainingBox from '../../../components/training/box'


const GroupsList = ({ item }) => {

    
    // Sort by role
    const items = item.value.sort((a, b) => (a.role.id > b.role.id) ? 1 : (b.role.id > a.role.id) ? -1 : 0)    

    return (
        <BoxComponent height={500} style={{ overflowY: 'auto', overflowX: 'hidden' }} >
            <ListProvider
                head={item.conf.head}
                body={item.conf.body}
                data={items}>
                <List>
                    <ListHead />
                    <ListBody />
                </List>
            </ListProvider>
        </BoxComponent>
    )
}


function filterPlayers(type, players) {

    switch (type) {
        case 'GoalKeepers':
            return players.filter(player => player.role.id === 1)
        default:
            return players.filter(player => player.role.id !== 1)
    }
}


const GroupsExercisePage = ({
    authContext,
    groups,
    getGroups,
    training,
    type,
    title,
}) => {

    const { index, setIndex } = useTabs(0)
    useEffect(() => {
        if (training.data !== null) {
            getGroups({
                access_token: authContext.access_token,
                teams: training.data.team.id,
                date: training.data.date,
            })
        }
    }, [training.data]);

    
    const tabs = []

    if (groups.data !== null) {

        Object.keys(groups.data[0]).forEach(key => {            
            if (key !== 'team') {
                const item = groups.data[0][key] 
                const players = filterPlayers(type, item.players)               
                const tab = {
                    title: `${item.label} (${players.length})`,
                    conf: GroupsConf,
                    value: players,
                    count: players.length
                }
                tabs.push(tab)
            }
        });

        tabs.sort(function(a,b) {
            return b.count - a.count
        })
    }

    return (
        <TrainingBox
            title={title}>
            <TabsProvider
                index={index}
                onChange={(index) => {
                    setIndex(index)
                }}
                tabs={tabs}>
                <Tabs />
                {groups.loading || tabs.length === 0 ? <ProgressComponent /> : <GroupsList item={tabs[index]} />}
            </TabsProvider>
        </TrainingBox>
    )
}

const mapStateToProps = state => ({
    groups: selectSquad(state),
    training: selectTraining(state),
});

const mapDispatchToProps = dispatch => ({
    getGroups: (item) => {
        dispatch(getSquad(item));
    }
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(GroupsExercisePage));