import { createAction } from 'redux-actions';


// DailyReport
export const GET_DAILY_REPORT = 'GET_DAILY_REPORT';
export const getDailyReport = createAction(GET_DAILY_REPORT);
export const GET_DAILY_REPORT_ERROR = 'GET_DAILY_REPORT_ERROR';
export const getDailyReportError = createAction(GET_DAILY_REPORT_ERROR);
export const GET_DAILY_REPORT_SUCCESS = 'GET_DAILY_REPORT_SUCCESS';
export const getDailyReportSuccess = createAction(GET_DAILY_REPORT_SUCCESS);


// Injury Desk
export const GET_INJURY_DESK = 'GET_INJURY_DESK';
export const getInjuryDesk = createAction(GET_INJURY_DESK);
export const GET_INJURY_DESK_ERROR = 'GET_INJURY_DESK_ERROR';
export const getInjuryDeskError = createAction(GET_INJURY_DESK_ERROR);
export const GET_INJURY_DESK_SUCCESS = 'GET_INJURY_DESK_SUCCESS';
export const getInjuryDeskSuccess = createAction(GET_INJURY_DESK_SUCCESS);

// Statistics
export const GET_STATISTICS = 'GET_STATISTICS';
export const getStatistics = createAction(GET_STATISTICS);
export const GET_STATISTICS_ERROR = 'GET_STATISTICS_ERROR';
export const getStatisticsError = createAction(GET_STATISTICS_ERROR);
export const GET_STATISTICS_SUCCESS = 'GET_STATISTICS_SUCCESS';
export const getStatisticsSuccess = createAction(GET_STATISTICS_SUCCESS);


//Next Match
export const GET_NEXT_MATCH = 'GET_NEXT_MATCH';
export const getNextMatch = createAction(GET_NEXT_MATCH);
export const GET_NEXT_MATCH_ERROR = 'GET_NEXT_MATCH_ERROR';
export const getNextMatchError = createAction(GET_NEXT_MATCH_ERROR);
export const GET_NEXT_MATCH_SUCCESS = 'GET_NEXT_MATCH_SUCCESS';
export const getNextMatchSuccess = createAction(GET_NEXT_MATCH_SUCCESS);
