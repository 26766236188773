import {
  GET_INJURY_TYPES,
  GET_INJURY_TYPES_ERROR,
  GET_INJURY_TYPES_SUCCESS
} from '../../actions/actions'

const initialState = {
  loading: false,
  error: null,
  data: [],
};

const injuryTypesReducer = (state = initialState, action) => {
  switch (action.type) {

      case GET_INJURY_TYPES:
          return {
              ...state,
              loading: true
          };

      case GET_INJURY_TYPES_SUCCESS:
          return {
              ...state,
              loading: false,
              error: null,
              data: action.payload.data
          };

      case GET_INJURY_TYPES_ERROR:
          return {
              ...state,
              loading: false,
              error: action.payload,
          };

      default:
          return state

  }
};

export default injuryTypesReducer;