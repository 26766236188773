import _groupBy from 'lodash/groupBy'
import {
    GET_WORK_PROPOSALS,
    GET_WORK_PROPOSALS_ERROR,
    GET_WORK_PROPOSALS_SUCCESS
} from '../../actions/actions'


const initialState = {
    loading: false,
    error: null,
    data: {
        Other: [],
    }
};

const workProposalReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_WORK_PROPOSALS:
            return {
                ...state,
                loading: true
            };

        case GET_WORK_PROPOSALS_SUCCESS:
            const { data } = action.payload;
            const grouped = _groupBy(data, item => item.work_proposal_type.work_proposal_type)
            return {
                ...state,
                loading: false,
                error: null,
                data: {
                    Other: grouped['Other']? grouped['Other'] : [],
                }
            };

        case GET_WORK_PROPOSALS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default workProposalReducer;