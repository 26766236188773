import React from "react";
import { Route } from 'react-router-dom';

const PrivateRoute = ({
	path,
	component
}) => {

	return (
		<React.Fragment>
			<Route path={path} component={component} />
		</React.Fragment>
	);
};

export default PrivateRoute;
