export const AvailableGroupsConf = {
    toolbar: {
        title: 'Available'
    },
    head: {
        cells: [
            { label: '', component: 'CellText' },
            { label: 'N°', component: 'CellText' },
            { label: 'Name', component: 'CellText' },            
            { label: 'Role', component: 'CellText' },
            { label: 'Birthdate', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'image', component: 'CellImage' },
            { key: 'number', component: 'CellText', },
            { key: 'name', otherKey:'surname', component: 'CellText' },
            { key: 'role.role', component: 'CellText' },
            { key: 'birthdate', component: 'CellText' },
        ]
    },
};


export const NotAvailableGroupsConf = {
    toolbar: {
        title: 'Not Available'
    },
    head: {
        cells: [
            { label: '', component: 'CellText' },
            { label: 'N°', component: 'CellText' },
            { label: 'Name', component: 'CellText' },            
            { label: 'Role', component: 'CellText' },
            { label: 'Birthdate', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'image', component: 'CellImage' },
            { key: 'number', component: 'CellText' },
            { key: 'name', otherKey:'surname', component: 'CellText' },
            { key: 'role.role', component: 'CellText' },
            { key: 'birthdate', component: 'CellText' },
        ]
    },
};

export const DifferentiateGroupsConf = {
    toolbar: {
        title: 'Differentiate'
    },
    head: {
        cells: [
            { label: '', component: 'CellText' },
            { label: 'N°', component: 'CellText' },
            { label: 'Name', component: 'CellText' },            
            { label: 'Role', component: 'CellText' },
            { label: 'Birthdate', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'image', component: 'CellImage' },
            { key: 'number', component: 'CellText' },
            { key: 'name', otherKey:'surname', component: 'CellText' },
            { key: 'role.role', component: 'CellText' },
            { key: 'birthdate', component: 'CellText' },
        ]
    },
};


export const GroupsConf = {
    toolbar: {
        title: ''
    },
    head: {
        cells: [
            { label: '', component: 'CellText' },
            { label: 'N°', component: 'CellText' },
            { label: 'Name', component: 'CellText' },            
            { label: 'Role', component: 'CellText' },
            { label: 'Birthdate', component: 'CellText' },
        ]
    },
    body: {
        cells: [
            { key: 'image', component: 'CellImage' },
            { key: 'number', component: 'CellText', },
            { key: 'name', otherKey:'surname', component: 'CellText' },
            { key: 'role.role', component: 'CellText' },
            { key: 'birthdate', component: 'CellText' },
        ]
    },
};