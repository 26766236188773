import moment from 'moment'

export default {

    getMedicalNotesFilter: ({ values }) => {
        const startDate = moment(values.start_date).format('YYYY-MM-DD');
        const endDate = values.end_date ? moment(values.end_date).format('YYYY-MM-DD') : moment(values.start_date).format('YYYY-MM-DD');

        var params = new URLSearchParams();
        params.append("start_date", startDate);
        params.append("end_date", endDate);

        if (values.surname) {
            values.surname.forEach(element => {
                params.append("player", element.value);
            });
        }

        if (values.injury_type) {
            values.injury_type.forEach(element => {
                params.append("injury_type", element.value);
            })
        }

        if (values.therapy) {
            values.therapy.forEach(element => {
                params.append("therapies", element.value);
            })
        }

        if (values.work_program) {
            values.work_program.forEach(element => {
                params.append("work_programs", element.value);
            })
        }

        return {
            params: params
        }
    },

    upsertMedicalNote: ({ values, id }) => {
        var body = new FormData();

        // ID
        if (id) {
            body.set('id', id);
        }

        // Player
        if (values.surname) {
            body.set('player', values.surname);
        }

        // Start
        if (values.start) {
            body.set('start', moment(values.start).format('YYYY-MM-DD') + 'T00:00:0.0000Z');
        }

        // End
        if (values.end) {
            body.set('end', moment(values.end).format('YYYY-MM-DD') + 'T23:59:59.0000Z');
        }

        // InjuryType
        if (values.injury_type) {
            body.set('injury_type', values.injury_type);
        }

        // EventType
        if (values.event_type) {
            body.set('event_type', values.event_type);
        }

        // TraumaType
        if (values.trauma_type) {
            body.set('trauma_type', values.trauma_type);
        }

        // FieldType
        if (values.field_typology) {
            body.set('field_typology', values.field_typology);
        }

        // Availability
        if (values.availability) {
            body.set('availability', values.availability);
        }

        // Note
        if (values.note) {
            body.set('note', values.note);
        }

        // Therapy
        if (values.therapy) {
            values.therapy.map(item =>
                body.append('therapies', item.value)
            )
        }

        // Work Program
        if (values.work_program) {
            values.work_program.map(item =>
                body.append('work_programs', item.value)
            )
        }

        return body
    },


    upsertWorkProgram: ({ values, id }) => {
        var body = new FormData();

        // ID
        if (id) {
            body.set('id', id);
        }

        // Work program
        if (values.work_program) {
            body.set('work_program', values.work_program);
        }

        return body
    },

    upsertInjuryType: ({ values, id }) => {
        var body = new FormData();

        if (id) {
            body.set('id', id);
        }

        if (values.injury_type) {
            body.set('injury_type', values.injury_type);
        }

        return body
    },
}