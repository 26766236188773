import React from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from "../../../contexts/auth/Auth.context"
import { FormConf } from "../../../config/settings/form/ExerciseName.config"
import ExerciseNameForm from '../../../components/settings/form/exerciseName';

import {
    addExerciseName,
    updateExerciseName
} from "../../../redux/actions/actions"

const getInitialValue = (data) => {    
    return {
        id: data.id,
        category: data.category.id,
        name: data.name
    }
}


const NewExerciseNamePage = ({
    authContext,
    addExerciseName,
    updateExerciseName,
    onDismiss,
    item,
}) => {

    const {
        body,
        footer
    } = FormConf;

    const update = values => {
        updateExerciseName({
            access_token: authContext.access_token,
            values: values,
            id: item.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addExerciseName({
            access_token: authContext.access_token,
            values: values,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        item !== null ? update(values) : add(values)
    };

    return (
        <ExerciseNameForm
            body={body}
            footer={footer}
            onSubmit={onSubmit}
            initialValues={item ? getInitialValue(item) : {}}
            disabled={false} />
    );
};


const mapDispatchToProps = dispatch => ({
    addExerciseName: body => {
        dispatch(addExerciseName(body))
    },
    updateExerciseName: body => {
        dispatch(updateExerciseName(body))
    }
});

export default withAuthContext(connect(null, mapDispatchToProps)(NewExerciseNamePage));