import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Progress.component.style'
import LinearProgress from '@material-ui/core/LinearProgress';


const Progress = ({
  classes,
}) => {
  return (
    <LinearProgress color="primary" className={classes.root}/>
  );
}


export default withStyles(styles)(Progress);