import { createAction } from "redux-actions"

// Training
export const GET_TRAININGS = "GET_TRAININGS"
export const getTrainings = createAction(GET_TRAININGS)
export const GET_TRAININGS_ERROR = "GET_TRAININGS_ERROR"
export const getTrainingsError = createAction(GET_TRAININGS_ERROR)
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS"
export const getTrainingsSuccess = createAction(GET_TRAININGS_SUCCESS)

export const ADD_TRAINING = "ADD_TRAINING"
export const addTraining = createAction(ADD_TRAINING)
export const UPDATE_TRAINING = "UPDATE_TRAINING"
export const updateTraining = createAction(UPDATE_TRAINING)
export const GET_TRAINING = "GET_TRAINING"
export const getTraining = createAction(GET_TRAINING)
export const DELETE_TRAINING = "DELETE_TRAINING"
export const deleteTraining = createAction(DELETE_TRAINING)

export const ACTION_TRAINING_SUCCESS = "ACTION_TRAINING_SUCCESS"
export const actionTrainingSuccess = createAction(ACTION_TRAINING_SUCCESS)
export const ACTION_TRAINING_ERROR = "ACTION_TRAINING_ERROR"
export const actionTrainingError = createAction(ACTION_TRAINING_ERROR)

export const PATCH_TRAINING_FILE = "PATCH_TRAINING_FILE"
export const patchTrainingFile = createAction(PATCH_TRAINING_FILE)

// Training Desk
export const GET_TRAINING_DESK = "GET_TRAINING_DESK"
export const getTrainingDesk = createAction(GET_TRAINING_DESK)
export const GET_TRAINING_DESK_ERROR = "GET_TRAINING_DESK_ERROR"
export const getTrainingDeskError = createAction(GET_TRAINING_DESK_ERROR)
export const GET_TRAINING_DESK_SUCCESS = "GET_TRAINING_DESK_SUCCESS"
export const getTrainingDeskSuccess = createAction(GET_TRAINING_DESK_SUCCESS)
