import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getTrainingDesk,
    getTrainingDeskError,
    getTrainingDeskSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';


function* getTrainingDeskSaga(action) {

    const { access_token, values } = action.payload
    try {
        // Fetch List
        let response = yield call(API.coaching.getWorkProposalFilter, { access_token, values });
        response.data.filter = values;
        yield put(getTrainingDeskSuccess(response.data))

    } catch (e) {
        yield put(getTrainingDeskError(e))
    }
}


export function* trainingDeskWatcherSaga() {
    yield takeLatest(getTrainingDesk, getTrainingDeskSaga);
}