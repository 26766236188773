import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getInjuryDesk,
    getInjuryDeskError,
    getInjuryDeskSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';


function* getInjuryDeskSaga(action) {

    const { access_token, values } = action.payload
    try {
        // Fetch List
        let response = yield call(API.medicals.getMedicalNotesFilter, { access_token, values });
        response.data.filter = values;
        yield put(getInjuryDeskSuccess(response.data))

    } catch (e) {
        yield put(getInjuryDeskError(e))
    }
}


export function* injuryDeskWatcherSaga() {
    yield takeLatest(getInjuryDesk, getInjuryDeskSaga);
}