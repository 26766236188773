import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Gallery.component.style';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

const GalleryTemplate = ({
    classes,
    items,
    cellHeight = 250,
}) => {

    return (
        <div className={classes.root}>
            <GridList cellHeight={cellHeight} cols={3}>                
                {items.map((tile, index) => (
                    <GridListTile key={index} className={classes.gridItem}>
                        <img src={tile.img} alt={tile.title} style={{ maxHeight: '100%', maxWidth: '100%' }} />                        
                    </GridListTile>
                ))}
            </GridList>
        </div>

    )
}

export const GalleryComponent = withStyles(styles)(GalleryTemplate) 