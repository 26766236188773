import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Notifications.component.style'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const NotificationsComponent = ({
    classes,
    notifications,
    handleDelete,
}) => {

    return (
        <List>
            {notifications.map(({ id, primary, secondary }) => (
                <ListItem key={id}>
                    <ListItemText
                        primary={primary}
                        secondary={secondary} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() =>  handleDelete(id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    )
}

export default withStyles(styles)(NotificationsComponent);