import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const TabsComponent = ({
    children,
    index,
    onChange,
    tabs,
}) => {

    return (
        <>
            <Tabs
                value={index}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                onChange={(event, index) => onChange(index)}>
                {tabs.map((tab, key) => {
                    return (
                        <Tab
                            label={tab.title}
                            key={key} />
                    )
                })}
            </Tabs>
            <div>
                {children}
            </div>
        </>
    )
}

export default TabsComponent;