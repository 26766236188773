import { all } from "redux-saga/effects"
import { authWatcherSaga } from "./user/authSaga"

import { workProposalsWatcherSaga } from "./workProposal/workProposalsSaga"

import { notesWatcherSaga } from "./notes/notesSaga"

import { notificationsWatcherSaga } from "./notification/notificationSaga"

import { medicalWatcherSaga } from "./medical/medicalSaga"

import { dailyReportWatcherSaga } from "./report/dailyReportSaga"
import { injuryDeskWatcherSaga } from "./report/injuryDeskSaga"
import { nextMatchWatcherSaga } from "./report/nextMatchSaga"
import { statisticsWatcherSaga } from "./report/statisticsSaga"

import { squadWatcherSaga } from "./team/squadSaga"
import { teamsWatcherSaga } from "./team/teamsSaga"

import { goalKeeperWorkProposalsWatcherSaga } from "./goalkeeper/goalKeeperWorkProposalsSaga"

import { trainingDeskWatcherSaga } from "./training/trainingDeskSaga"
import { trainingsWatcherSaga } from "./training/trainingsSaga"

import { injuryTypesWatcherSaga } from "./settings/injuryTypesSaga"
import { workProgramsWatcherSaga } from "./settings/workProgramsSaga"
import { teamFieldWorksWatcherSaga } from "./settings/teamFieldWorksSaga"
import { exerciseCategoriesWatcherSaga } from "./settings/exerciseCategoriesSaga"
import { exerciseImagesWatcherSaga } from "./settings/exerciseImagesSaga"
import { exerciseNamesWatcherSaga } from "./settings/exerciseNamesSaga"
import { competitionsWatcherSaga } from "./club/competitionsSaga"
import { clubTeamsWatcherSaga } from "./club/teamsSaga"
import { clubCategoriesWatcherSaga } from "./club/clubCategoriesSaga"
import { clubLinksWatcherSaga } from "./club/clubLinksSaga"

export function* rootSaga() {
    yield all([
        authWatcherSaga(),
        trainingsWatcherSaga(),
        workProposalsWatcherSaga(),
        notesWatcherSaga(),
        notificationsWatcherSaga(),
        medicalWatcherSaga(),
        squadWatcherSaga(),
        dailyReportWatcherSaga(),
        injuryDeskWatcherSaga(),
        nextMatchWatcherSaga(),
        statisticsWatcherSaga(),
        teamsWatcherSaga(),
        goalKeeperWorkProposalsWatcherSaga(),
        trainingDeskWatcherSaga(),
        injuryTypesWatcherSaga(),
        workProgramsWatcherSaga(),
        teamFieldWorksWatcherSaga(),
        exerciseCategoriesWatcherSaga(),
        exerciseImagesWatcherSaga(),
        exerciseNamesWatcherSaga(),
        competitionsWatcherSaga(),
        clubTeamsWatcherSaga(),
        clubCategoriesWatcherSaga(),
        clubLinksWatcherSaga()
    ])
}
