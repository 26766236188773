import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import _orderBy from 'lodash/orderBy';

import useModal from '../../../hooks/useModal';

import {
    getWorkProposals,
    deleteWorkProposal,
    orderWorkProposals,
    resetWorkProposal,
} from "../../../redux/actions/actions";

import { selectWorkProposals } from "../../../redux/selectors/selectors";
import { withAuthContext } from '../../../contexts/auth/Auth.context';

import ProgressComponent from '../../../components/ui/progress/Progress.component';
import TrainingBox from '../../../components/training/box';
import NewExercise from './NewExercise.container';
import FormModal from '../../../components/form/ui/modal';
import Exercise from '../../../components/exercise/other';
import DragAndDropList from '../../../components/dragAndDrop'
import BoxComponent from '../../../components/ui/box/Box.component';

const ExercisesPage = ({
    authContext,
    trainingId,
    exercises,
    enabled,
    getWorkProposals,
    orderWorkProposals,
    deleteWorkProposal,
    resetWorkProposal
}) => {

    const { openModal, closeModal, isModalShowing } = useModal();
    const [currentExercise, setCurrentExercise] = useState(null)

    useEffect(() => {
        getWorkProposals({
            access_token: authContext.access_token,
            id: trainingId,
        })
    }, []);

    const onDragEndCallback = (items) => {
        let actions = { access_token: authContext.access_token, items: [], training: trainingId }
        items.forEach((element, index) =>
            actions.items.push({ number: index + 1, id: element.id })
        )
        orderWorkProposals(actions)
    }

    const onAddExercise = () => {
        setCurrentExercise(null)
        openModal()
    }

    const onDeleteExercise = (id) => {
        deleteWorkProposal({
            access_token: authContext.access_token,
            id: id,
            training: trainingId,
            work_proposal_type: 2
        })
    }

    const onUpdateExercise = (id) => {
        setCurrentExercise(id)
        openModal()
    }

    const workProposals = exercises.data.Other.sort((a, b) => (a.number > b.number) ? 1 : -1)

    return (
        <TrainingBox
            title='Exercises'
            action={() => onAddExercise()}
            disabled={!enabled}>
            <FormModal
                open={isModalShowing}
                onCancel={() => {
                    setCurrentExercise(null)
                    resetWorkProposal()
                    closeModal()
                }}
                onSave={() => {
                    setCurrentExercise(null)
                    resetWorkProposal()
                    closeModal()
                }}
                title={currentExercise ? 'Update Exercise' : 'Add Exercise'}>
                <NewExercise
                    exerciseId={currentExercise}
                    onDismiss={() => {
                        setCurrentExercise(null)
                        resetWorkProposal()
                        closeModal()
                    }}
                />
            </FormModal>
            {exercises.loading ?
                <ProgressComponent /> :
                <BoxComponent height={550} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                    <DragAndDropList
                        rows={workProposals}
                        disabled={!enabled}
                        dragEndCallback={(items) => onDragEndCallback(items)}
                        renderItem={(item) =>
                            <Exercise
                                item={item}
                                onDeleteExercise={onDeleteExercise}
                                onUpdateExercise={onUpdateExercise}
                                disabled={!enabled} />
                        }
                    />
                </BoxComponent>
            }
        </TrainingBox>
    )
};

const mapStateToProps = state => ({
    exercises: selectWorkProposals(state)
});

const mapDispatchToProps = dispatch => ({
    getWorkProposals: (item) => {
        dispatch(getWorkProposals(item));
    },
    deleteWorkProposal: (item) => {
        dispatch(deleteWorkProposal(item))
    },
    resetWorkProposal: () => {
        dispatch(resetWorkProposal())
    },
    orderWorkProposals: (body) => {
        dispatch(orderWorkProposals(body));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(ExercisesPage));