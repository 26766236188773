export const styles = theme => ({    
    big:{
        margin: 'auto',
        width: 100,
        height: 100,        
    },
    small: {
        margin: 'auto',
        width: 50,
        height: 50,
    }
});