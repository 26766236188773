import React from 'react';
import { FormContext } from '../context';

const FormProvider = ({
    children,
    body,
    footer,
    initialValues,
    onSubmit,
    onDelete,
    onPrint,
    onChange,
    onValidate,
    edit = true,
}) => {

    return (
        <FormContext.Provider
            value={{
                formContext: {
                    body: body,
                    footer: footer,
                    initialValues: initialValues,
                    edit: edit,
                    actions: {
                        onSubmit: onSubmit,
                        onDelete: onDelete,
                        onPrint: onPrint,
                        onValidate: onValidate,
                        onChange: onChange                        
                    }
                }
            }}>
            {children}
        </FormContext.Provider>
    )
}
export default FormProvider;
