import { createAction } from 'redux-actions';

// GoalKeepers Work Proposal
export const GET_GOALKEEPER_WORK_PROPOSALS = 'GET_GOALKEEPER_WORK_PROPOSALS';
export const getGoalKeeperWorkProposals = createAction(GET_GOALKEEPER_WORK_PROPOSALS);
export const GET_GOALKEEPER_WORK_PROPOSALS_ERROR = 'GET_GOALKEEPER_WORK_PROPOSALS_ERROR';
export const getGoalKeeperWorkProposalsError = createAction(GET_GOALKEEPER_WORK_PROPOSALS_ERROR);
export const GET_GOALKEEPER_WORK_PROPOSALS_SUCCESS = 'GET_GOALKEEPER_WORK_PROPOSALS_SUCCESS';
export const getGoalKeeperWorkProposalsSuccess = createAction(GET_GOALKEEPER_WORK_PROPOSALS_SUCCESS);

export const GET_GOALKEEPER_WORK_PROPOSAL = 'GET_GOALKEEPER_WORK_PROPOSAL';
export const getGoalKeeperWorkProposal = createAction(GET_GOALKEEPER_WORK_PROPOSAL);
export const RESET_GOALKEEPER_WORK_PROPOSAL = 'RESET_GOALKEEPER_WORK_PROPOSAL';
export const resetGoalKeeperWorkProposal = createAction(RESET_GOALKEEPER_WORK_PROPOSAL);
export const ADD_GOALKEEPER_WORK_PROPOSAL = 'ADD_GOALKEEPER_WORK_PROPOSAL';
export const addGoalKeeperWorkProposal = createAction(ADD_GOALKEEPER_WORK_PROPOSAL);
export const UPDATE_GOALKEEPER_WORK_PROPOSAL = 'UPDATE_GOALKEEPER_WORK_PROPOSAL';
export const updateGoalKeeperWorkProposal = createAction(UPDATE_GOALKEEPER_WORK_PROPOSAL);
export const DELETE_GOALKEEPER_WORK_PROPOSAL = 'DELETE_GOALKEEPER_WORK_PROPOSAL';
export const deleteGoalKeeperWorkProposal = createAction(DELETE_GOALKEEPER_WORK_PROPOSAL);

export const ACTION_GOALKEEPER_WORK_PROPOSAL_RESET = 'ACTION_GOALKEEPER_WORK_PROPOSAL_RESET';
export const actionGoalKeeperWorkProposalReset = createAction(ACTION_GOALKEEPER_WORK_PROPOSAL_RESET);
export const ACTION_GOALKEEPER_WORK_PROPOSAL_SUCCESS = 'ACTION_GOALKEEPER_WORK_PROPOSAL_SUCCESS';
export const actionGoalKeeperWorkProposalSuccess = createAction(ACTION_GOALKEEPER_WORK_PROPOSAL_SUCCESS);
export const ACTION_GOALKEEPER_WORK_PROPOSAL_ERROR = 'ACTION_GOALKEEPER_WORK_PROPOSAL_ERROR';
export const actionGoalKeeperWorkProposalError = createAction(ACTION_GOALKEEPER_WORK_PROPOSAL_ERROR);

export const ORDER_GOALKEEPER_GOALKEEPER_WORK_PROPOSALS = 'ORDER_GOALKEEPER_GOALKEEPER_WORK_PROPOSALS';
export const orderGoalKeeperWorkProposals = createAction(ORDER_GOALKEEPER_GOALKEEPER_WORK_PROPOSALS);

