import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Head.component.style'; 
import TableHead from '@material-ui/core/TableHead';

const HeadTemplate = ({    
    children,
    classes
}) => {
    return (
        <TableHead className={classes.root}>
            {children}
        </TableHead>    
    )
}
export const HeadComponent = withStyles(styles)(HeadTemplate)




