import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CellEmpty.component.style';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const CellEmptyTemplate = ({
    emptyRows
}) => {

    return (
        <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={6} />
        </TableRow>
    )
}

export const CellEmptyComponent = withStyles(styles)(CellEmptyTemplate)