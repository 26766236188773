export const LoginConf = {
    body: {

        username: {
            component: 'EditText',
            name: 'username',
            label: 'Username',
            required: true,
            disabled: false,
            type: 'text',
            validation: {
                message: "Insert a valid username"
            },
        },
        password: {
            component: 'EditText',
            name: 'password',
            label: 'Password',
            required: true,
            disabled: false,
            type: 'password',            
            validation: {
                message: ""
            },
        },                
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Login',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};