export const NewInjuryDeskConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start_date',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',            
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end_date',
            label: 'End Date',
            required: true,
            disabled: false, 
            calendarType: 'DatePicker',           
            validation: {
                message: "This field is Required"
            },
        },
        player: {
            component: 'Select',
            name: 'surname',
            others: 'name', 
            label: 'Player',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        },   
        injury: {
            component: 'SelectMultiple',
            name: 'injury_type',            
            label: 'Injury',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        },
        therapy: {
            component: 'SelectMultiple',
            name: 'therapy',            
            label: 'Therapy',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        }, 
        workProgram: {
            component: 'SelectMultiple',
            name: 'work_program',            
            label: 'Work Program',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        },          
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },            
        ]
    }
};