export const NewTrainingConf = {
    body: {
        date: {
            component: 'CalendarPicker',
            name: 'date',
            label: 'Date',
            required: true,
            disabled: false,
            xs: 6,            
            validation: {
                message: "This field is Required"
            },
        },
        duration: {
            component: 'EditText',
            name: 'duration',
            label: 'Duration',
            required: true,
            disabled: false,
            xs: 6,
            type: 'text',
            validation: {                
                message: "This field is Required"
            },
        },              
        trainingPlan: {
            component: 'Select',
            name: 'training_plan',
            label: 'Training Plan',
            required: true,
            disabled: false, 
            xs: 12,           
            validation: {                
                message: "This field is Required"
            },
        },
        objective:{
            component: 'Select',
            name: 'objective',
            label: 'Objective',
            required: false,
            disabled: false,
            xs: 6,
            validation: {                
                message: "This field is Required"
            },
        },
        trainingGroup:{
            component: 'Select',
            name: 'training_group',
            label: 'Group',
            required: false,
            disabled: false,
            xs: 12,
            validation: {
                message: "This field is Required"
            },
        },
        pitchPosition:{
            component: 'Select',
            name: 'pitch_position',
            label: 'Pitch Position',
            required: false,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        combinedPitchPosition:{
            component: 'Select',
            name: 'combined_pitch_position',
            label: 'Combined Pitch Position',
            required: false,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        trainingRole:{
            component: 'Select',
            name: 'training_role',
            label: 'Role',
            required: false,
            disabled: false,
            xs: 6,
            validation: {
                message: "This field is Required"
            },
        },
        note:{
            component: 'EditArea',
            name: 'note',
            label: 'note',
            required: false,
            disabled: false,
            rows: 4,
            type: 'text',
            xs: 12,
            validation: {                
                message: "This field is Required"
            },
        }                
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Save',
                variant: 'contained',
                color: 'primary',
            },                 
        ]
    }
};