import { useState } from 'react';

const useListSelection = (selectedValue) => {
    const [selected, setSelected] = useState(selectedValue);

    function setSelectedItem(id, multi = false) {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (multi) {

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            setSelected(newSelected)

        } else {            
            if (selectedIndex === -1) {
                newSelected = [id];
            } else  {
              newSelected = []  
            } 
            setSelected(newSelected)
        }
    }

    function setSelectedAll(items) {
        setSelected(items)
    }

    return {
        selected,
        setSelectedItem,
        setSelectedAll
    }
};

export default useListSelection;