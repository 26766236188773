import { put, call, takeLatest } from 'redux-saga/effects';
import {
    getNotifications,
    getNotificationsError,
    getNotificationsSuccess,

    updateNotification,

} from '../../actions/actions';

import API from '../../../services/axios/api';

function* refreshList({ access_token }) {

    try {
        // Fetch List
        let list = yield call(API.coaching.getTrainingNotes, { access_token });          
        yield put(getNotificationsSuccess(list.data.data))

    } catch (e) {
        yield put(getNotificationsError(e))
    }
}

function* getNotificationsSaga(action) {

    const { access_token } = action.payload;    
    yield call(refreshList, { access_token })
}


function* updateNotificationSaga(action) {

    const { access_token, values } = action.payload;
    
    try {
        yield call(API.coaching.setReadTrainingNote, { access_token, values });
    } catch (e) {
        yield put(getNotificationsError(e))
    }

    yield call(refreshList, { access_token })
}

export function* notificationsWatcherSaga() {
    yield takeLatest(getNotifications, getNotificationsSaga);    
    yield takeLatest(updateNotification, updateNotificationSaga);    
}