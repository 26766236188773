import React from 'react';
import {
  Calendar,
  momentLocalizer
} from 'react-big-calendar';

import { withCalendarContext } from '../hoc';
import moment from 'moment';

import { BodyComponent } from '../../ui/page/body/Body.component';
import "./style.css";

const localizer = momentLocalizer(moment)

const Event = ({ event }) => {
  return (
    <span>
      <strong>{event.title}</strong>
      {event.subtitle}
    </span>
  )
}


const BigCalendar = ({
  events,
  onSelectSlot,
  onSelectEvent,
  onEventPropGetter= () => {},
  onRangeChange = () => {},
}) => (
    <BodyComponent>
      <div style={{ width: '100%', height: "100vh" }}>
        <Calendar
          popup
          selectable={true}
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          views={['month']}
          events={events}
          onSelectEvent={event => onSelectEvent(event)}
          onSelectSlot={event => onSelectSlot(event)}   
          onRangeChange={range => onRangeChange(range)}                 
          eventPropGetter={event => onEventPropGetter(event)}
          components={{
            event: Event            
          }} />
      </div>
    </BodyComponent>
  )


export default withCalendarContext(BigCalendar);