import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';

import { styles } from './AppBar.component.style';

const AppBarTemplate = ({
    classes,
    openDrawer,
    onOpenDrawer,
    onOpenNotification,
    onClickLogout,
    title,
    badge,
}) => {

    return (
        <AppBar position="absolute" className={clsx(classes.appBar, openDrawer && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={onOpenDrawer}
                    className={clsx(classes.menuButton, openDrawer && classes.menuButtonHidden)}>
                    <MenuIcon />
                </IconButton>                
                <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                    {title}
                </Typography>                
                <IconButton
                    color="inherit"
                    onClick={onOpenNotification}>
                    <Badge
                        badgeContent={badge}
                        color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Button
                    color="primary"
                    onClick={onClickLogout}> 
                    Logout                   
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export const AppBarComponent = withStyles(styles)(AppBarTemplate);