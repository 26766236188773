import React from "react"

import {
    IconAddComponent,
    IconEditComponent
} from "../../../components/ui/icon/Icon.component"

const FileForm = ({
    onUpdateFile,
    file,
    field,
    extAccept = ".pdf, .xls, .xlsx"
}) => {
    return (
        <form
            onChange={({ target }) => {
                onUpdateFile(target.files)
            }}
        >
            <input
                accept={extAccept}
                style={{ display: "none" }}
                id={"icon-button-file" + field}
                type="file"
            />
            <label htmlFor={"icon-button-file" + field}>
                {file ? (
                    <IconEditComponent component={"span"} />
                ) : (
                    <IconAddComponent component={"span"} />
                )}
            </label>
        </form>
    )
}

export default FileForm
