import React, { PureComponent } from 'react';
import { TabsContext } from '../context';

export function withTabsContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <TabsContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props}/>
                    }
                </TabsContext.Consumer>
            )
        }
    }
}