import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getGoalKeeperWorkProposal,
    addGoalKeeperWorkProposal,
    updateGoalKeeperWorkProposal
} from "../../../redux/actions/actions";

import {
    selectTraining,
    selectGoalKeeperWorkProposals,
    selectGoalKeeperWorkProposal,
    selectSquad,
} from "../../../redux/selectors/selectors";

import { NewGoalKeeperExerciseConf } from '../../../config/training/NewGoalKeeperExercise.conf';
import { withAuthContext } from '../../../contexts/auth/Auth.context';
import GoalKeeperExerciseForm from '../../../components/goalkeeper/form';
import ProgressComponent from '../../../components/ui/progress/Progress.component';

const getInitialValue = (data, players) => {    
    const initialValues = data !== null ? {
        workout_goalkeeper: data.workout_goalkeeper ? data.workout_goalkeeper.map(item => { return { label: item.name, value: item.id } }) : null,
        theme: data.theme ? data.theme.map(item => { return { label: item.theme, value: item.id } }) : null,
        workout_modality: data.workout_modality ? data.workout_modality.map(item => { return { label: item.name, value: item.id } }) : null,
        kick_type: data.kick_type ? data.kick_type.map(item => { return { label: item.name, value: item.id } }) : null,
        ball_type: data.ball_type ? data.ball_type.map(item => { return { label: item.name, value: item.id } }) : null,        
        team_field_work: data.team_field_work ? data.team_field_work.map(item => { return { label: item.name, value: item.id } }) : null,
        gym_work: data.gym_work ? data.gym_work.map(item => { return { label: item.name, value: item.id } }) : null,
        limb: data.limb ? data.limb.id : null,
        equipment: data.equipment ? data.equipment.id : null,
        psychokinetic_light_work: data.psychokinetic_light_work ? data.psychokinetic_light_work.id : null,
        sand_work: data.sand_work ? data.sand_work.id : null,
        surname: data.players.map(player => { return { label: player.surname + ' ' + player.name, value: player.id } }),
        number: data.number,
        duration: data.duration ? data.duration : '',
        rest: data.rest ? data.rest : '',
        workout_coach_group: data.workout_coach_group ? data.workout_coach_group : null,
        workout_coach_description: data.workout_coach_description ? data.workout_coach_description : null,
        workout_team_description: data.workout_team_description ? data.workout_team_description : null,
        workout_specific_description: data.workout_specific_description ? data.workout_specific_description : null,
    } : {
            surname: players.map(player => { return { label: player.surname + ' ' + player.name, value: player.id } }),
        }
    return initialValues
}



const NewGoalKeeperExercisePage = ({
    onDismiss,
    authContext,
    training,
    goalKeeperWorkProposal,
    getGoalKeeperWorkProposal,
    addGoalKeeperWorkProposal,
    updateGoalKeeperWorkProposal,
    nextNumber,
    groups,
    exerciseId,
}) => {

    const {
        body,
        footer
    } = NewGoalKeeperExerciseConf;

    useEffect(() => {
        if (exerciseId) {
            getGoalKeeperWorkProposal({
                access_token: authContext.access_token,
                id: exerciseId
            })
        }
    }, [exerciseId]);

    const update = values => {
        updateGoalKeeperWorkProposal({
            access_token: authContext.access_token,
            values: values,
            training: training.data.id,
            id: goalKeeperWorkProposal.data.id,
            callback: () => {
                onDismiss()
            }
        })
    }

    const add = values => {
        addGoalKeeperWorkProposal({
            access_token: authContext.access_token,
            values: {
                ...values,
                number: nextNumber,
            },
            id: training.data.id,
            callback: () => {
                onDismiss()
            }
        })
    }

    const onSubmit = values => {
        goalKeeperWorkProposal.data !== null ? update(values) : add(values)
    };

    
    const getPlayers = (team) => {
        let newGroups = []
        Object.keys(team).forEach(key => {
            if (key !== 'team') {
                const item = team[key]
                const players = team[key].players.filter(player => player.role.id === 1)
                const count = players.length
                newGroups.push({
                    players,
                    count
                })
            }
        });

        newGroups.sort(function (a, b) {
            return b.count - a.count
        })

        return newGroups[0].players.sort((a, b) => (a.role.id > b.role.id) ? 1 : (b.role.id > a.role.id) ? -1 : 0)
    }

    const players = groups.data ? getPlayers(groups.data[0]) : []

    return (
        <>
            {goalKeeperWorkProposal.loading ? <ProgressComponent /> : null}
            <GoalKeeperExerciseForm
                body={body}
                footer={footer}
                onSubmit={onSubmit}
                initialValues={getInitialValue(goalKeeperWorkProposal.data, players)}
                disabled={false}
                players={players} />
        </>
    );
};


const mapStateToProps = state => ({
    training: selectTraining(state),
    nextNumber: selectGoalKeeperWorkProposals(state).data.length + 1,
    groups: selectSquad(state),
    goalKeeperWorkProposal: selectGoalKeeperWorkProposal(state)
});

const mapDispatchToProps = dispatch => ({
    getGoalKeeperWorkProposal: (body) => {
        dispatch(getGoalKeeperWorkProposal(body))
    },
    addGoalKeeperWorkProposal: (body) => {
        dispatch(addGoalKeeperWorkProposal(body));
    },
    updateGoalKeeperWorkProposal: (body) => {
        dispatch(updateGoalKeeperWorkProposal(body));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NewGoalKeeperExercisePage));