import { createAction } from 'redux-actions';

// Login
export const ACCOUNT_SIGNIN = 'ACCOUNT_SIGNIN';
export const accountSignIn = createAction(ACCOUNT_SIGNIN);
export const ACCOUNT_SIGNIN_SUCCESS = 'ACCOUNT_SIGNIN_SUCCESS';
export const accountSignInSuccess = createAction(ACCOUNT_SIGNIN_SUCCESS);
export const ACCOUNT_SIGNIN_FAILURE = 'ACCOUNT_SIGNIN_FAILURE';
export const accountSignInFailure = createAction(ACCOUNT_SIGNIN_FAILURE);

// Account
export const ACCOUNT_CREATE = 'ACCOUNT_CREATE';
export const accountCreate = createAction(ACCOUNT_CREATE);
export const CHECK_IF_ALREADY_LOGGED = 'CHECK_IF_ALREADY_LOGGED';
export const checkIfAlreadyLogged = createAction(CHECK_IF_ALREADY_LOGGED);

// Logout
export const ACCOUNT_SIGNOUT = 'ACCOUNT_SIGNOUT';
export const accountSignOut = createAction(ACCOUNT_SIGNOUT);

// Token
export const CHECK_TOKEN = 'CHECK_TOKEN';
export const checkToken = createAction(CHECK_TOKEN);