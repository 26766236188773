import { put, call, takeLatest } from 'redux-saga/effects';
import {
    
    getTrainingNote,
    getTrainingNotes,
    getTrainingNotesError,
    getTrainingNotesSuccess,

    addTrainingNote,
    updateTrainingNote,
    deleteTrainingNote,
    resetTrainingNote,

    actionTrainingNoteReset,
    actionTrainingNoteError,
    actionTrainingNoteSuccess,

} from '../../actions/actions';

import API from '../../../services/axios/api';

function* refreshList({ access_token, id }) {

    try {
        // Fetch List
        let list = yield call(API.coaching.getTrainingNotesFilter, { access_token, id });
        yield put(getTrainingNotesSuccess(list.data.data))

    } catch (e) {
        yield put(getTrainingNotesError(e))
    }
}

function* getTrainingNotesSaga(action) {

    const { access_token, id } = action.payload;
    yield call(refreshList, { access_token, id })
}

function* getTrainingNoteSaga(action) {

    const { access_token, id, callback = () => { } } = action.payload;

    try {
        // GET 
        let { data } = yield call(API.coaching.getTrainingNote, { access_token, id });        
        yield put(actionTrainingNoteSuccess(data.data))
    } catch (e) {
        yield put(actionTrainingNoteError(e))
    }    
    callback()
}


function* addTrainingNoteSaga(action) {
    
    const { access_token, values, id, callback = () => { } } = action.payload;

    try {
        // Add 
        yield call(API.coaching.addTrainingNote, { access_token: access_token, values: values, id: id });
        yield put(actionTrainingNoteSuccess())
    } catch (e) {
        yield put(actionTrainingNoteError(e))
    }

    yield call(refreshList, { access_token, id })
    callback()
}

function* updateTrainingNoteSaga(action) {

    const { access_token, values, training, id, callback = () => { } } = action.payload;    
    try {
        // Update 
        yield call(API.coaching.updateTrainingNote, { access_token, values, training, id });
        yield put(actionTrainingNoteSuccess())
    } catch (e) {
        yield put(actionTrainingNoteError(e))
    }

    yield call(refreshList, { access_token, id: training })
    callback()
}

function* deleteTrainingNoteSaga(action) {

    const { access_token, id, training } = action.payload;

    try {
        // Delete
        yield call(API.coaching.deleteTraningNote, { access_token, id });
        yield put(actionTrainingNoteSuccess())
    } catch (e) {
        yield put(actionTrainingNoteError(e))
    }

    yield call(refreshList, { access_token, id: training })
}

function* resetTrainingNoteSaga() {
    yield put(actionTrainingNoteReset())    
}


export function* notesWatcherSaga() {
    yield takeLatest(getTrainingNotes, getTrainingNotesSaga);
    yield takeLatest(getTrainingNote, getTrainingNoteSaga);
    yield takeLatest(addTrainingNote, addTrainingNoteSaga);
    yield takeLatest(updateTrainingNote, updateTrainingNoteSaga);
    yield takeLatest(deleteTrainingNote, deleteTrainingNoteSaga);
    yield takeLatest(resetTrainingNote, resetTrainingNoteSaga);
}