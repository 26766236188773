import moment from "moment"

export default {
    getWorkProposals: ({ id }) => {
        var params = new URLSearchParams()
        params.append("training", id)
        return {
            params: params
        }
    },

    getExerciseName: ({ categoryId }) => {
        var params = new URLSearchParams()
        if (categoryId) {
            params.append("category", categoryId)
        }
        return {
            params: params
        }
    },

    getExerciseImages: ({ exerciseId }) => {
        var params = new URLSearchParams()
        if (exerciseId) {
            params.append("exercise", exerciseId)
        }

        return {
            params: params
        }
    },

    upsertWorkProposal: ({ values, id, training }) => {
        var body = new FormData()

        // ID
        if (id) {
            body.set("id", id)
        }

        // Training
        if (training) {
            body.set("training", training)
        }

        // Category
        if (values.date) {
            body.set("date", moment(values.date).format("YYYY-MM-DD HH:mm"))
        }

        // Name
        if (values.name) {
            body.set("name", values.name)
        }

        // Theme
        if (values.theme) {
            body.set("theme", values.theme)
        }

        // Duration
        if (values.duration) {
            body.set("duration", values.duration)
        }

        // Rest
        if (values.rest) {
            body.set("rest", values.rest)
        }

        // Description
        if (values.description) {
            body.set("description", values.description)
        }

        // Number
        if (values.number) {
            body.set("number", values.number)
        }

        // Work Proposal Type
        if (values.work_proposal_type) {
            body.set("work_proposal_type", values.work_proposal_type)
        }

        // Players
        if (values.surname) {
            values.surname.map(player => body.append("players", player.value))
        }

        if (values.images) {
            values.images.map(image => body.append("images", image.value))
        }

        return body
    },

    orderWorkProposal: ({ number }) => {
        var body = new FormData()
        body.set("number", number)
        return body
    },

    getWorkProposalFilter: ({ values }) => {
        const startDate = moment(values.start_date).format("YYYY-MM-DD")
        const endDate = values.end_date
            ? moment(values.end_date).format("YYYY-MM-DD")
            : moment(values.start_date).format("YYYY-MM-DD")

        var params = new URLSearchParams()
        params.append("start", startDate + "T00:00:00Z")
        params.append("end", endDate + "T23:59:59Z")

        if (values.training_plan) {
            values.training_plan.forEach(element => {
                params.append("training_plan", element.value)
            })
        }

        if (values.training_group !== null) {
            params.append("training_group", values.training_group)
        }

        if (values.pitch_position) {
            values.pitch_position.forEach(element => {
                params.append("pitch_position", element.value)
            })
        }

        if (values.combined_pitch_position) {
            values.combined_pitch_position.forEach(element => {
                params.append("combined_pitch_position", element.value)
            })
        }

        if (values.training_role) {
            values.training_role.forEach(element => {
                params.append("training_role", element.value)
            })
        }

        if (values.objective) {
            values.objective.forEach(element => {
                params.append("objective", element.value)
            })
        }

        return {
            params: params
        }
    },


    getExerciseFilter: ({ values }) => {

        const startDate = moment(values.start).format("YYYY-MM-DD")
        const endDate = moment(values.end).format("YYYY-MM-DD")

        var params = new URLSearchParams()
        params.append("start", startDate + "T00:00:00Z")
        params.append("end", endDate + "T23:59:59Z")


        if (values.category) {
            values.category.forEach(element => {
                params.append("categories", element.value)
            })
        }

        if (values.name) {
            values.name.forEach(element => {
                params.append("names", element.value)
            })
        }

        if (values.theme) {
            values.theme.forEach(element => {
                params.append("themes", element.value)
            })
        }

        // Players
        if (values.surname) {
            values.surname.forEach(element => {
                params.append("players", element.value)
            })
        }

        return { params }
    },

    getExerciseGoalkeeperFilter: ({ values }) => {

        const startDate = moment(values.start).format("YYYY-MM-DD")
        const endDate = moment(values.end).format("YYYY-MM-DD")

        var params = new URLSearchParams()
        params.append("start", startDate + "T00:00:00Z")
        params.append("end", endDate + "T23:59:59Z")


        if (values.ball_types) {
            values.ball_types.forEach(element => {
                params.append("ball_types", element.value)
            })
        }

        if (values.equipments) {
            values.equipments.forEach(element => {
                params.append("equipments", element.value)
            })
        }

        if (values.gym_works) {
            values.gym_works.forEach(element => {
                params.append("gym_works", element.value)
            })
        }

        if (values.kick_types) {
            values.kick_types.forEach(element => {
                params.append("kick_types", element.value)
            })
        }

        if (values.limbs) {
            values.limbs.forEach(element => {
                params.append("limbs", element.value)
            })
        }

        if (values.psychokinetic_light_works) {
            values.psychokinetic_light_works.forEach(element => {
                params.append("psychokinetic_light_works", element.value)
            })
        }

        if (values.sand_works) {
            values.sand_works.forEach(element => {
                params.append("sand_works", element.value)
            })
        }


        if (values.team_field_works) {
            values.team_field_works.forEach(element => {
                params.append("team_field_works", element.value)
            })
        }

        if (values.themes) {
            values.themes.forEach(element => {
                params.append("themes", element.value)
            })
        }

        if (values.workout_goalkeepers) {
            values.workout_goalkeepers.forEach(element => {
                params.append("workout_goalkeepers", element.value)
            })
        }

        if (values.workout_modalities) {
            values.workout_modalities.forEach(element => {
                params.append("workout_modalities", element.value)
            })
        }

        if (values.surname) {
            values.surname.forEach(element => {
                params.append("players", element.value)
            })
        }
        
        return { params }

    }
}
