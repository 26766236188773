import React, { useState } from 'react';
import { withDashboardContext } from '../../hoc';
import { DrawerComponent } from '../../../ui/drawer/Drawer.component';
import DrawerMenu from '../drawer-menu'
import { MenuComponent } from '../../../ui/menu/Menu.component';
import shortid from "shortid";

const DashboardDrawer = ({ dashboardContext }) => {

    const {
        openDrawer,
        conf,
        actions: {
            handleDrawerClose,
        },
    } = dashboardContext;

    const [selected, setSelected] = useState(0)
    
    return (
        <DrawerComponent
            open={openDrawer}
            onCloseDrawer={handleDrawerClose}>
            <MenuComponent>
                {conf.list.map((item, index) =>
                    <DrawerMenu
                        key={shortid.generate()}
                        item={item}
                        index={index}
                        selected={selected === index}
                        onClick={(index) => setSelected(index)} />
                )}
            </MenuComponent>
        </DrawerComponent>
    )
};

export default withDashboardContext(DashboardDrawer);