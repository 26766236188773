import { useState, useEffect } from "react";

const useFetch = (request) => {    
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {

        const fetchData = async function () {
            
            try {
                
                setLoading(true);
                setError(null)

                const response = await request;                

                setData(response.data);
                
            } catch (error) {                
                setError(error);
            } finally {                
                setLoading(false);
            }
        };
        fetchData();

    }, [request]);

    return { loading, data , error };
};

export default useFetch;