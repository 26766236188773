import React from "react";
import { DateTimePicker, DatePicker } from "@material-ui/pickers";
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Calendar.component.style';

const calendar_type = {
    DatePicker,
    DateTimePicker
}


export const CalendarPickerTemplate = ({
    classes,
    title,
    calendarType,
    input: {
        name,
        onChange,
        value,
        ...restInput
    },
    meta,
    disabled = false,
    ...rest
}) => {

    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
    const TypeCalendar = calendar_type[calendarType];

    return (
        <FormControl
            className={classes.formControl}
            error={showError}>
            <TypeCalendar
                {...rest}
                disabled={disabled}
                name={name}
                error={showError}
                inputProps={restInput}
                onChange={onChange}
                value={value === '' ? null : value}
                ampm={false}
            />
        </FormControl>
    )
}

export const CalendarPickerComponent = withStyles(styles)(CalendarPickerTemplate)