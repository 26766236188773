import React from 'react';
import BoxComponent from '../../components/ui/box/Box.component';
import { appColor } from '../../config/colors/colors';


// Toolbar
const TrainingBoxTemplate = ({
    children,
    titleBox,
    actionBox,
}) => {
    return (
        <BoxComponent
            mr={1}
            bgcolor={appColor.white}
            boxShadow={1}>
            <BoxComponent
                width='100%'
                display="flex"
                flexDirection="row"
                bgcolor={appColor.other.main}>
                <BoxComponent
                    width="90%"
                    textAlign="left"
                    fontWeight="fontWeightBold"
                    p={2}
                    fontSize={18}
                    color={appColor.white}>
                    {titleBox}
                </BoxComponent>
                <BoxComponent width="10%">
                    {actionBox}
                </BoxComponent>                
            </BoxComponent>
            {children}
        </BoxComponent>
    )
}

export default TrainingBoxTemplate