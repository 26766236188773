import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./CellCheckbox.component.style"
import TableCell from "@material-ui/core/TableCell"
import Checkbox from "@material-ui/core/Checkbox"

const CellCheckboxTemplate = ({
    classes,
    indeterminate,
    checked,
    onChange
}) => {
    return (
        <TableCell padding="checkbox" className={classes.cell}>
            <Checkbox
                color="primary"
                indeterminate={indeterminate}
                checked={checked}
                onChange={event => onChange(event)}
                classes={{
                    root: classes.root,
                    checked: classes.checked
                }}
            />
        </TableCell>
    )
}

export const CellCheckboxComponent = withStyles(styles)(CellCheckboxTemplate)
