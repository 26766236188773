import React from 'react'

import FormProvider from '../../../../components/form/provider';
import Form from '../../../../components/form/ui';
import { GridComponent } from '../../../../components/ui/grid/Grid.component';
import { UploadFile } from "../../../../components/form/ui/uploadFile"
import { AsyncSelect } from "../../../../components/form/ui/select/async/single"

import { withAuthContext } from '../../../../contexts/auth/Auth.context';

import { GET } from "../../../../services/Service.context"
import API from "../../../../services/axios/api"


const ExerciseImageForm = ({
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
    authContext,
}) => {


    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.exercise.xs}>
                        <GET
                            request={API.coaching.getExerciseName({
                                access_token: authContext.access_token
                            })}>
                            <AsyncSelect
                                item={body.exercise}
                                node={body.exercise.node}
                            />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.description.xs}>
                        <GridComponent item={true} xs={body.description.xs}>
                            <UploadFile item={body.description} />
                        </GridComponent>
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(ExerciseImageForm);