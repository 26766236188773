import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import { withAuthContext } from "../../contexts/auth/Auth.context"
import { GridListComponent } from "../../components/ui/gridList/GridList.component"
import { ToolbarComponent } from "../../components/ui/page/toolbar/Toolbar.component"
import BoxComponent from "../../components/ui/box/Box.component"
import {
    IconExpandLessComponent,
    IconExpandMoreComponent
} from "../../components/ui/icon/Icon.component"
import Avatar from "../../components/ui/avatar/Avatar.component"

import { getCompetitions } from "../../redux/actions/actions"

import { selectCompetitons } from "../../redux/selectors/selectors"

import { appColor } from "../../config/colors/colors"

const Accordion = ({ logo, title, competitionTeams, selectTeam }) => {
    competitionTeams.map(team => {
        team.title = team.name
        team.img = team.logo
    })

    const [open, setOpen] = useState(false)
    return (
        <>
            <CompetitionHeader>
                <BoxComponent width="10%">
                    <Avatar src={logo} />
                </BoxComponent>
                <BoxComponent
                    width="85%"
                    textAlign="left"
                    p={2}
                    fontWeight="fontWeightBold"
                    fontSize={18}
                >
                    {title}
                </BoxComponent>
                <BoxComponent width="5%">
                    {open ? (
                        <IconExpandLessComponent
                            selected={true}
                            action={() => setOpen(!open)}
                        />
                    ) : (
                            <IconExpandMoreComponent
                                selected={true}
                                action={() => setOpen(!open)}
                            />
                        )}
                </BoxComponent>
            </CompetitionHeader>
            {open && competitionTeams.length > 0 ? (
                <BoxComponent mt={1}>
                    <GridListComponent
                        items={competitionTeams}
                        onSelectItem={selectTeam}
                    />
                </BoxComponent>
            ) : null}
        </>
    )
}

const CompetitionHeader = ({ children }) => {
    return (
        <BoxComponent
            width="100%"
            mt={2}
            bgcolor={appColor.white}
            boxShadow={1}
            display="flex"
            flexDirection="row"
            color={appColor.other.main}
            p={2}
        >
            {children}
        </BoxComponent>
    )
}

const CompetitionsPage = ({
    authContext,
    getCompetitions,
    competitions,
    history
}) => {
    useEffect(() => {
        getCompetitions({ access_token: authContext.access_token })
    }, [])

    const selectTeam = team => {
        history.push(`/team/${team.id}`)
    }

    return (
        <>
            <ToolbarComponent title="Competitions" />
            {competitions.length > 0 &&
                competitions.map((competition, index) => (
                    <Accordion
                        logo={competition.logo}
                        title={competition.name}
                        competitionTeams={competition.teams}
                        key={index + 1}
                        selectTeam={selectTeam}
                    />
                ))}
        </>
    )
}

const mapStateToProps = state => ({
    competitions: selectCompetitons(state)
})

const mapDispatchToProps = dispatch => ({
    getCompetitions: params => {
        dispatch(getCompetitions(params))
    }
})

export default withAuthContext(
    connect(mapStateToProps, mapDispatchToProps)(CompetitionsPage)
)
