import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';

const ExerciseMedium = ({
    children,
}) => {
    return (
        <BoxComponent
            textAlign="left"
            fontWeight="fontWeightBold"
            mb={2}
            fontStyle="normal"
            fontSize={16}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseMedium;