import React, { useState } from 'react'
import { connect } from 'react-redux';

import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _find from 'lodash/find'

import { colors, appColor } from '../../../../config/colors/colors';
import PieChartComponent from '../../../../components/graph/ui/pie';
import { withAuthContext } from '../../../../contexts/auth/Auth.context';

import FormProvider from '../../../../components/form/provider';
import Form from '../../../../components/form/ui';

import ProgressComponent from '../../../../components/ui/progress/Progress.component';
import BoxComponent from '../../../../components/ui/box/Box.component';

import { CalendarPicker } from '../../../../components/form/ui/calendar';
import { SelectMultiple } from '../../../../components/form/ui/select/multiple';
import { NewExerciseGoalkeeperDeskConf } from '../../../../config/training/NewExerciseDesk.conf';
import { GridComponent } from '../../../../components/ui/grid/Grid.component';

import API from '../../../../services/axios/api';
import { selectWorkProposalGoalkeeperSettings } from "../../../../redux/selectors/selectors";


const getArrayCount = (results, node, id) => {

    let count = 0
    results.forEach(result => {
        result[node].forEach(subitem => {
            if (subitem.id === id) {
                count = count + 1
            }
        });
    });

    return count
}

const getValueCount = (results, node, id) => {

    let count = 0
    results.forEach(result => {        
        if (result[node] && result[node].id === id) {
            count = count + 1
        }
    });

    return count
}

const getGraphArrayData = (results, source, node) => {
    let arrayToReturn = source.map(item => ({
        name: item.label,
        value: getArrayCount(results, node, item.value)
    }))
    return _orderBy(arrayToReturn, ['value'], ['desc'])
}


const getGraphValueData = (results, source, node) => {
    let arrayToReturn = source.map(item => ({
        name: item.label,
        value: getValueCount(results, node, item.value)
    }))
    return _orderBy(arrayToReturn, ['value'], ['desc'])
}



const getGraphPlayersData = (results, player) => {

    const groupBy = _groupBy(results, function (item) {
        return item.name.category.name
    })

    const array = Object.keys(groupBy).map((key) => {
        let item = { value: 0, name: key }
        groupBy[key].forEach(element => {
            if (_find(element.players, function (o) { return o.id === player })) {
                item.value = item.value + 1
            }
        });
        return item
    })

    return _orderBy(array, ['value'], ['desc'])
}








const GoalkeeperExerciseDeskPlayers = ({
    authContext,
    settings,
}) => {

    const [playersSelected, setPlayersSelected] = useState([])
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(null)

    const onSubmit = values => {
        setPlayersSelected(values.surname ? values.surname : [])
        setValues(values)

        const fetchData = async function () {
            try {

                setLoading(true)
                const response = await API.coaching.getExerciseGoalkeeperDesk({ access_token: authContext.access_token, values: values });
                setResponse(response.data)
            } catch (error) {

            } finally {
                setLoading(false)
            }
        };
        fetchData();
    };

    return (
        <>
            <BoxComponent boxShadow={1} width="100%">
                <FormProvider
                    onSubmit={onSubmit}
                    body={NewExerciseGoalkeeperDeskConf.body}
                    footer={NewExerciseGoalkeeperDeskConf.footer}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={NewExerciseGoalkeeperDeskConf.body.startDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={NewExerciseGoalkeeperDeskConf.body.endDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.themes ? settings.data.themes : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.theme}
                                    node={NewExerciseGoalkeeperDeskConf.body.theme.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.workout ? settings.data.workout : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.workoutGoalkeeper}
                                    node={NewExerciseGoalkeeperDeskConf.body.workoutGoalkeeper.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.workoutModality ? settings.data.workoutModality : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.workoutModality}
                                    node={NewExerciseGoalkeeperDeskConf.body.workoutModality.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.kickType ? settings.data.kickType : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.kickType}
                                    node={NewExerciseGoalkeeperDeskConf.body.kickType.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.ballType ? settings.data.ballType : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.ballType}
                                    node={NewExerciseGoalkeeperDeskConf.body.ballType.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.gym ? settings.data.gym : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.gymWork}
                                    node={NewExerciseGoalkeeperDeskConf.body.gymWork.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.limb ? settings.data.limb : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.limb}
                                    node={NewExerciseGoalkeeperDeskConf.body.limb.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.equipment ? settings.data.equipment : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.equipment}
                                    node={NewExerciseGoalkeeperDeskConf.body.equipment.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.psychokineticLight ? settings.data.psychokineticLight : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.psychokineticLightWork}
                                    node={NewExerciseGoalkeeperDeskConf.body.psychokineticLightWork.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.sand ? settings.data.sand : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.sandWork}
                                    node={NewExerciseGoalkeeperDeskConf.body.sandWork.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.teamField ? settings.data.teamField : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.teamFieldWork}
                                    node={NewExerciseGoalkeeperDeskConf.body.teamFieldWork.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.players ? settings.data.players : []}
                                    item={NewExerciseGoalkeeperDeskConf.body.players}
                                    node={NewExerciseGoalkeeperDeskConf.body.players.name}
                                    others={NewExerciseGoalkeeperDeskConf.body.players.others} />
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BoxComponent>
            {loading ? <ProgressComponent /> : null}
            {response ?
                <>
                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Tema Esercizio</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.themes ? values.themes : settings.data.themes.map(item => ({ label: item.theme, value: item.id })), 'theme')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} boxShadow={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Categoria Esercizio</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.workout_goalkeepers ? values.workout_goalkeepers : settings.data.workout.map(item => ({ label: item.name, value: item.id })), 'workout_goalkeeper')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>



                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Modalità Esercizio</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.workout_modalities ? values.workout_modalities : settings.data.workoutModality.map(item => ({ label: item.name, value: item.id })), 'workout_modality')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} boxShadow={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Tipologia tiro</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.kick_types ? values.kick_types : settings.data.kickType.map(item => ({ label: item.name, value: item.id })), 'kick_type')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>



                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Tipologia palla</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.ball_types ? values.ball_types : settings.data.ballType.map(item => ({ label: item.name, value: item.id })), 'ball_type')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} boxShadow={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Lavoro con la squadra</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.team_field_works ? values.team_field_works : settings.data.teamField.map(item => ({ label: item.name, value: item.id })), 'team_field_work')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>


                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Lavoro in palestra</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphArrayData(response.data.work_proposal, values.gym_works ? values.gym_works : settings.data.gym.map(item => ({ label: item.name, value: item.id })), 'gym_work')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} boxShadow={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Arti</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphValueData(response.data.work_proposal, values.limbs ? values.limbs : settings.data.limb.map(item => ({ label: item.name, value: item.id })), 'limb')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>


                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Macchinari</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphValueData(response.data.work_proposal, values.equipments ? values.equipments : settings.data.equipment.map(item => ({ label: item.name, value: item.id })), 'equipment')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} boxShadow={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Lavoro psicocinetico luci</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphValueData(response.data.work_proposal, values.psychokinetic_light_works ? values.psychokinetic_light_works : settings.data.psychokineticLight.map(item => ({ label: item.name, value: item.id })), 'psychokinetic_light_work')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>

                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Sabbia</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent
                                        data={getGraphValueData(response.data.work_proposal, values.sandWork ? values.sandWork : settings.data.sand.map(item => ({ label: item.name, value: item.id })), 'sand_work')}
                                        colors={colors}
                                        dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} />                         
                    </BoxComponent>



                    {/* <BoxComponent width="100%" display="flex" flexDirection="row" mt={2}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mt={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Categories</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent data={getGraphCategoriesData(response.data.work_proposal)} colors={colors} dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mt={1} ml={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Name</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent data={getGraphNameData(response.data.work_proposal)} colors={colors} dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>                    
                    {playersSelected.length > 0 ?
                        <BoxComponent width={1} bgcolor={appColor.white} boxShadow={1} mt={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Players/Categories</BoxComponent>
                            {response ?
                                <GridComponent container={true}>
                                    {playersSelected.map(player =>
                                        <GridComponent item={true} xs={12} key={player.value}>
                                            <BoxComponent borderBottom={1}>
                                                <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>{player.label}</BoxComponent>
                                                <BoxComponent style={{ height: 300 }}>
                                                    <PieChartComponent data={getGraphPlayersData(response.data.work_proposal, player.value)} colors={colors} dataKey='value' />
                                                </BoxComponent>
                                            </BoxComponent>
                                        </GridComponent>
                                    )}
                                </GridComponent> : null}
                        </BoxComponent> : null
                    } */}
                </> : null}
        </>
    )
}

const mapStateToProps = state => ({
    settings: selectWorkProposalGoalkeeperSettings(state)
});

export default withAuthContext(connect(mapStateToProps, null)(GoalkeeperExerciseDeskPlayers));