import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { styles } from './ModalTitle.component.style';
import DialogTitle from '@material-ui/core/DialogTitle';

const ModalTitle = ({
    children,
    classes,
}) => {

    return (
        <DialogTitle
            className={classes.root}>
            {children}
        </DialogTitle>
    )
}

const ModalTitleWithHoc = compose(
    withStyles(styles),
)(ModalTitle);

export default ModalTitleWithHoc;