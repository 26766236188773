export const NewTrainingDeskConf = {
    body: {
        startDate: {
            component: 'CalendarPicker',
            name: 'start_date',
            label: 'Start Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        endDate: {
            component: 'CalendarPicker',
            name: 'end_date',
            label: 'End Date',
            required: true,
            disabled: false,
            calendarType: 'DatePicker',
            validation: {
                message: "This field is Required"
            },
        },
        trainingPlan: {
            component: 'SelectMultiple',
            name: 'training_plan',
            label: 'Training Plan',
            required: false,
            disabled: false,            
            validation: {                
                message: "This field is Required"
            },
        },
        objective:{
            component: 'SelectMultiple',
            name: 'objective',
            label: 'Objective',
            required: false,
            disabled: false,
            validation: {                
                message: "This field is Required"
            },
        },
        trainingGroup:{
            component: 'Select',
            name: 'training_group',
            label: 'Group',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        },
        pitchPosition:{
            component: 'SelectMultiple',
            name: 'pitch_position',
            label: 'Pitch Position',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        },
        combinedPitchPosition:{
            component: 'SelectMultiple',
            name: 'combined_pitch_position',
            label: 'Combined Pitch Position',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        },
        trainingRole:{
            component: 'SelectMultiple',
            name: 'training_role',
            label: 'Role',
            required: false,
            disabled: false,
            validation: {
                message: "This field is Required"
            },
        }
    },
    footer: {
        columns: [
            {
                component: 'ButtonSubmit',
                title: 'Search',
                variant: 'contained',
                color: 'primary',
            },
        ]
    }
};