import React, { useEffect } from 'react'
import { connect } from 'react-redux';

import { withAuthContext } from '../../../../contexts/auth/Auth.context';
import ProgressComponent from '../../../../components/ui/progress/Progress.component';
import { ToolbarComponent } from '../../../../components/ui/page/toolbar/Toolbar.component';

import { selectWorkProposalSettings } from "../../../../redux/selectors/selectors";
import { getWorkProposalSettings } from '../../../../redux/actions/actions'

import BoxComponent from '../../../../components/ui/box/Box.component';
import ExerciseDeskPlayersContainer from './ExerciseDeskPlayers.container';

const ExerciseDesk = ({
    authContext,
    settings,
    getWorkProposalSettings
}) => {

    useEffect(() => {
        getWorkProposalSettings({
            access_token: authContext.access_token
        })
    }, [])

    return (
        <>
            <ToolbarComponent title="Exercise Desk" />
            {settings.loading ? <ProgressComponent /> : null}
            <BoxComponent mt={2} width={'100%'}>
                <ExerciseDeskPlayersContainer />
            </BoxComponent>            
        </>
    )
}

const mapStateToProps = state => ({
    settings: selectWorkProposalSettings(state)
});

const mapDispatchToProps = dispatch => ({
    getWorkProposalSettings: (params) => {
        dispatch(getWorkProposalSettings(params));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(ExerciseDesk));