import { takeLatest, put, call } from 'redux-saga/effects';
import {
    getNextMatch,
    getNextMatchError,
    getNextMatchSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';


function* getNextMatchSaga(action) {

    const { access_token, start_date, end_date } = action.payload
    let data = {};
    try {
        // Fetch Medical Notes List
        let medicalNotes = yield call(API.medicals.getMedicalNotesFilter, { access_token, values:{ start_date, end_date } });                
        data.medicalNotes = medicalNotes.data.data;

        // Fetch Training
        let trainings = yield call(API.coaching.getTrainingsFiltered, { access_token, values:{ start_date, end_date } });                
        data.trainings = trainings.data.data;

        yield put(getNextMatchSuccess(data))

    } catch (e) {
        yield put(getNextMatchError(e))
    }
}


export function* nextMatchWatcherSaga() {
    yield takeLatest(getNextMatch, getNextMatchSaga);
}