import React from 'react';
import BoxComponent from '../../../components/ui/box/Box.component';

const ExerciseSmall = ({
    children,
}) => {    
    return (
        <BoxComponent
            textAlign="left"
            fontWeight="fontWeightRegular"
            mb={2}
            fontStyle="italic"
            fontSize={15}>
            {children}
        </BoxComponent>
    )
}

export default ExerciseSmall;