import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { styles } from "./UploadFile.component.style"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"

import Button from "@material-ui/core/Button"

const UploadFileTemplate = ({
    classes,
    label,
    required = false,
    disabled = false,
    inputRef,
    name,
    input: { value, onChange, ...input },
    meta,
    ...rest
}) => {
    const showError =
        ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
        meta.touched

    const handleChange = ({ target }) => {
        onChange(target.files)
    }

    return (
        <FormControl
            className={classes.formControl}
            error={showError}
            variant="outlined"
            onChange={handleChange}
        >
            <Input
                id="uploadFile"
                className={classes.input}
                fullWidth
                disabled={disabled}
                inputRef={inputRef}
                {...input}
                {...rest}
            />
            <label htmlFor="uploadFile">
                <Button variant="contained" color="primary" component="span">
                    {label}
                </Button>
            </label>
        </FormControl>
    )
}

export const UploadFileComponent = withStyles(styles)(UploadFileTemplate)
