import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import { withRouter } from 'react-router';


import classes from './App.module.css';
import { checkToken } from './redux/actions/actions';
import { selectUser, selectAuth } from "./redux/selectors/selectors";
import Router from './routes/router';


const App = ({
	token,
	history,
	auth,
	user
}) => {

	const [cookies, setCookie] = useCookies(['access_token']);

	useEffect(() => {
		if (cookies && cookies.token) {
			token(cookies.token.access_token, cookies.token.refresh_token);
		} else {
			history.push('/login')
		}
	}, []);

	return (
		<div className={classes.App}>
			<Router />
		</div>
	);
};

const mapStateToProps = state => ({
	user: selectUser(state),
	auth: selectAuth(state),
});

const mapDispatchToProps = dispatch => ({
	token: (access_token, refresh_token) => {
		dispatch(checkToken({ access_token, refresh_token }))
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
