import {
    GET_GOALKEEPER_WORK_PROPOSAL,
    ADD_GOALKEEPER_WORK_PROPOSAL,
    UPDATE_GOALKEEPER_WORK_PROPOSAL,
    DELETE_GOALKEEPER_WORK_PROPOSAL,
    ACTION_GOALKEEPER_WORK_PROPOSAL_SUCCESS,
    ACTION_GOALKEEPER_WORK_PROPOSAL_ERROR,
    ACTION_GOALKEEPER_WORK_PROPOSAL_RESET
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: null
};

const goalKeeperWorkProposalReducer = (state = initialState, action) => {
    switch (action.type) {

        case ADD_GOALKEEPER_WORK_PROPOSAL:
        case GET_GOALKEEPER_WORK_PROPOSAL:
        case DELETE_GOALKEEPER_WORK_PROPOSAL:
        case UPDATE_GOALKEEPER_WORK_PROPOSAL:
            return {
                ...state,
                loading: true
            };
        case ACTION_GOALKEEPER_WORK_PROPOSAL_RESET:
            return {
                ...state,
                data: null
            };
        case ACTION_GOALKEEPER_WORK_PROPOSAL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload? action.payload : null
            };

        case ACTION_GOALKEEPER_WORK_PROPOSAL_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default goalKeeperWorkProposalReducer;