import React, { useEffect } from "react"
import { connect } from "react-redux"
import moment from "moment"
import {
    getTraining,
    updateTraining,
    deleteTraining
} from "../../redux/actions/actions"
import { selectTraining } from "../../redux/selectors/selectors"

import { withAuthContext } from "../../contexts/auth/Auth.context"

import { UpdateTrainingConf } from "../../config/training/UpdateTraining.conf"
import ProgressComponent from "../../components/ui/progress/Progress.component"
import SnackbarComponent from "../../components/ui/snackbar/Snackbar.component"
import TrainingForm from "../../components/training/form"
import TrainingBoxTemplate from "../../template/training/TrainingBox.template"

const getInitialValue = data => {
    const initialValues =
        data !== null
            ? {
                  date: data.date
                      ? moment(data.date, "YYYY-MM-DDTHH:mm:ss").format(
                            "YYYY-MM-DDTHH:mmZ"
                        )
                      : "",
                  duration: data.duration ? data.duration : "",
                  note: data.note ? data.note : "",
                  objective: data.objective
                      ? data.objective.map(item => {
                            return { value: item.id, label: item.objective }
                        })
                      : null,
                  pitch_position: data.pitch_position
                      ? data.pitch_position.map(item => {
                            return {
                                value: item.id,
                                label: item.pitch_position
                            }
                        })
                      : null,
                  combined_pitch_position: data.combined_pitch_position
                      ? data.combined_pitch_position.map(item => {
                            return {
                                value: item.id,
                                label: item.combined_pitch_position
                            }
                        })
                      : null,
                  training_role: data.training_role
                      ? data.training_role.map(item => {
                            return { value: item.id, label: item.training_role }
                        })
                      : null,
                  training_plan: data.training_plan
                      ? data.training_plan.id
                      : null,
                  training_group: data.training_group
                      ? data.training_group
                      : null
              }
            : {}

    return initialValues
}

const UpdateTrainingPage = ({
    authContext,
    trainingId,
    training,
    getTraining,
    updateTraining,
    deleteTraining,
    enabled
}) => {

    const {
        body,
        footer,
    } = UpdateTrainingConf;

    useEffect(() => {
        getTraining({
            access_token: authContext.access_token,
            id: trainingId
        })
    }, []);

    const onSubmit = values => {
        updateTraining({
            access_token: authContext.access_token,
            values: values,
            team: 1,
            id: training.data.id
        })
    }

    const onDelete = () => {
        deleteTraining({
            access_token: authContext.access_token,
            id: training.data.id
        })
    }

    const disabled = !enabled

    return (
        <TrainingBoxTemplate titleBox="Update Training">
            {training.error ? (
                <SnackbarComponent
                    variant="error"
                    message={training.error.message}
                />
            ) : null}
            {training.loading ? (
                <ProgressComponent />
            ) : (
                <TrainingForm
                    body={body}
                    footer={disabled ? null : footer}
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    initialValues={getInitialValue(training.data)}
                />
            )}
        </TrainingBoxTemplate>
    )
}

const mapStateToProps = state => ({
    training: selectTraining(state)
})

const mapDispatchToProps = dispatch => ({
    updateTraining: item => {
        dispatch(updateTraining(item))
    },
    deleteTraining: item => {
        dispatch(deleteTraining(item))
    },
    getTraining: item => {
        dispatch(getTraining(item))
    }
})

export default withAuthContext(
    connect(mapStateToProps, mapDispatchToProps)(UpdateTrainingPage)
)
