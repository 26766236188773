import React from 'react';
import { withListContext } from '../../hoc';

import BoxComponent from '../../../ui/box/Box.component';
import { IconDeleteComponent, IconAddComponent, IconEditComponent } from '../../../ui/icon/Icon.component';
import { appColor } from '../../../../config/colors/colors';

const ListHeader = ({ listContext }) => {

    const {
        toolbar,
        selected,
        actions: {
            onDeleteAction,
            onAddAction,
            onEditAction,
        }
    } = listContext;

    return (
        <BoxComponent
            bgcolor={appColor.white}
            boxShadow={1}>
            <BoxComponent
                width='100%'
                display="flex"
                bgcolor={appColor.other.main}>
                <BoxComponent
                    width="100%"
                    textAlign="left"
                    fontWeight="fontWeightBold"
                    p={2}
                    fontSize={18}
                    color={appColor.white}>
                    {toolbar}
                </BoxComponent>
                {onDeleteAction || onAddAction ?
                    selected.length > 0 ?
                        <>
                            {selected.length > 1 ||
                                <BoxComponent flexShrink={1}>
                                    <IconEditComponent action={onEditAction} color="secondary" />
                                </BoxComponent>}
                            {onDeleteAction &&
                                <BoxComponent flexShrink={2}>
                                    <IconDeleteComponent action={onDeleteAction} color="secondary" />
                                </BoxComponent>
                            }

                        </> :
                        <BoxComponent flexShrink={1}>
                            <IconAddComponent action={onAddAction} />
                        </BoxComponent> : null
                }
            </BoxComponent>
        </BoxComponent>
    )
};

export default withListContext(ListHeader);