import React, { PureComponent } from 'react';
import { DashboardContext } from '../context';

export function withDashboardContext(WrappedComponent) {

    return class extends PureComponent {

        render() {
            return (
                <DashboardContext.Consumer>
                    {(contexts) =>
                        <WrappedComponent
                            {...contexts}
                            {...this.props}/>
                    }
                </DashboardContext.Consumer>
            )
        }
    }
}