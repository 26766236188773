import React from 'react'

import FormProvider from '../../../../components/form/provider';
import Form from '../../../../components/form/ui';
import { GridComponent } from '../../../../components/ui/grid/Grid.component';
import { AsyncSelect } from "../../../../components/form/ui/select/async/single"
import { EditArea } from "../../../../components/form/ui/editArea"

import { withAuthContext } from '../../../../contexts/auth/Auth.context';

import { GET } from "../../../../services/Service.context"
import API from "../../../../services/axios/api"


const ExerciseNameForm = ({
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
    authContext,
}) => {


    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.category.xs}>
                        <GET
                            request={API.coaching.getExerciseCategories(
                                authContext.access_token
                            )}>
                            <AsyncSelect
                                item={body.category}
                                node={body.category.node}
                            />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.description.xs}>
                        <EditArea item={body.description} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(ExerciseNameForm);