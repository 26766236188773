import React from 'react';
import useFetch from "../hooks/useFetch";

// Context
export const ServiceContext = React.createContext();

// Provider
export const ServiceProvider = ({
    children,
    request,    
}) => {

    const { loading, data , error } = useFetch(request);
       
    return (
        <ServiceContext.Provider
            value={{
                data: data,
                loading: loading,
                error: error
            }}>
            {children}
        </ServiceContext.Provider>
    )
}

// Consumer
export const ServiceConsumer = ServiceContext.Consumer;


//  ServiceComponent
export const GET = ({
    request,
    children,
}) => {

    return (
        <ServiceProvider
            request={request}>
            <ServiceConsumer>
                {({ data, loading, error }) => (
                    React.Children.map(children, child =>
                        React.cloneElement(child, {
                            data: data,
                            loading: loading,
                            error: error,
                        })
                    )
                )}
            </ServiceConsumer>
        </ServiceProvider>
    )
}
