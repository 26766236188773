import { createSelector } from "reselect"

// Club
const getCompetitions = state => state.club.competitions.data
export const selectCompetitons = createSelector(
    [getCompetitions],
    competitions => competitions
)

const getClubTeams = state => state.club.clubTeams.data
export const selectClubTeams = createSelector(
    [getClubTeams],
    clubTeams => clubTeams
)

const getClubTeam = state => state.club.clubTeam.data
export const selectClubTeam = createSelector(
    [getClubTeam],
    clubTeam => clubTeam
)

const getClubCategories = state => state.club.clubCategories.data
export const selectClubCategories = createSelector(
    [getClubCategories],
    clubCategories => clubCategories
)

const getClubLinks = state => state.club.clubLinks.data
export const selectClubLinks = createSelector(
    [getClubLinks],
    clubLinks => clubLinks
)


// GoalKeeper
const getGoalKeeperWorkProposals = state =>
    state.goalKeeperWork.goalKeeperWorkProposals
export const selectGoalKeeperWorkProposals = createSelector(
    [getGoalKeeperWorkProposals],
    goalKeeperWorkProposals => goalKeeperWorkProposals
)

const getGoalKeeperWorkProposal = state => state.goalKeeperWork.goalKeeperWorkProposal
export const selectGoalKeeperWorkProposal = createSelector(
    [getGoalKeeperWorkProposal],
    goalKeeperWorkProposal => goalKeeperWorkProposal
)


// Medical
const getMedicalNotes = state => state.medical.medicalNotes
export const selectMedicalNotes = createSelector(
    [getMedicalNotes],
    notes => notes
)

const getMedicalNote = state => state.medical.medicalNote
export const selectMedicalNote = createSelector([getMedicalNote], note => note)


// Notes
const getNotes = state => state.notes.notes
export const selectNotes = createSelector([getNotes], notes => notes)

const getNote = state => state.notes.note
export const selectNote = createSelector([getNote], note => note)


// Notifications
const getNotifications = state => state.notification.notifications
export const selectNotifications = createSelector(
    [getNotifications],
    notifications => notifications
)


// Report
const getDailyReport = state => state.report.dailyReport
export const selectDailyReport = createSelector(
    [getDailyReport],
    dailyReport => dailyReport
)

const getInjuryDesk = state => state.report.injuryDesk
export const selectInjuryDesk = createSelector(
    [getInjuryDesk],
    injuryDesk => injuryDesk
)

const getNextMatch = state => state.report.nextMatch
export const selectNextMatch = createSelector(
    [getNextMatch],
    nextMatch => nextMatch
)

const getStatistics = state => state.report.statistics
export const selectStatistics = createSelector(
    [getStatistics],
    statistics => statistics
)


// Settings
const getInjuryTypes = state => state.settings.injuryTypes
export const selectInjuryTypes = createSelector(
    [getInjuryTypes],
    injuryTypes => injuryTypes
)

const getWorkPrograms = state => state.settings.workPrograms
export const selectWorkPrograms = createSelector(
    [getWorkPrograms],
    workPrograms => workPrograms
)

const getTeamFieldWorks = state => state.settings.teamFieldWorks
export const selectTeamFieldWorks = createSelector(
    [getTeamFieldWorks],
    teamFieldWorks => teamFieldWorks
)

const getExerciseCategories = state => state.settings.exerciseCategories
export const selectExerciseCategories = createSelector(
    [getExerciseCategories],
    exerciseCategories => exerciseCategories
)

const getExerciseNames = state => state.settings.exerciseNames
export const selectExerciseNames = createSelector(
    [getExerciseNames],
    exerciseNames => exerciseNames
)

const getExerciseImages = state => state.settings.exerciseImages
export const selectExerciseImages = createSelector(
    [getExerciseImages],
    exerciseImages => exerciseImages
)




// Team
const getPlayers = state => state.team.players
export const selectPlayers = createSelector(
    [getPlayers],
    players => players
)

const getSquad = state => state.team.squad
export const selectSquad = createSelector([getSquad], squad => squad)

const getTeams = state => state.team.teams.data
export const selectTeams = createSelector([getTeams], teams => teams)




// Training
const getTraining = state => state.training.training
export const selectTraining = createSelector(
    [getTraining],
    training => training
)

const getTrainingDesk = state => state.training.trainingDesk
export const selectTrainingDesk = createSelector(
    [getTrainingDesk],
    trainingDesk => trainingDesk
)

const getTrainings = state => state.training.trainings
export const selectTrainings = createSelector(
    [getTrainings],
    trainings => trainings
)



// User
const getUser = state => state.user.user
export const selectUser = createSelector([getUser], user => user)

const getAuth = state => state.user.auth
export const selectAuth = createSelector([getAuth], auth => auth)




// Work Proposals
const getWorkProposals = state => state.workProposal.workProposals
export const selectWorkProposals = createSelector(
    [getWorkProposals],
    workProposals => workProposals
)

const getWorkProposal = state => state.workProposal.workProposal
export const selectWorkProposal = createSelector(
    [getWorkProposal],
    workProposal => workProposal
)

const getWorkProposalSettings = state => state.workProposal.workProposalSettings
export const selectWorkProposalSettings = createSelector(
    [getWorkProposalSettings],
    workProposal => workProposal
)


const getWorkProposalGoalkeeperSettings = state => state.workProposal.workProposalGoalkeeperSettings
export const selectWorkProposalGoalkeeperSettings = createSelector(
    [getWorkProposalGoalkeeperSettings],
    workProposal => workProposal
)