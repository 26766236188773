import {
    GET_GOALKEEPER_WORK_PROPOSALS,
    GET_GOALKEEPER_WORK_PROPOSALS_ERROR,
    GET_GOALKEEPER_WORK_PROPOSALS_SUCCESS
} from '../../actions/actions'


const initialState = {
    loading: false,
    error: null,
    data: []
};

const goalKeeperWorkProposalReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_GOALKEEPER_WORK_PROPOSALS:
            return {
                ...state,
                loading: true
            };

        case GET_GOALKEEPER_WORK_PROPOSALS_SUCCESS:
            const { data } = action.payload;
            return {
                ...state,
                loading: false,
                error: null,
                data: data
            };

        case GET_GOALKEEPER_WORK_PROPOSALS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default goalKeeperWorkProposalReducer;