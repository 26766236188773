import React, { useState } from 'react'
import { connect } from 'react-redux';

import _groupBy from 'lodash/groupBy'
import _orderBy from 'lodash/orderBy'
import _find from 'lodash/find'

import { colors, appColor } from '../../../../config/colors/colors';
import PieChartComponent from '../../../../components/graph/ui/pie';
import { withAuthContext } from '../../../../contexts/auth/Auth.context';

import FormProvider from '../../../../components/form/provider';
import Form from '../../../../components/form/ui';

import ProgressComponent from '../../../../components/ui/progress/Progress.component';
import BoxComponent from '../../../../components/ui/box/Box.component';

import { CalendarPicker } from '../../../../components/form/ui/calendar';
import { SelectMultiple } from '../../../../components/form/ui/select/multiple';
import { NewExerciseDeskConf } from '../../../../config/training/NewExerciseDesk.conf';
import { GridComponent } from '../../../../components/ui/grid/Grid.component';

import API from '../../../../services/axios/api';
import { selectWorkProposalSettings } from "../../../../redux/selectors/selectors";


const getArrayToReturn = (groupBy) => {

    const array = Object.keys(groupBy).map((key) => ({
        name: key,
        value: groupBy[key].length
    }))
    return _orderBy(array, ['value'], ['desc'])
}


const getGraphCategoriesData = (results) => {

    const groupBy = _groupBy(results, function (item) {
        return item.name.category.name
    })

    return getArrayToReturn(groupBy)
}

const getGraphNameData = (results) => {

    const groupBy = _groupBy(results, function (item) {
        return item.name.name
    })
    return getArrayToReturn(groupBy)
}

const getGraphThemeData = (results) => {
    const groupBy = _groupBy(results, function (item) {
        return item.theme.theme
    })
    return getArrayToReturn(groupBy)
}

const getGraphPlayersData = (results, player) => {

    const groupBy = _groupBy(results, function (item) {
        return item.name.category.name
    })

    const array = Object.keys(groupBy).map((key) => {
        let item = { value: 0, name: key }
        groupBy[key].forEach(element => {
            if (_find(element.players, function (o) { return o.id === player })) {
                item.value = item.value + 1
            }
        });
        return item
    })

    return _orderBy(array, ['value'], ['desc'])
}


const ExerciseDeskPlayers = ({
    authContext,
    settings,
}) => {

    const [playersSelected, setPlayersSelected] = useState([])
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)

    const onSubmit = values => {
        setPlayersSelected(values.surname ? values.surname : [])

        const fetchData = async function () {
            try {

                setLoading(true)
                const response = await API.coaching.getExerciseDesk({ access_token: authContext.access_token, values: values });
                setResponse(response.data)
            } catch (error) {

            } finally {
                setLoading(false)
            }
        };
        fetchData();
    };



    return (
        <>
            <BoxComponent boxShadow={1} width="100%">
                <FormProvider
                    onSubmit={onSubmit}
                    body={NewExerciseDeskConf.body}
                    footer={NewExerciseDeskConf.footer}>
                    <Form>
                        <GridComponent container={true}>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={NewExerciseDeskConf.body.startDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={3} style={{ padding: '8px' }}>
                                <CalendarPicker item={NewExerciseDeskConf.body.endDate} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.categories ? settings.data.categories : []}
                                    item={NewExerciseDeskConf.body.category}
                                    node={NewExerciseDeskConf.body.category.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.themes ? settings.data.themes : []}
                                    item={NewExerciseDeskConf.body.theme}
                                    node={NewExerciseDeskConf.body.theme.node} />
                            </GridComponent>
                            <GridComponent item={true} xs={12} style={{ padding: '8px' }}>
                                <SelectMultiple
                                    options={settings.data.players ? settings.data.players : []}
                                    item={NewExerciseDeskConf.body.players}
                                    node={NewExerciseDeskConf.body.players.name}
                                    others={NewExerciseDeskConf.body.players.others} />
                            </GridComponent>
                        </GridComponent>
                    </Form>
                </FormProvider>
            </BoxComponent>
            {loading ? <ProgressComponent /> : null}
            {response ?
                <>
                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={2}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mt={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Categories</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent data={getGraphCategoriesData(response.data.work_proposal)} colors={colors} dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mt={1} ml={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Name</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent data={getGraphNameData(response.data.work_proposal)} colors={colors} dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                    </BoxComponent>
                    <BoxComponent width="100%" display="flex" flexDirection="row" mt={1}>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} boxShadow={1} mr={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Theme</BoxComponent>
                            {response ?
                                <BoxComponent style={{ height: 400 }}>
                                    <PieChartComponent data={getGraphThemeData(response.data.work_proposal)} colors={colors} dataKey='value' />
                                </BoxComponent> : null}
                        </BoxComponent>
                        <BoxComponent width={1 / 2} bgcolor={appColor.white} ml={1} />
                    </BoxComponent>
                    {playersSelected.length > 0 ?
                        <BoxComponent width={1} bgcolor={appColor.white} boxShadow={1} mt={1}>
                            <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>Players/Categories</BoxComponent>
                            {response ?
                                <GridComponent container={true}>
                                    {playersSelected.map(player =>
                                        <GridComponent item={true} xs={12} key={player.value}>
                                            <BoxComponent borderBottom={1}>
                                                <BoxComponent fontWeight="fontWeightNormal" fontSize="h6.fontSize" textAlign="left" color={appColor.darkBlue} p={2}>{player.label}</BoxComponent>
                                                <BoxComponent style={{ height: 300 }}>
                                                    <PieChartComponent data={getGraphPlayersData(response.data.work_proposal, player.value)} colors={colors} dataKey='value' />
                                                </BoxComponent>
                                            </BoxComponent>
                                        </GridComponent>
                                    )}
                                </GridComponent> : null}
                        </BoxComponent> : null
                    }
                </> : null}
        </>
    )
}

const mapStateToProps = state => ({
    settings: selectWorkProposalSettings(state)
});

export default withAuthContext(connect(mapStateToProps, null)(ExerciseDeskPlayers));