import React from 'react'
import { Field } from 'react-final-form';
import { SelectMultipleComponent } from '../../../../ui/form/select/SelectMultiple.component';
import { Error } from '../../error';

export const SelectMultiple = ({
    item,
    errors,
    options = [],
    disabled,
    node,
    others
}) => {

    const values = options ? options.map((item, index) => {
        return {
            label: others ? item[node] + ' ' + item[others] : item[node],
            value: item.id,
        }
    }) : []

    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                error={errors ? errors[item.name] : null}
                component={SelectMultipleComponent}
                values={values} />
            <Error name={item.name} />
        </>
    )
}
