import React from 'react';
import { withTabsContext } from '../hoc';
import TabsComponent from '../../ui/tabs/Tabs.component';

const Tabs = ({
    children,
    tabsContext: {
        index,
        tabs,
        actions: {
            onChange
        }
    }
}) => {

    return (
        <TabsComponent
            index={index}
            tabs={tabs}
            onChange={onChange}>
            {children}
        </TabsComponent>
    )
}


export default withTabsContext(Tabs);