import React from "react"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
//import { ThemeProvider } from '@material-ui/styles';

import { appColor } from "./config/colors/colors"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: appColor.primary.main
        },
        secondary: {
            main: appColor.secondary.main
        },
        other: {
            main: appColor.other.main
        }
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: appColor.grey,
                "&$focused": {
                    color: appColor.grey
                }
            }
        },
        MuiInputBase: {
            root: {
                color: appColor.primary.main
            }
        },
        MuiCardHeader: {
            title: {
                textAlign: "left"
            },
            subheader: {
                textAlign: "left"
            }
        },
        MuiFormHelperText: {
            root: {
                color: appColor.red
            }
        },
        MuiCard: {
            root: {
                boxShadow: "none",
                borderRadius: 0
            }
        },
        MuiGridListTile: {
            imgFullWidth: {
                width: "none"
            }
        },
        MuiGridListTileBar: {
            root: {
                background: appColor.primary.main
            }
        },
        MuiListItemText: {
            primary: {
                fontWeight: "bold",
                fontSize: "smaller"
            }
        },
        MuiAvatar: {
            img: {
                objectFit: "contain"
            }
        }
    }
})

const StyleProvider = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default StyleProvider
