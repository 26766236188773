import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import moment from 'moment'

import {
	addMedicalNote,
	getMedicalNotes,
	deleteMedicalNote,
	resetMedicalNote
} from '../../redux/actions/actions';

import {
	selectMedicalNotes
} from '../../redux/selectors/selectors';

import { withAuthContext } from '../../contexts/auth/Auth.context';

// import usePermissions from '../../hooks/usePermissions';
import useModal from '../../hooks/useModal';

import NewMedicalNote from './NewMedicalNote.container';


import BigCalendar from '../../components/calendar/ui';
import ProgressComponent from '../../components/ui/progress/Progress.component';
import { ToolbarComponent } from '../../components/ui/page/toolbar/Toolbar.component';
import FormModal from '../../components/form/ui/modal';
import CalendarTemplate from '../../template/calendar/Calendar.template';



const MedicalAreaPage = ({
	authContext,
	getMedicalNotes,
	medicalNotes,
	resetMedicalNote,
}) => {

	const [initialValues, setInitialValues] = useState({});
	const [multiSlot, setMultiSlot] = useState(false);
	const [currentNote, setCurrentNote] = useState(null)
	const [initialDate, setInitialDate] = useState({
		start_date: moment(new Date()).startOf('month').subtract(7, 'days'),
		end_date: moment(new Date()).endOf('month').add(7, 'days')
	});
	const { openModal, closeModal, isModalShowing } = useModal();
	// const { isAuthEnabled } = usePermissions('MedicalArea', authContext.type)


	useEffect(() => {
		getMedicalNotes({
			access_token: authContext.access_token,
			start_date: initialDate.start_date,
			end_date: initialDate.end_date,
		})
	}, [initialDate])

	const onSelectSlot = event => {
		setMultiSlot(event.action === "select")
		setCurrentNote(null)
		openModal();
	};

	const onSelectEvent = event => {
		setMultiSlot(event.action === "select")
		setCurrentNote(event.resource.id)
		openModal();
	}

	const onRangeChange = range => {
		setInitialDate({
			start_date: range.start,
			end_date: range.end
		})
	}

	const onEventPropGetter = event => {		
		return {
			style: {
				backgroundColor: `#${event.resource.availability.color}`
			}
		}
	}

	const events = medicalNotes.data ? medicalNotes.data.map(item => {
		return ({
			title: `${item.player.name} ${item.player.surname}`,
			subtitle: ``,
			start: moment(item.start, 'YYYY-MM-DD - HH:mm:ss').format('YYYY/MM/DD'),
			end: moment(item.end, 'YYYY-MM-DD - HH:mm:ss').format('YYYY/MM/DD'),
			resource: item
		})
	}) : []

	return (
		<>
			{medicalNotes.loading ? <ProgressComponent /> : null}
			<CalendarTemplate
				toolbar={
					<ToolbarComponent title='Medical Area' />
				}
				calendarBox={
					<BigCalendar
						onSelectSlot={(slot) => {
							onSelectSlot(slot);
							setInitialValues({ start: slot.start, end: slot.end });
						}}
						onSelectEvent={(event) => {
							onSelectEvent(event)
						}}
						onRangeChange={(range) =>
							onRangeChange(range)
						}
						onEventPropGetter={(event) =>
							onEventPropGetter(event)
						}
						events={events} />
				}
			/>
			<FormModal
				open={isModalShowing}
				onCancel={() => {
					setCurrentNote(null)
					resetMedicalNote()
					closeModal()
				}}
				onSave={() => {
					setCurrentNote(null)
					resetMedicalNote()
					closeModal()
				}}
				title={currentNote? 'Update medical report' : 'New medical report'}>
				<NewMedicalNote
					noteId={currentNote}
					multiSlot={multiSlot}
					initialValues={initialValues}
					range={initialDate}
					onDismiss={() => {
						setCurrentNote(null)
						resetMedicalNote()
						closeModal()
					}} />
			</FormModal>
		</>
	);

};

const mapStateToProps = state => ({
	medicalNotes: selectMedicalNotes(state),
});

const mapDispatchToProps = dispatch => ({
	resetMedicalNote: () => {
		dispatch(resetMedicalNote())
	},
	addMedicalNote: (values) => {
		dispatch(addMedicalNote(values))
	},
	getMedicalNotes: (item) => {
		dispatch(getMedicalNotes(item))
	},
	deleteMedicalNote: (item) => {
		dispatch(deleteMedicalNote(item))
	}
});

export default withAuthContext(withRouter(connect(mapStateToProps, mapDispatchToProps)(MedicalAreaPage)));