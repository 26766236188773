import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './MenuItem.component.style';
import ListItem from '@material-ui/core/ListItem';

const MenuItemTemplate = ({
    children,
    action= () => {},
    classes,
    nested,
}) => {

    return (
        <ListItem 
            button
            onClick={action}            
            className={nested ? classes.nested: ''}>
            {children}
        </ListItem>
    )
}

export const MenuItemComponent = withStyles(styles)(MenuItemTemplate)