import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getTrainingNote,
    addTrainingNote,
    updateTrainingNote
} from "../../../redux/actions/actions";

import {
    selectTraining,
    selectNote,
} from "../../../redux/selectors/selectors";

import { NewNoteConf } from '../../../config/training/NewNote.conf';
import { withAuthContext } from '../../../contexts/auth/Auth.context';
import NoteForm from '../../../components/note/form';
import ProgressComponent from '../../../components/ui/progress/Progress.component';

const getInitialValue = (data) => {
    const initialValues = data !== null ? {
        description: data.description,
        training: data.training,
        surname: data.player.id
    } : {}
    return initialValues
}


const NewNotePage = ({
    onDismiss,
    authContext,
    training,
    noteId,
    note,
    addTrainingNote,
    updateTrainingNote,
    getTrainingNote,
}) => {

    const {
        body,
        footer
    } = NewNoteConf;

    useEffect(() => {
        if (noteId) {
            getTrainingNote({
                access_token: authContext.access_token,
                id: noteId
            })
        }
    }, [noteId]);

    const update = values => {
        updateTrainingNote({
            access_token: authContext.access_token,
            values: {
                ...values,
                isReady: true
            },
            training: training.data.id,
            id: note.data.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addTrainingNote({
            access_token: authContext.access_token,
            values: values,
            id: training.data.id,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        note.data !== null ? update(values) : add(values)
    };

    return (
        <>
            {note.loading ? <ProgressComponent /> : null}
            <NoteForm
                body={body}
                footer={footer}
                onSubmit={onSubmit}
                initialValues={getInitialValue(note.data)}
                disabled={false} />
        </>
    );
};


const mapStateToProps = state => ({
    training: selectTraining(state),
    note: selectNote(state)
});

const mapDispatchToProps = dispatch => ({
    getTrainingNote: (body) => {
        dispatch(getTrainingNote(body))
    },
    addTrainingNote: (body) => {
        dispatch(addTrainingNote(body));
    },
    updateTrainingNote: (body) => {
        dispatch(updateTrainingNote(body));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NewNotePage));