import React from 'react'

import FormProvider from '../../../../components/form/provider';
import Form from '../../../../components/form/ui';
import { EditArea } from '../../../../components/form/ui/editArea';
import { GridComponent } from '../../../../components/ui/grid/Grid.component';

import { withAuthContext } from '../../../../contexts/auth/Auth.context';


const ExerciseCategoryForm = ({
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
}) => {


    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.description.xs}>
                        <EditArea item={body.description} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(ExerciseCategoryForm);