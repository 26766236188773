import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Button.component.style';
import Button from '@material-ui/core/Button';

const ButtonTemplate = ({
    classes,
    color = 'primary',
    title = '',
    action = () => { },
    variant = 'contained',
    disabled = false,
}) => {

    return (
        <Button
            className={classes.button}
            color={color}
            onClick={action}
            variant={variant}
            disabled={disabled}>
            {title}
        </Button>
    )
}

export const ButtonComponent = withStyles(styles)(ButtonTemplate)