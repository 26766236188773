export default {

    login: ({ username, password }) => {
        var body = new FormData();
        body.set("username", username);
        body.set("password", password);
        body.set("grant_type", "password");
        body.set("client_id", process.env.REACT_APP_API_CLIENT_ID);
        return body
    },
}