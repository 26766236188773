import React from 'react';
import { TabsContext } from '../context';

const TabsProvider = ({
    children,
    index,
    onChange,
    tabs
}) => {

    return (
        <TabsContext.Provider
            value={{
                tabsContext: {
                    index: index,
                    tabs: tabs,                    
                    actions: {
                        onChange: onChange
                    }
                }
            }}>
            {children}
        </TabsContext.Provider>
    )
}

export default TabsProvider;