import React from 'react';
import { DashboardContext } from '../context';

const DashboardProvider = ({
    children,
    openDrawer,
    openNotification,
    title,
    badge,
    conf,
    handleDrawerClose,
    handleDrawerOpen,
    handleGoToLink,
    handleOpenNotification,
    handleLogout,
}) => {

    return (
        <DashboardContext.Provider
            value={{
                dashboardContext: {                    
                    openDrawer,
                    openNotification,
                    title,
                    conf,
                    badge,
                    actions: {
                        handleDrawerClose,
                        handleDrawerOpen,
                        handleGoToLink,
                        handleOpenNotification,
                        handleLogout,
                    }
                }
            }}>
            {children}
        </DashboardContext.Provider>
    )
}
export default DashboardProvider;
