import {
    GET_CLUB_CATEGORIES,
    GET_CLUB_CATEGORIES_SUCCESS,
    GET_CLUB_CATEGORIES_ERROR
} from "../../actions/actions"

const initialState = {
    loading: false,
    error: null,
    data: [],
    filter: {}
}

const clubCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLUB_CATEGORIES:
            return {
                ...state,
                loading: true
            }

        case GET_CLUB_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data
            }

        case GET_CLUB_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export default clubCategoriesReducer
