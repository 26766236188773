// import _groupBy from 'lodash/groupBy'
import {
    GET_MEDICAL_NOTES,
    GET_MEDICAL_NOTES_SUCCESS,
    GET_MEDICAL_NOTES_ERROR
} from '../../actions/actions'


const initialState = {
    loading: false,
    error: null,
    data: []
};

const medicalNotesReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_MEDICAL_NOTES:
            return {
                ...state,
                loading: true
            };

        case GET_MEDICAL_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_MEDICAL_NOTES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default medicalNotesReducer;