import { put, call, takeLatest } from 'redux-saga/effects';

import {
    getSquad,
    getSquadError,
    getSquadSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';

function* getSquadSaga(action) {

    const { access_token, teams, date } = action.payload

    try {
        // Fetch List
        let list = yield call(API.player.getSquads, { access_token, teams, date });
        yield put(getSquadSuccess(list.data.data))

    } catch (e) {
        yield put(getSquadError(e))
    }
}

export function* squadWatcherSaga() {
    yield takeLatest(getSquad, getSquadSaga);
}
