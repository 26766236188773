import React, { useState } from 'react'
// import _omit from 'lodash/omit';

import { endpoints } from '../../../config/api/Endpoints.conf';

import FormProvider from '../../../components/form/provider';
import Form from '../../../components/form/ui';
import { EditText } from '../../../components/form/ui/editText';
import { EditArea } from '../../../components/form/ui/editArea';
import { AsyncSelect } from '../../../components/form/ui/select/async/single';
import { AsyncSelectMultiple } from '../../../components/form/ui/select/async/multiple';
import { SelectMultiple } from '../../../components/form/ui/select/multiple';
import { GridComponent } from '../../../components/ui/grid/Grid.component';
import { GalleryComponent } from '../../../components/ui/gallery/Gallery.component';

import { GET } from '../../../services/Service.context';
import API from '../../../services/axios/api';

import { withAuthContext } from '../../../contexts/auth/Auth.context';

const getImages = (values) => {
    let imagesToReturn = []
    if (values.category && values.name && values.images) {
        imagesToReturn = values.images.map(item => {
            return {
                img: `${endpoints.baseURL}/media/exercises/${values.category}/${values.name}/${item.label}`,
                title: item.value
            }
        })
    }

    return imagesToReturn
}

const ExerciseForm = ({
    authContext,
    body,
    footer,
    onSubmit,
    initialValues,
    disabled = false,
    players = []
}) => {

    const [values, setValues] = useState({})

    const onChange = (newValues) => {        
        if (values.category !== newValues.category || values.name !== newValues.name || values.images !== newValues.images) {            
            setValues(newValues)
        }
    }

    return (
        <FormProvider
            onSubmit={onSubmit}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}
            onChange={onChange}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.category.xs}>
                        <GET request={API.coaching.getExerciseCategories(authContext.access_token)}>
                            <AsyncSelect
                                item={body.category}
                                node={body.category.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.name.xs}>
                        <GET request={API.coaching.getExerciseName({ access_token: authContext.access_token, categoryId: values.category })}>
                            <AsyncSelect
                                item={body.name}
                                node={body.name.node} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.image.xs}>
                        <GET request={API.coaching.getExerciseImages({ access_token: authContext.access_token, exerciseId: values.name })}>
                            <AsyncSelectMultiple
                                item={body.image}
                                node={body.image.node} />
                        </GET>
                    </GridComponent>
                    <GalleryComponent items={getImages(values)} cellHeight={100} />
                    <GridComponent item={true} xs={body.theme.xs}>
                        <GET request={API.coaching.getTheme(authContext.access_token)}>
                            <AsyncSelect
                                item={body.theme}
                                node={body.theme.name} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.duration.xs}>
                        <EditText item={body.duration} />
                    </GridComponent>

                    <GridComponent item={true} xs={body.rest.xs}>
                        <EditText item={body.rest} />
                    </GridComponent>

                    <GridComponent item={true} xs={body.players.xs}>
                        <SelectMultiple
                            options={players}
                            item={body.players}
                            node={body.players.name}
                            others={body.players.others} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.description.xs}>
                        <EditArea item={body.description} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    )
}


export default withAuthContext(ExerciseForm);