import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './CellCalendar.component.style';
import TableCell from '@material-ui/core/TableCell';
// import Typography from '@material-ui/core/Typography';
import Moment from 'moment';

const CellCalendarTemplate = ({
    value
}) => {

    const data = Moment(value).format('YYYY-MM-DD');

    return (
        <TableCell>
            {data}
        </TableCell>
    )
}

export const CellCalendarComponent = withStyles(styles)(CellCalendarTemplate)