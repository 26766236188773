import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Toolbar.component.style';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const ToolbarTemplate = ({
    classes,
    title,
    children,
}) => {

    return (
        <div className={classes.root}>
            <Divider className={classes.separator} />
            <Typography variant="h6" className={classes.title}>{title}</Typography>
            {children}
        </div>
    )
}

export const ToolbarComponent = withStyles(styles)(ToolbarTemplate)