import jsPDF from 'jspdf'
import 'jspdf-autotable';
import moment from 'moment'

const getImageFromUrl = (url) => new Promise((resolve, reject) => {

    var img = new Image, data, ret = { data: null, pending: true };
    img.setAttribute('crossOrigin', 'anonymous');

    img.onError = function () {
        throw new Error('Cannot load image: "' + url + '"');
    }
    img.onload = function () {
        var canvas = document.createElement('canvas');
        document.body.appendChild(canvas);
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        // Grab the image as a jpeg encoded in base64, but only the data        
        data = canvas.toDataURL('image/jpeg').slice('data:image/jpeg;base64,'.length);

        // Convert the data to binary form
        //data = atob(data)
        document.body.removeChild(canvas);

        ret['data'] = data;
        ret['width'] = img.width;
        ret['height'] = img.height;
        resolve(ret)
    }
    img.src = url;

});

const processImageAsycn = async (url) => {
    let data = await getImageFromUrl(url);
    return data;
};


function resizeImage(originalSize, boundsSize) {
    let newSize = { w: 0.0, h: 0.0 }
    const rw = originalSize.w / boundsSize.w
    const rh = originalSize.h / boundsSize.h

    if (rw > rh) {
        newSize.h = Math.round(originalSize.h / rw);
        newSize.w = boundsSize.w;
    }
    else {
        newSize.w = Math.round(originalSize.w / rh);
        newSize.h = boundsSize.h;
    }

    return newSize
}

function HeadRows() {
    return [
        { title: 'Title', value: 'Value' },
    ]
}



function TraningBodyRows(training) {

    const titles = [
        'Training Plan: ',
        'Training Role: ',
        'Pitch Position: ',
        'Combined Pitch Position: ',
        'Group: ',
        'Objective: ',
        'Note: ',        
    ]

    const values = [
        training.training_plan.training_plan,
        training.training_role.map(item => { return ` ${item.training_role} ` }),
        training.pitch_position.map(item => { return ` ${item.pitch_position} ` }),
        training.combined_pitch_position.map(item => { return ` ${item.combined_pitch_position} ` }),
        training.training_group ? 'Si' : ' No',
        training.objective.map(item => { return ` ${item.objective} ` }),
        training.note ? training.note : '',
    ]

    let body = []
    titles.forEach((element, index) => {
        body.push({
            title: element,
            value: values[index]
        })
    });

    return body
}

function ExerciseBodyRows(exercise) {

    const titles = [
        'Name: ',
        'Duration: ',
        'Recovery: ',
        'Step: ',
        'Num. Participants: ',
        'Dim. Field: ',
        'Rules: ',
        'Note: ',
        'Description: '
    ]

    const values = [
        exercise.name ? exercise.name.name : '',
        exercise.duration ? exercise.duration : '',
        exercise.rest ? exercise.rest : '',
        exercise.step ? exercise.step : '',
        '',
        '',
        '',
        '',
        exercise.description ? exercise.description : ''
    ]

    let body = []
    titles.forEach((element, index) => {
        body.push({
            title: element,
            value: values[index]
        })
    });

    return body
}





export const TraningToPDF = async (training, exercises) => {


    var doc = new jsPDF('p', 'pt')
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    // Title 
    doc.setFontSize(22);
    doc.setFont('helvetica');
    doc.setFontStyle('bold')
    doc.text(40, 40, `Scheda Allenamento N ___ del ${moment(training.date, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm')}`)
    doc.autoTable({
        head: HeadRows(),
        body: TraningBodyRows(training),
        showHead: false,
        startY: 100,
        rowPageBreak: 'auto',
        bodyStyles: {
            valign: 'top'
        },
        styles: {
            cellWidth: 'wrap',
            fontSize: 12
        },
        columnStyles: {
            title: {
                fontStyle: 'bold'
            },
            value: {
                fontStyle: 'italic',
                cellWidth: 'auto'
            },
        },
    })

    var actions = []
    var bodyExercise = []
    exercises.forEach(exercise => {
        if (exercise.images.length > 0) {
            exercise.images.forEach(image => {
                bodyExercise.push(ExerciseBodyRows(exercise))
                actions.push(processImageAsycn(image.image))
            });
        }
    });

    var results = await Promise.all(actions)

    results.forEach((element, index) => {
        doc.addPage()

        const size = resizeImage(
            { w: element.width, h: element.height },
            { w: width, h: height }
        )

        doc.addImage(element.data, 'JPEG', 10, 10, size.w - 20, size.h)
        doc.autoTable({
            head: HeadRows(),
            body: bodyExercise[index],
            showHead: false,
            startY: size.h + 50,
            rowPageBreak: 'auto',
            bodyStyles: {
                valign: 'top'
            },
            styles: {
                cellWidth: 'wrap',
                fontSize: 12
            },
            columnStyles: {
                title: {
                    fontStyle: 'bold'
                },
                value: {
                    fontStyle: 'italic',
                    cellWidth: 'auto'
                },
            },
        })
    });

    exercises.forEach(exercise => {
        if (exercise.images.length === 0) {
            doc.addPage()
            doc.autoTable({
                head: HeadRows(),
                body: ExerciseBodyRows(exercise),
                showHead: false,
                startY: 20,
                rowPageBreak: 'auto',
                bodyStyles: {
                    valign: 'top'
                },
                styles: {
                    cellWidth: 'wrap',
                    fontSize: 12
                },
                columnStyles: {
                    title: {
                        fontStyle: 'bold'
                    },
                    value: {
                        fontStyle: 'italic',
                        cellWidth: 'auto'
                    },
                },
            })
        }
    });

    const filename = `${moment(training.date, 'YYYY-MM-DDTHH:mm:ss').format('DD_MM_YYYY_HH_mm')}.pdf`
    doc.save(filename)
}