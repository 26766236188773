import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { NewMedicalNoteConf } from '../../config/medicalArea/NewMedicalNoteConf';

import { withAuthContext } from '../../contexts/auth/Auth.context';

import MedicalNoteForm from '../../components/medical/form';
import {
    getMedicalNote,
    addMedicalNote,
    updateMedicalNote,
    deleteMedicalNote
} from "../../redux/actions/actions";

import {
    selectMedicalNote,
} from "../../redux/selectors/selectors";

import ProgressComponent from '../../components/ui/progress/Progress.component';



const getInitialValue = (data, { start, end }) => {

    const initialValues = data !== null ? {
        id: data.id,
        start: data.start ? data.start : null,
        end: data.end ? data.end : null,
        surname: data.player ? data.player.id : null,
        availability: data.availability ? data.availability.id : null,
        injury_type: data.injury_type ? data.injury_type.id : null,
        therapy: data.therapies ? data.therapies.map(item => { return { value: item.id, label: item.therapy } }) : [],
        work_program: data.work_programs ? data.work_programs.map(item => { return { value: item.id, label: item.work_program } }) : [],
        field_typology: data.field_typology ? data.field_typology.id : '',
        event_type: data.event_type ? data.event_type.id : null,
        trauma_type: data.trauma_type ? data.trauma_type.id : null,
        note: data.note ? data.note : '',
    } : {
            start: start,
            end: end
        }

    return initialValues
}


const NewMedicalNote = ({
    onDismiss,
    authContext,
    medicalNote,
    noteId,
    getMedicalNote,
    addMedicalNote,
    updateMedicalNote,
    deleteMedicalNote,
    disabled,
    multiSlot,
    initialValues,
    range
}) => {

    const {
        body,
        footer
    } = NewMedicalNoteConf;

    useEffect(() => {
        if (noteId) {
            getMedicalNote({
                access_token: authContext.access_token,
                id: noteId
            })
        }
    }, [noteId]);

    const update = values => {
        updateMedicalNote({
            access_token: authContext.access_token,
            values: values,
            id: noteId,
            start_date: range.start_date,
            end_date: range.end_date,
            callback: () => {
                onDismiss()
            }
        })
    }

    const add = values => {
        addMedicalNote({
            access_token: authContext.access_token,
            values: values,
            start_date: range.start_date,
            end_date: range.end_date,
            callback: () => {
                onDismiss()
            }
        })
    }

    const onSubmit = values => {
        medicalNote.data !== null ? update(values) : add(values)
    };

    const onDelete = () => {
        deleteMedicalNote({
            access_token: authContext.access_token,
            id: noteId,
            start_date: range.start_date,
            end_date: range.end_date,
            callback: () => {
                onDismiss()
            }
        })
    };

    return (
        <>
            {medicalNote.loading ? <ProgressComponent /> : null}
            <MedicalNoteForm
                body={body}
                footer={footer}
                multiSlot={multiSlot}
                onSubmit={onSubmit}
                onDelete={onDelete}
                edit={noteId ? true : false}
                initialValues={getInitialValue(medicalNote.data, initialValues)}
                disabled={disabled} />
        </>
    );
};

const mapStateToProps = state => ({
    medicalNote: selectMedicalNote(state),
});

const mapDispatchToProps = dispatch => ({
    getMedicalNote: (body) => {
        dispatch(getMedicalNote(body))
    },
    addMedicalNote: (body) => {
        dispatch(addMedicalNote(body));
    },
    updateMedicalNote: (body) => {
        dispatch(updateMedicalNote(body));
    },
    deleteMedicalNote: (body) => {
        dispatch(deleteMedicalNote(body));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NewMedicalNote));