import React from 'react';
import { withNotificationsContext } from '../context';

import NotificationsComponent from '../../ui/notification/Notifications.component';

const Notifications = ({
    notificationsContext: {
        notifications,
        actions: {
            handleDelete,
        }
    }
}) => {

    const list = notifications.map(notification => {
        return ({
            id: notification.id,
            primary: `Player: ${notification.player.name}  ${notification.player.surname}`,
            secondary: notification.description
        })
    })

    return (
        <NotificationsComponent
            notifications={list}
            handleDelete={handleDelete} />
    )
}

export default withNotificationsContext(Notifications);