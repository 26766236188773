import React from 'react';

import { withAuthContext } from '../../../contexts/auth/Auth.context';

import { EditText } from '../../../components/form/ui/editText';
import { CalendarPicker } from '../../../components/form/ui/calendar';
import { EditArea } from '../../../components/form/ui/editArea';
import { AsyncSelect } from '../../../components/form/ui/select/async/single';
import { AsyncSelectMultiple } from '../../../components/form/ui/select/async/multiple';
import FormProvider from '../../../components/form/provider';
import Form from '../../../components/form/ui';
import { GridComponent } from '../../../components/ui/grid/Grid.component';
import { Checkbox } from '../../../components/form/ui/checkbox';

import { GET } from '../../../services/Service.context';
import API from '../../../services/axios/api';



const TrainingForm = ({
    authContext,
    body,
    footer,
    onSubmit,
    onDelete,
    initialValues,
    disabled = false
}) => {

    return (
        <FormProvider
            onSubmit={onSubmit}
            onDelete={onDelete}
            body={body}
            footer={disabled ? null : footer}
            initialValues={initialValues}>
            <Form>
                <GridComponent container={true}>
                    <GridComponent item={true} xs={body.date.xs}>
                        <CalendarPicker
                            item={body.date}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.duration.xs}>
                        <EditText
                            item={body.duration}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={body.trainingPlan.xs}>
                        <GET request={API.coaching.getTrainingPlan(authContext.access_token)}>
                            <AsyncSelect
                                item={body.trainingPlan}
                                node={body.trainingPlan.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.trainingGroup.xs}>
                        <Checkbox
                            item={body.trainingGroup}
                            disabled={disabled} />
                    </GridComponent>
                    <GridComponent item={true} xs={11} />
                    <GridComponent item={true} xs={body.pitchPosition.xs}>
                        <GET request={API.coaching.getPitchPosition(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.pitchPosition}
                                node={body.pitchPosition.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.combinedPitchPosition.xs}>
                        <GET request={API.coaching.getCombinedPitchPosition(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.combinedPitchPosition}
                                node={body.combinedPitchPosition.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.trainingRole.xs}>
                        <GET request={API.coaching.getTrainingRole(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.trainingRole}
                                node={body.trainingRole.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.objective.xs}>
                        <GET request={API.coaching.getObjective(authContext.access_token)}>
                            <AsyncSelectMultiple
                                item={body.objective}
                                node={body.objective.name}
                                disabled={disabled} />
                        </GET>
                    </GridComponent>
                    <GridComponent item={true} xs={body.note.xs}>
                        <EditArea
                            item={body.note}
                            disabled={disabled} />
                    </GridComponent>
                </GridComponent>
            </Form>
        </FormProvider>
    );
};

export default withAuthContext(TrainingForm);