import React from "react"
import { Field } from "react-final-form"
import { UploadFileComponent } from "../../../ui/form/uploadFile/UploadFile.component"
import { Error } from "../error"

export const UploadFile = props => {
    const { item, errors, disabled } = props
    return (
        <>
            <Field
                name={item.name}
                label={item.label}
                required={item.required}
                disabled={disabled}
                type={item.type}
                error={errors ? errors[item.name] : null}
                component={UploadFileComponent}
            />
            <Error name={item.name} />
        </>
    )
}
