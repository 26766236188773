import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Grid.component.style';
import Grid from '@material-ui/core/Grid';

const GridTemplate = ({
    classes,
    children,
    container,
    item,
    xs,
    spacing,
}) => {

    return (
        <Grid
            className={classes.root}
            container={container}
            item={item}
            xs={xs}
            spacing={spacing}>
            {children}
        </Grid>
    )
}

export const GridComponent = withStyles(styles)(GridTemplate)