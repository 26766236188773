import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_ERROR,
    GET_NOTIFICATIONS_SUCCESS
} from '../../actions/actions'

const initialState = {
    loading: false,
    error: null,
    data: []
};

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {

        case GET_NOTIFICATIONS:
            return {
                ...state,
                loading: true
            };

        case GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            };

        case GET_NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state

    }
};

export default notificationsReducer;