import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './MenuText.component.style';
import ListItemText from '@material-ui/core/ListItemText';

import { appColor } from '../../../../config/colors/colors';

const MenuItemTextTemplate = ({
    classes,
    text,
    selected
}) => {
    
    const color = selected? appColor.other.main : appColor.secondary.main

    return (
        <ListItemText
            primary={text}
            style={{color: color}}/>
    )
}

export const MenuItemTextComponent = withStyles(styles)(MenuItemTextTemplate)