import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router"
import { TraningToPDF } from "../../services/pdf/pdf"

import GroupsExercise from "./groups/GroupsExercise.container"
import UpdateTraining from "./UpdateTraining.container"
import Exercises from "./exercises/Exercises.container"
import GoalKeeperExercises from "./goalkeepers/GoalKeeperExercises.container"
import Notes from "./note/Notes.container"
import File from "./file/File.container"

import { withAuthContext } from "../../contexts/auth/Auth.context"
import usePermissions from "../../hooks/usePermissions"

import TrainingTemplate from "../../template/training/Training.template"
import { ToolbarComponent } from "../../components/ui/page/toolbar/Toolbar.component"
import { ButtonComponent } from "../../components/ui/button/Button.component"

import {
    selectTraining,
    selectWorkProposals
} from "../../redux/selectors/selectors"

const TrainingPage = ({ match, authContext, training, exercises }) => {
    const [downloadPDF, setDownloadPDF] = useState(false)

    useEffect(() => {
        training.data !== null && exercises.data !== null
            ? setDownloadPDF(true)
            : setDownloadPDF(false)
    }, [training, exercises])

    const { isAuthEnabled } = usePermissions("Training", authContext.type)

    const goTOPDF = () => {
        if (downloadPDF) {
            TraningToPDF(training.data, exercises.data.Other)
        }
    }

    return (
        <TrainingTemplate
            toolbar={
                <ToolbarComponent title="TRAINING">
                    <ButtonComponent
                        title="PDF"
                        disabled={false}
                        action={event => {
                            goTOPDF(event)
                        }}
                    />
                </ToolbarComponent>
            }
            trainingBox={
                <UpdateTraining
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                />
            }
            notesBox={
                <Notes
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                />
            }
            exercisesBox={
                <Exercises
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                />
            }
            exercisesPlayersBox={
                <GroupsExercise
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                    type="Players"
                    title="Player"
                />
            }
            goalkepperExercisesBox={
                <GoalKeeperExercises
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                />
            }
            goalkepperExercisesPlayersBox={
                <GroupsExercise
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                    type="GoalKeepers"
                    title="GoalKeeper"
                />
            }
            gpsBox={
                <File
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                    title={"Gps"}
                    field={"gps"}
                    file={
                        training.data && training.data.gps
                            ? { url: training.data.gps }
                            : null
                    }
                />
            }
            pdfBox={
                <File
                    trainingId={match.params.training}
                    enabled={isAuthEnabled}
                    title={"Pdf"}
                    field={"file_note"}
                    file={
                        training.data && training.data.file_note
                            ? { url: training.data.file_note }
                            : null
                    }
                />
            }
        />
    )
}

const mapStateToProps = state => ({
    training: selectTraining(state),
    exercises: selectWorkProposals(state)
})

export default withRouter(
    withAuthContext(connect(mapStateToProps, null)(TrainingPage))
)
