import { combineReducers } from "redux"
import { connectRouter } from "connected-react-router"
import { ACCOUNT_SIGNOUT } from "../actions/actions"

import authReducer from "./user/auth.reducer"
import userReducer from "./user/user.reducer"

import trainingsReducer from "./training/trainings.reducer"
import trainingReducer from "./training/training.reducer"
import trainingDeskReducer from "./training/trainingDesk.reducer"

import workProposalsReducer from "./workProposal/workProposals.reducer"
import workProposalReducer from "./workProposal/workProposal.reducer"
import workProposalSettingsReducer from './workProposal/workProposalSettings.reducer'
import workProposalGoalkeeperSettingsReducer from "./workProposal/workProposalGoalkeeperSettings.reducer"

import medicalNoteReducer from "./medical/medicalNote.reducer"
import medicalNotesReducer from "./medical/medicalNotes.reducer"

import goalWorkKeeperProposalReducer from "./goalkeeper/goalKeeperWorkProposal.reducer"
import goalWorkKeeperProposalsReducer from "./goalkeeper/goalKeeperWorkProposals.reducer"

import notesReducer from "./notes/notes.reducer"
import noteReducer from "./notes/note.reducer"

import notiticationsReducer from "./notification/notifications.reducer"

import squadReducer from "./team/squad.reducer"
import teamsReducer from "./team/teams.reducer"

import dailyReportReducer from "./report/dailyReport.reducer"
import injuryDeskReducer from "./report/injuryDesk.reducer"
import nextMatchReducer from "./report/nextMatch.reducer"
import statisticsReducer from "./report/statistics.reducer"

import injuryTypesReducer from "./settings/injuryTypes.reducer"
import workProgramsReducer from "./settings/workPrograms.reducer"
import teamFieldWorksReducer from "./settings/teamFieldWorks.reducer"
import exerciseCategoriesReducer from "./settings/exerciseCategories.reducer"
import exerciseImagesReducer from "./settings/exerciseImages.reducer"
import exerciseNamesReducer from "./settings/exerciseNames.reducer"

import clubTeamsReducer from "./club/clubTeams.reducer"
import clubTeamReducer from "./club/clubTeam.reducer"
import competitionsReducer from "./club/competitions.reducer"
import clubCategoriesReducer from "./club/clubCategories.reducer"
import clubLinksReducer from "./club/clubLinks.reducer"

import playersReducer from './team/players.reducer';


const settings = combineReducers({
    injuryTypes: injuryTypesReducer,
    workPrograms: workProgramsReducer,
    teamFieldWorks: teamFieldWorksReducer,
    exerciseCategories: exerciseCategoriesReducer,
    exerciseImages: exerciseImagesReducer,
    exerciseNames: exerciseNamesReducer
})

const club = combineReducers({
    competitions: competitionsReducer,
    clubTeams: clubTeamsReducer,
    clubTeam: clubTeamReducer,
    clubCategories: clubCategoriesReducer,
    clubLinks: clubLinksReducer,
    players: playersReducer,
})

const goalKeeperWork = combineReducers({
    goalKeeperWorkProposal: goalWorkKeeperProposalReducer,
    goalKeeperWorkProposals: goalWorkKeeperProposalsReducer
})

const medical = combineReducers({
    medicalNote: medicalNoteReducer,
    medicalNotes: medicalNotesReducer,
})

const notes = combineReducers({
    note: noteReducer,
    notes: notesReducer,
})

const notification = combineReducers({
    notifications: notiticationsReducer,
})

const report = combineReducers({
    dailyReport: dailyReportReducer,
    injuryDesk: injuryDeskReducer,
    nextMatch: nextMatchReducer,
    statistics: statisticsReducer,
})

const team = combineReducers({
    squad: squadReducer,
    teams: teamsReducer,
    players: playersReducer,
})

const training = combineReducers({
    training: trainingReducer,
    trainingDesk: trainingDeskReducer,
    trainings: trainingsReducer,
})

const user = combineReducers({
    auth: authReducer,
    user: userReducer,
})

const workProposal = combineReducers({
    workProposals: workProposalsReducer,
    workProposal: workProposalReducer,
    workProposalSettings: workProposalSettingsReducer,
    workProposalGoalkeeperSettings: workProposalGoalkeeperSettingsReducer 
})


const appReducer = history =>
    combineReducers({
        router: connectRouter(history),
        club: club,
        goalKeeperWork: goalKeeperWork,
        medical: medical,
        notes: notes,
        notification: notification,
        report: report,
        settings: settings,
        team: team,
        training: training,
        user: user,
        workProposal: workProposal
    }
)

export const rootReducer = history => (state, action) => {
    if (action.type === ACCOUNT_SIGNOUT) {
        state = undefined
    }
    return appReducer(history)(state, action)
}
