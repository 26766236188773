import { takeLatest, put, call } from "redux-saga/effects"
import {
    getInjuryTypes,
    getInjuryTypesSuccess,
    getInjuryTypesError,
    addInjuryType,
    updateInjuryType,
    actionInjuryTypeError,
    actionInjuryTypeSuccess
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* refreshList(access_token) {
    try {
        let response = yield call(API.medicals.getInjuryType, access_token)
        yield put(getInjuryTypesSuccess(response.data))
    } catch (e) {
        yield put(getInjuryTypesError(e))
    }
}

function* getInjuryTypesSaga(action) {
    const { access_token } = action.payload
    yield call(refreshList, access_token)
}

function* addInjuryTypeSaga(action) {
    const { access_token, values , callback = () => { } } = action.payload

    try {
        let add = yield call(API.medicals.addInjuryType, {
            access_token,
            values
        })
        yield put(actionInjuryTypeSuccess(add.data.data))
    } catch (e) {
        yield put(actionInjuryTypeError(e))
    }

    yield call(refreshList, access_token)

    callback()
}

function* updateInjuryTypeSaga(action) {
    const { access_token, values, id, callback = () => { } } = action.payload

    try {
        let update = yield call(API.medicals.updateInjuryType, {
            access_token,
            values,
            id
        })
        yield put(actionInjuryTypeSuccess(update.data.data))
    } catch (e) {
        yield put(actionInjuryTypeError(e))
    }

    yield call(refreshList, access_token)

    callback()
}

export function* injuryTypesWatcherSaga() {
    yield takeLatest(getInjuryTypes, getInjuryTypesSaga)
    yield takeLatest(addInjuryType, addInjuryTypeSaga)
    yield takeLatest(updateInjuryType, updateInjuryTypeSaga)
}
