import { createAction } from 'redux-actions';


// Squad
export const GET_SQUAD = 'GET_SQUAD';
export const getSquad = createAction(GET_SQUAD);
export const GET_SQUAD_ERROR = 'GET_SQUAD_ERROR';
export const getSquadError = createAction(GET_SQUAD_ERROR);
export const GET_SQUAD_SUCCESS = 'GET_SQUAD_SUCCESS';
export const getSquadSuccess = createAction(GET_SQUAD_SUCCESS);

// Teams
export const GET_TEAMS = 'GET_TEAMS';
export const getTeams = createAction(GET_TEAMS);
export const GET_TEAMS_ERROR = 'GET_TEAMS_ERROR';
export const getTeamsError = createAction(GET_TEAMS_ERROR);
export const GET_TEAMS_SUCCESS = 'GET_TEAMS_SUCCESS';
export const getTeamsSuccess = createAction(GET_TEAMS_SUCCESS);



// Players
export const GET_PLAYERS = 'GET_PLAYERS';
export const getPlayers = createAction(GET_PLAYERS);
export const GET_PLAYERS_ERROR = 'GET_PLAYERS_ERROR';
export const getPlayersError = createAction(GET_PLAYERS_ERROR);
export const GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS';
export const getPlayersSuccess = createAction(GET_PLAYERS_SUCCESS);

export const ADD_PLAYER = 'ADD_PLAYER';
export const addPlayer = createAction(ADD_PLAYER);
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const updatePlayer = createAction(UPDATE_PLAYER);
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const deletePlayer = createAction(DELETE_PLAYER);

export const ACTION_PLAYER_SUCCESS = 'ACTION_PLAYER_SUCCESS';
export const actionPlayerSuccess = createAction(ACTION_PLAYER_SUCCESS);
export const ACTION_PLAYER_ERROR = 'ACTION_PLAYER_ERROR';
export const actionPlayerError = createAction(ACTION_PLAYER_ERROR);



