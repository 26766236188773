import React from 'react';
import ListProvider from '../../components/list/provider';
import List from '../../components/list/ui';
import ListHead from '../../components/list/ui/head';
import ListBody from '../../components/list/ui/body';
import BoxComponent from '../../components/ui/box/Box.component';

import TabsProvider from '../../components/tabs/provider';
import Tabs from '../../components/tabs/ui';
import useTabs from '../../hooks/useTabs';

import {
    AppearanceGroupsConf
} from '../../config/training/PlayerAppearances.conf';


const GroupsList = ({ item }) => {

    const items = item.value;

    return (
        <BoxComponent p={1}>
            <ListProvider
                head={item.conf.head}
                body={item.conf.body}
                data={items}>
                <List>
                    <ListHead />
                    <ListBody />
                </List>
            </ListProvider>
        </BoxComponent>
    )
}

const PlayerAppearences = ({
    appearances
}) => {

    const { index, setIndex } = useTabs()

    const tabs = [{
        title: 'Appearances',
        conf: AppearanceGroupsConf,
        value: appearances !== null ? appearances : [],
    }]

    return (
        <TabsProvider
            index={index}
            onChange={(index) => { setIndex(index) }}
            tabs={tabs}>
            <Tabs />
            <GroupsList item={tabs[index]} />
        </TabsProvider>
    )
}


export default PlayerAppearences;