import { createAction } from "redux-actions"

// Competition
export const GET_COMPETITIONS = "GET_COMPETITIONS"
export const getCompetitions = createAction(GET_COMPETITIONS)
export const GET_COMPETITIONS_ERROR = "GET_COMPETITIONS_ERROR"
export const getCompetitionsError = createAction(GET_COMPETITIONS_ERROR)
export const GET_COMPETITIONS_SUCCESS = "GET_COMPETITIONS_SUCCESS"
export const getCompetitionsSuccess = createAction(GET_COMPETITIONS_SUCCESS)

// Team
export const GET_CLUB_TEAMS = "GET_CLUB_TEAMS"
export const getClubTeams = createAction(GET_CLUB_TEAMS)
export const GET_CLUB_TEAMS_ERROR = "GET_CLUB_TEAMS_ERROR"
export const getClubTeamsError = createAction(GET_CLUB_TEAMS_ERROR)
export const GET_CLUB_TEAMS_SUCCESS = "GET_CLUB_TEAMS_SUCCESS"
export const getClubTeamsSuccess = createAction(GET_CLUB_TEAMS_SUCCESS)

export const GET_CLUB_TEAM = "GET_CLUB_TEAM"
export const getClubTeam = createAction(GET_CLUB_TEAM)
export const GET_CLUB_TEAM_ERROR = "GET_CLUB_TEAM_ERROR"
export const getClubTeamError = createAction(GET_CLUB_TEAM_ERROR)
export const GET_CLUB_TEAM_SUCCESS = "GET_CLUB_TEAM_SUCCESS"
export const getClubTeamSuccess = createAction(GET_CLUB_TEAM_SUCCESS)

// Categories
export const GET_CLUB_CATEGORIES = "GET_CLUB_CATEGORIES"
export const getClubCategories = createAction(GET_CLUB_CATEGORIES)
export const GET_CLUB_CATEGORIES_ERROR = "GET_CLUB_CATEGORIES_ERROR"
export const getClubCategoriesError = createAction(GET_CLUB_CATEGORIES_ERROR)
export const GET_CLUB_CATEGORIES_SUCCESS = "GET_CLUB_CATEGORIES_SUCCESS"
export const getClubCategoriesSuccess = createAction(
    GET_CLUB_CATEGORIES_SUCCESS
)

//Links
export const GET_CLUB_LINKS = "GET_CLUB_LINKS"
export const getClubLinks = createAction(GET_CLUB_LINKS)
export const GET_CLUB_LINKS_ERROR = "GET_CLUB_LINKS_ERROR"
export const getClubLinksError = createAction(GET_CLUB_LINKS_ERROR)
export const GET_CLUB_LINKS_SUCCESS = "GET_CLUB_LINKS_SUCCESS"
export const getClubLinksSuccess = createAction(GET_CLUB_LINKS_SUCCESS)
