import React from "react"
import { connect } from "react-redux"

import { patchTrainingFile } from "../../../redux/actions/actions"

import { withAuthContext } from "../../../contexts/auth/Auth.context"

import File from "../../../components/file"
import FileForm from "../../../components/file/form"

import TrainingBoxTemplate from "../../../template/training/TrainingBox.template"

const FilePage = ({
    authContext,
    trainingId,
    title,
    field,
    patchTrainingFile,
    file,
    enabled
}) => {
    const onDeleteFile = () => {
        patchTrainingFile({
            access_token: authContext.access_token,
            id: trainingId,
            field: field,
            file: null
        })
    }

    const onUpdateFile = uploadFile => {
        patchTrainingFile({
            access_token: authContext.access_token,
            id: trainingId,
            field: field,
            file: uploadFile
        })
    }

    const disabled = !enabled

    return (
        <>
            <TrainingBoxTemplate
                titleBox={title}
                actionBox={
                    disabled ? null : (
                        <FileForm
                            file={file}
                            field={field}
                            onUpdateFile={onUpdateFile}
                        />
                    )
                }>
                <File
                    item={file}
                    title={title}
                    onDeleteFile={() => onDeleteFile()}
                    disabled={disabled}
                />
            </TrainingBoxTemplate>
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    patchTrainingFile: (field, item) => {
        dispatch(patchTrainingFile(field, item))
    }
})

export default withAuthContext(connect(null, mapDispatchToProps)(FilePage))
