import React from 'react';
import { connect } from 'react-redux';

import { withAuthContext } from "../../../contexts/auth/Auth.context"
import { FormConf } from "../../../config/settings/form/ExerciseCategory.config"
import ExerciseCategoryForm from '../../../components/settings/form/execiseCategory';

import {
    addExerciseCategory,
    updateExerciseCategory
} from "../../../redux/actions/actions"

const getInitialValue = (data) => {
    return {
        id: data.id,
        name: data.name,
    }
}


const NewExerciseCategoryPage = ({
    authContext,
    addExerciseCategory,
    updateExerciseCategory,
    onDismiss,
    item,
}) => {

    const {
        body,
        footer
    } = FormConf;

    const update = values => {
        updateExerciseCategory({
            access_token: authContext.access_token,
            values: values,
            id: item.id,
            callback: () => onDismiss()
        })
    }

    const add = values => {
        addExerciseCategory({
            access_token: authContext.access_token,
            values: values,
            callback: () => onDismiss()
        })
    }

    const onSubmit = values => {
        item !== null ? update(values) : add(values)
    };

    return (
        <ExerciseCategoryForm
            body={body}
            footer={footer}
            onSubmit={onSubmit}
            initialValues={item ? getInitialValue(item) : {}}
            disabled={false} />
    );
};


const mapDispatchToProps = dispatch => ({
    addExerciseCategory: body => {
        dispatch(addExerciseCategory(body))
    },
    updateExerciseCategory: body => {
        dispatch(updateExerciseCategory(body))
    }
});

export default withAuthContext(connect(null, mapDispatchToProps)(NewExerciseCategoryPage));