import {
    GET_EXERCISE_NAMES,
    GET_EXERCISE_NAMES_ERROR,
    GET_EXERCISE_NAMES_SUCCESS
} from "../../actions/actions"

const initialState = {
    loading: false,
    error: null,
    data: []
}

const exerciseNamesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EXERCISE_NAMES:
            return {
                ...state,
                loading: true
            }

        case GET_EXERCISE_NAMES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload.data
            }

        case GET_EXERCISE_NAMES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export default exerciseNamesReducer
