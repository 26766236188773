export default {
    upsertExerciseImage: ({ values, id }) => {
        var body = new FormData()

        if (id) {
            body.set("id", id)
        }

        if (values.exercise) {
            body.set("exercise", values.exercise)
        }

        if (values.uploadFile && values.uploadFile.length > 0) {
            body.set("image", values.uploadFile[0])
        }

        return body
    }
}
