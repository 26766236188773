import { useState, useEffect } from "react"
import { UserType } from "../contexts/auth/Auth.context"

const TrainingPermission = type => {
    switch (type) {
        case UserType.Admin:
        case UserType.Technician:
            return true
        default:
            return false
    }
}

const MedicalAreaPermission = type => {
    switch (type) {
        case UserType.Admin:
        case UserType.Medical:
            return true
        default:
            return false
    }
}

const NextMatchPermission = type => {
    return true
}

const CompetitionsPermission = type => {
    return true
}

const StatisticsPermission = type => {
    return true
}

const SettingsPermission = type => {
    switch (type) {
        case UserType.Admin:
            return true
        default:
            return false
    }
}

const usePermissions = (section, userType) => {
    const [isAuthEnabled, setIsAuthEnabled] = useState(false)

    useEffect(() => {
        switch (section) {
            case "Training":
                setIsAuthEnabled(TrainingPermission(userType))
                break

            case "MedicalArea":
                setIsAuthEnabled(MedicalAreaPermission(userType))
                break

            case "NextMatch":
                setIsAuthEnabled(NextMatchPermission(userType))
                break

            case "Competitions":
                setIsAuthEnabled(CompetitionsPermission(userType))
                break

            case "Statistics":
                setIsAuthEnabled(StatisticsPermission(userType))
                break

            case "Settings":
                setIsAuthEnabled(SettingsPermission(userType))
                break

            default:
                setIsAuthEnabled(false)
        }
    }, [userType])
    return {
        isAuthEnabled
    }
}

export default usePermissions
