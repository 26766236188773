export default {
    getClubCategories: ({ team }) => {
        if (team) {
            var params = new URLSearchParams()
            params.append("team", team)
            return {
                params: params
            }
        }
    },
    getClubLinks: ({ category }) => {
        if (category) {
            var params = new URLSearchParams()
            params.append("category", category)
            return {
                params: params
            }
        }
    }
}
