import { useState } from 'react';

const useDrawer = (initialValue) => {
    const [openDrawer, setOpenDrawer] = useState(initialValue);
    
    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };
    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    return { openDrawer, handleDrawerOpen, handleDrawerClose }
};

export default useDrawer;