import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { useCookies } from 'react-cookie';

import {
    accountSignOut,
    getNotifications,
    updateNotification,
} from "../../redux/actions/actions";

import {
    selectNotifications,
    selectUser,
    selectTeams
} from "../../redux/selectors/selectors";

import DashboardProvider from '../../components/dashboard/provider';
import Dashboard from '../../components/dashboard/ui';
import DashboardToolbar from '../../components/dashboard/ui/toolbar';
import DashboardDrawer from '../../components/dashboard/ui/drawer';
import DashboardContent from '../../components/dashboard/ui/content';
import { PopoverProvider } from '../../components/popover/context';
import Popover from '../../components/popover/ui';
import { NotificationsProvider } from '../../components/notifications/context';
import Notifications from '../../components/notifications/ui';

import useDrawer from '../../hooks/useDrawer';
import usePopover from '../../hooks/usePopover';

import { DrawerConf } from '../../config/drawer/Drawer.conf';

import { AuthProvider } from '../../contexts/auth/Auth.context';
// import apiPlayer from '../../services/axios/api.player';

const DashboardPage = ({
    children,
    history,
    signOut,
    getNotifications,
    updateNotification,
    notifications,
    user,
    teams,
}) => {

    const { openDrawer, handleDrawerOpen, handleDrawerClose } = useDrawer(true);
    const { openPopover, anchorEl, handlePopoverOpen, handlePopoverClose } = usePopover(false)
    const [cookies, setCookie, removeCookie] = useCookies(['access_token']);
    const [drawer, setDrawer] = useState(DrawerConf)

    useEffect(() => {
        //getNotifications({ access_token: cookies.token.access_token })
    }, []);

    useEffect(() => {
        let drawConf = JSON.parse(JSON.stringify(DrawerConf));
        drawConf.list[0].nested.splice(0, 0, ...teams.map(team => { return { title: team.name, id: team.id, link: `/training_calendar/${team.id}`, icon: 'IconTeamComponent' } }));
        setDrawer(drawConf)
    }, [teams]);

    const handleGoToLink = (link) => {
        history.push(link)
    }

    const handleLogout = () => {
        removeCookie('token', { path: '/' })
        signOut()
    }

    const handleDelete = (id) => {
        let notification = notifications.data.find(element => element.id === id);
        notification = {
            ...notification,
            surname: notification.player.id
        }
        updateNotification({
            access_token: cookies.token.access_token,
            values: notification
        })
    }

    const notificationsToRead = notifications.data.filter(element => element.isRead === false)

    return (
        <AuthProvider
            user={user}>
            <DashboardProvider
                conf={drawer}
                openDrawer={openDrawer}
                openPopover={openPopover}
                title={'Footure Desk'}
                badge={notificationsToRead.length}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                handleGoToLink={handleGoToLink}
                handleOpenNotification={handlePopoverOpen}
                handleLogout={handleLogout}>
                <Dashboard>
                    <DashboardToolbar />
                    <DashboardDrawer />
                    <DashboardContent>
                        {children}
                    </DashboardContent>
                </Dashboard>
            </DashboardProvider>
            <PopoverProvider
                open={openPopover}
                handleClose={handlePopoverClose}
                anchorEl={anchorEl}>
                <Popover>
                    <NotificationsProvider
                        notifications={notificationsToRead}
                        handleDelete={handleDelete}>
                        <Notifications />
                    </NotificationsProvider>
                </Popover>
            </PopoverProvider>
        </AuthProvider>
    );
};

const mapStateToProps = state => ({
    notifications: selectNotifications(state),
    user: selectUser(state),
    teams: selectTeams(state),
});

const mapDispatchToProps = dispatch => ({
    signOut: () => {
        dispatch(accountSignOut());
    },
    getNotifications: (params) => {
        dispatch(getNotifications(params));
    },
    updateNotification: (params) => {
        dispatch(updateNotification(params));
    }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardPage));
