import { takeLatest, put, call } from 'redux-saga/effects';
import {

    getMedicalNotes,
    getMedicalNotesError,
    getMedicalNotesSuccess,

    getMedicalNote,
    addMedicalNote,
    updateMedicalNote,
    deleteMedicalNote,
    resetMedicalNote,

    actionMedicalNoteReset,
    actionMedicalNoteSuccess,
    actionMedicalNoteError,

} from '../../actions/actions';


import API from '../../../services/axios/api';


function* refreshList({ access_token, start_date, end_date }) {

    try {
        // Fetch List
        let list = yield call(API.medicals.getMedicalNotesFilter, { access_token, values: { start_date, end_date } });
        yield put(getMedicalNotesSuccess(list.data.data))

    } catch (e) {
        yield put(getMedicalNotesError(e))
    }
}

function* getMedicalNotesSaga(action) {
    const { access_token, start_date, end_date } = action.payload
    yield call(refreshList, { access_token, values: { start_date, end_date } })
}


function* getMedicalNoteSaga(action) {

    const { access_token, id, callback = () => { } } = action.payload;

    try {
        // GET 
        let { data } = yield call(API.medicals.getMedicalNote, { access_token, id });
        yield put(actionMedicalNoteSuccess(data.data))
    } catch (e) {
        yield put(actionMedicalNoteError(e))
    }
    callback()
}


function* addMedicalNoteSaga(action) {

    const { access_token, values, start_date, end_date, callback = () => { } } = action.payload;

    try {
        // Add 
        let add = yield call(API.medicals.addMedicalNote, { access_token, values });
        yield put(actionMedicalNoteSuccess(add.data.data))
    } catch (e) {
        yield put(actionMedicalNoteError(e))
    }

    yield call(refreshList, { access_token, start_date, end_date })

    callback();
}

function* updateMedicalNoteSaga(action) {

    const { access_token, values, id, start_date, end_date,  callback = () => { } } = action.payload;

    try {
        // Update 
        let update = yield call(API.medicals.updateMedicalNote, { access_token, values, id });
        yield put(actionMedicalNoteSuccess(update.data.data))
    } catch (e) {
        yield put(actionMedicalNoteError(e))
    }

    yield call(refreshList, { access_token, start_date, end_date })

    callback()
}

function* deleteMedicalNoteSaga(action) {

    const { access_token, id, start_date, end_date, callback = () => { } } = action.payload;

    try {
        //Delete
        yield call(API.medicals.deleteMedicalNote, { access_token, id });
        yield put(actionMedicalNoteSuccess())

    } catch (e) {
        yield put(actionMedicalNoteError(e))
    }

    yield call(refreshList, { access_token, start_date, end_date })

    callback();
}

function* resetMedicalNoteSaga() {
    yield put(actionMedicalNoteReset())
}

export function* medicalWatcherSaga() {
    yield takeLatest(getMedicalNotes, getMedicalNotesSaga);
    yield takeLatest(getMedicalNote, getMedicalNoteSaga);
    yield takeLatest(resetMedicalNote, resetMedicalNoteSaga);
    yield takeLatest(addMedicalNote, addMedicalNoteSaga);
    yield takeLatest(deleteMedicalNote, deleteMedicalNoteSaga);
    yield takeLatest(updateMedicalNote, updateMedicalNoteSaga);
}