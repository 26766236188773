import { put, call, takeLatest } from 'redux-saga/effects';
import {
    getTeams,
    getTeamsError,
    getTeamsSuccess,
} from '../../actions/actions';

import API from '../../../services/axios/api';

function* getTeamsSaga(action) {    
    try {
        // Fetch List
        let list = yield call(API.player.getTeams, action.payload);
        yield put(getTeamsSuccess(list.data.data))

    } catch (e) {
        yield put(getTeamsError(e))
    }
}

export function* teamsWatcherSaga() {
    yield takeLatest(getTeams, getTeamsSaga);    
}