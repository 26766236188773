import React from 'react';
import { withModalContext } from '../../hoc';
import ModalTitleComponent from '../../../ui/modal/title/ModalTitle.component';
import ToolbarComponent from '../../../ui/toolbar/Toolbar.component';
import { IconCloseComponent } from '../../../ui/icon/Icon.component';


const ModalToolbar = ({
    modalContext: {
        title,
        actions: {
            onClose
        }
    }
}) => {

    return (
        <ModalTitleComponent>
            <ToolbarComponent
                title={title}
                rightButtons={onClose ? <IconCloseComponent action={onClose} /> : null} />
        </ModalTitleComponent>
    )
}

export default withModalContext(ModalToolbar);