import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
    getWorkProposal,
    addWorkProposal,
    updateWorkProposal,
} from "../../../redux/actions/actions";

import {
    selectTraining,
    selectWorkProposals,
    selectSquad,
    selectWorkProposal,
} from "../../../redux/selectors/selectors";

import { NewExerciseConf } from '../../../config/training/NewExercise.conf';
import { withAuthContext } from '../../../contexts/auth/Auth.context';
import ExerciseForm from '../../../components/exercise/form'
import ProgressComponent from '../../../components/ui/progress/Progress.component';

const getInitialValue = (data, players) => {
    const initialValues = data !== null ? {
        name: data.name.id,
        category: data.name.category.id,
        theme: data.theme.id,
        description: data.description ? data.description : '',
        duration: data.duration ? data.duration : '',
        rest: data.rest ? data.rest : '',
        number: data.number,
        work_proposal_type: data.work_proposal_type.id,
        images: data.images.map(image => { return { label: image.image.substring(image.image.lastIndexOf('/') + 1), value: image.id } }),
        surname: data.players.map(player => { return { label: player.surname + ' ' + player.name, value: player.id } })
    } : {
            surname: players.map(player => { return { label: player.surname + ' ' + player.name, value: player.id } })
        }

    return initialValues
}



const NewExercisePage = ({
    onDismiss,
    authContext,
    training,
    workProposal,
    getWorkProposal,
    addWorkProposal,
    updateWorkProposal,
    nextNumber,
    groups,
    exerciseId,
}) => {

    const {
        body,
        footer
    } = NewExerciseConf;


    useEffect(() => {
        if (exerciseId) {
            getWorkProposal({
                access_token: authContext.access_token,
                id: exerciseId
            })
        }
    }, [exerciseId]);

    const update = values => {
        updateWorkProposal({
            access_token: authContext.access_token,
            values: values,
            training: training.data.id,
            id: workProposal.data.id,
            callback: () => {
                onDismiss()
            }
        })
    }

    const add = values => {
        addWorkProposal({
            access_token: authContext.access_token,
            values: {
                ...values,
                number: nextNumber,
                work_proposal_type: 2
            },
            id: training.data.id,
            callback: () => {
                onDismiss()
            }
        })
    }

    const onSubmit = values => {
        workProposal.data !== null ? update(values) : add(values)
    };

    const getPlayers = (team) => {
        let newGroups = []
        Object.keys(team).forEach(key => {
            if (key !== 'team') {
                const item = team[key]
                const players = team[key].players.filter(player => player.role.id !== 1)
                const count = players.length
                newGroups.push({
                    players,
                    count
                })
            }
        });

        newGroups.sort(function (a, b) {
            return b.count - a.count
        })

        return newGroups[0].players.sort((a, b) => (a.role.id > b.role.id) ? 1 : (b.role.id > a.role.id) ? -1 : 0)
    }

    const players = groups.data ? getPlayers(groups.data[0]) : []

    return (
        <>
            {workProposal.loading ? <ProgressComponent /> : null}
            <ExerciseForm
                body={body}
                footer={footer}
                onSubmit={onSubmit}
                initialValues={getInitialValue(workProposal.data, players)}
                disabled={false}
                players={players} />
        </>
    );
};


const mapStateToProps = state => ({
    training: selectTraining(state),
    nextNumber: selectWorkProposals(state).data.Other.length + 1,
    groups: selectSquad(state),
    workProposal: selectWorkProposal(state)
});

const mapDispatchToProps = dispatch => ({
    getWorkProposal: (body) => {
        dispatch(getWorkProposal(body))
    },
    addWorkProposal: (body) => {
        dispatch(addWorkProposal(body));
    },
    updateWorkProposal: (body) => {
        dispatch(updateWorkProposal(body));
    },
});

export default withAuthContext(connect(mapStateToProps, mapDispatchToProps)(NewExercisePage));