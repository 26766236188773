import { useState } from 'react';

const useTabs = () => {
    const [index, setIndex] = useState(0);

    return {
        index,
        setIndex,        
    }
};

export default useTabs;