import React from 'react';
import { IconEditComponent, IconDeleteComponent } from '../../../components/ui/icon/Icon.component';

import ExerciseLarge from '../large'
import ExerciseSmall from '../small'
import ExerciseMedium from '../medium'
import ExerciseNumber from '../number'
import ExerciseRow from '../row'
import ExerciseAction from '../action'
import ExerciseContent from '../content'

const GoalKeeperExercise = ({
    item,
    onDeleteExercise,
    onUpdateExercise,
    disabled,
}) => {

    return (
        <ExerciseRow>
            <ExerciseNumber>
                {item.number}
            </ExerciseNumber>
            <ExerciseContent>
                <ExerciseLarge>
                    {item.theme.map(value => value.theme.toUpperCase())}
                </ExerciseLarge>

                <ExerciseMedium>
                    Lavoro campo preparatore
                </ExerciseMedium>
                {item.workout_goalkeeper && item.workout_goalkeeper.length > 0 ?
                    <ExerciseSmall>
                        {`Categoria: ${item.workout_goalkeeper.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }
                {item.workout_modality && item.workout_modality.length > 0 ?
                    <ExerciseSmall>
                        {`Modalità: ${item.workout_modality.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }
                {item.kick_type  && item.kick_type.length > 0 ?
                    <ExerciseSmall>
                        {`Tipologia tiro: ${item.kick_type.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }
                {item.ball_type && item.ball_type.length > 0 ?
                    <ExerciseSmall>
                        {`Tipologia palla: ${item.ball_type.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }


                <ExerciseMedium>
                    Lavoro campo squadra
                </ExerciseMedium>
                {item.team_field_work ?
                    <ExerciseSmall>
                        {`Lavoro con squadra: ${item.team_field_work.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }

                <ExerciseMedium>
                    Lavoro specifico
                </ExerciseMedium>
                {item.gym_work && item.gym_work.length > 0 ?
                    <ExerciseSmall>
                        {`Lavoro in palestra: ${item.gym_work.map(value => value.name.toUpperCase())}`}                        
                    </ExerciseSmall> : null
                }
                {item.limb ?
                    <ExerciseSmall>
                        {`Arti: ${item.limb.name}`}                        
                    </ExerciseSmall> : null
                }
                {item.equipment ?
                    <ExerciseSmall>
                        {`Macchinari: ${item.equipment.name}`}                        
                    </ExerciseSmall> : null
                }
                {item.psychokinetic_light_work ?
                    <ExerciseSmall>
                        {`Lavoro psicocinetico luci: ${item.psychokinetic_light_work.name}`}                        
                    </ExerciseSmall> : null
                }
                {item.sand_work ?
                    <ExerciseSmall>
                        {`Sabbia: ${item.sand_work.name}`}                        
                    </ExerciseSmall> : null
                }



            </ExerciseContent>

            <ExerciseAction>
                {disabled ? null :
                    <>
                        <IconDeleteComponent action={() => onDeleteExercise(item.id)} />
                        <IconEditComponent action={() => onUpdateExercise(item.id)} />
                    </>
                }
            </ExerciseAction>

        </ExerciseRow>
    )
}

export default GoalKeeperExercise;