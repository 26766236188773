import React from "react"

//import FileViewer from "react-file-viewer"

import { Box } from "@material-ui/core"

import BoxComponent from "../ui/box/Box.component"
import FilePdfRender from "./pdfRender"

import {
    IconDeleteComponent,
    IconFileComponent,
    IconFilePdfComponent
} from "../ui/icon/Icon.component"

import { appColor } from "../../config/colors/colors"

const FileTitleRender = ({ file, type }) => {
    return (
        <>
            <a href={file.url} download>
                {type === "pdf" ? (
                    <IconFilePdfComponent />
                ) : (
                    <IconFileComponent />
                )}
            </a>
            <span>{file.url.substring(file.url.lastIndexOf("/") + 1)}</span>
        </>
    )
}

const File = ({ item, title, onDeleteFile, disabled }) => {
    const type = item ? item.url.substring(item.url.lastIndexOf(".") + 1) : null

    return (
        <>
            <BoxComponent
                width="100%"
                display="flex"
                flexDirection="row"
                bgcolor={appColor.white}
                color={appColor.darkGrey}
            >
                <BoxComponent width="90%">
                    <BoxComponent flexDirection="row" p={2}>
                        <BoxComponent
                            textAlign="left"
                            fontWeight="fontWeightRegular"
                            fontStyle="italic"
                            mb={2}
                            fontSize={16}
                        >
                            {item ? (
                                <FileTitleRender file={item} type={type} />
                            ) : (
                                <Box p={2}>Add new {title} file</Box>
                            )}
                        </BoxComponent>
                    </BoxComponent>
                </BoxComponent>

                <BoxComponent width="10%" pt={2}>
                    {disabled || !item ? null : (
                        <IconDeleteComponent
                            action={() => onDeleteFile(item)}
                        />
                    )}
                </BoxComponent>
            </BoxComponent>
            {type === "pdf" ? <FilePdfRender file={item} /> : null}
        </>
    )
}

export default File
