import { put, call, takeLatest, select } from "redux-saga/effects"
import { push } from "connected-react-router"

import {
    getTrainings,
    getTrainingsError,
    getTrainingsSuccess,
    addTraining,
    updateTraining,
    getTraining,
    deleteTraining,
    actionTrainingError,
    actionTrainingSuccess,
    patchTrainingFile
} from "../../actions/actions"

import API from "../../../services/axios/api"

function* refreshList({ access_token, start_date, end_date, team }) {
    try {
        // Fetch List
        let list = yield call(API.coaching.getTrainingsFiltered, {
            access_token,
            values: { start_date, end_date, team }
        })
        yield put(getTrainingsSuccess(list.data.data))
    } catch (e) {
        yield put(getTrainingsError(e))
    }
}

function* getTrainingsSaga(action) {
    const { access_token, start_date, end_date, team } = action.payload
    yield call(refreshList, { access_token, start_date, end_date, team })
}

function* addTrainingSaga(action) {
    const {
        access_token,
        values,
        team,
        start_date,
        end_date,
        callback = () => {}
    } = action.payload

    try {
        // Add Training
        let add = yield call(API.coaching.addTraining, {
            access_token,
            values,
            team
        })
        yield put(actionTrainingSuccess(add.data.data))
    } catch (e) {
        yield put(actionTrainingError(e))
    }

    // Refresh List
    yield call(refreshList, { access_token, start_date, end_date, team })

    // Callback
    callback()
}

function* updateTrainingSaga(action) {
    const { access_token, values, team, id } = action.payload

    try {
        // Update Training
        let update = yield call(API.coaching.updateTraining, {
            access_token,
            values,
            team,
            id
        })
        yield put(actionTrainingSuccess(update.data.data))
    } catch (e) {
        yield put(actionTrainingError(e))
    }
}

function* getTrainingSaga(action) {
    const { access_token, id, callback = () => {} } = action.payload

    try {
        // Get
        let { data } = yield call(API.coaching.getTraining, {
            access_token,
            id
        })
        yield put(actionTrainingSuccess(data.data))
        callback()
    } catch (e) {
        yield put(actionTrainingError(e))
    }
}

function* deleteTrainingSaga(action) {
    const { access_token, id } = action.payload

    try {
        // Delete
        yield call(API.coaching.deleteTraining, { access_token, id })
        yield put(actionTrainingSuccess(null))
    } catch (e) {
        yield put(actionTrainingError(e))
    }

    // Show Calendar
    const teams = yield select(state => state.team.teams.data);
    yield put(push(`/training_calendar/${teams[0].id}`))
}

function* patchTrainingFileSaga(action) {
    const { access_token, field, file, id } = action.payload

    try {
        yield call(API.coaching.patchTrainingFile, {
            access_token,
            field,
            file,
            id
        })
        yield call(getTrainingSaga, action)
    } catch (e) {
        yield put(actionTrainingError(e))
    }
}

export function* trainingsWatcherSaga() {
    yield takeLatest(getTrainings, getTrainingsSaga)
    yield takeLatest(addTraining, addTrainingSaga)
    yield takeLatest(updateTraining, updateTrainingSaga)
    yield takeLatest(getTraining, getTrainingSaga)
    yield takeLatest(deleteTraining, deleteTrainingSaga)
    yield takeLatest(patchTrainingFile, patchTrainingFileSaga)
}
