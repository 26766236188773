import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Row.component.style';
import TableRow from '@material-ui/core/TableRow';

const RowTemplate = ({
    children
}) => {

    return (
        <TableRow>
            {children}
        </TableRow>
    )
}

export const RowComponent = withStyles(styles)(RowTemplate)