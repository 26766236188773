import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { styles } from './Body.component.style';


const BodyTemplate = ({
    classes,
    children
}) => {

    return (
        <Paper className={classes.root} elevation={1}>
            {children}
        </Paper>
    )
}

export const BodyComponent = withStyles(styles)(BodyTemplate)