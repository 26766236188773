import React from "react"
import { Route, Switch } from "react-router-dom"

import PrivateRoute from "../components/privateRoute/privateRoute"
import Dashboard from "../containers/dashboard/Dashboard.container"
import Login from "../containers/login/Login.container"
import CompetitionsPage from "../containers/competitions/Competitions.container"

import MedicalAreaPage from "../containers/medicalArea/MedicalArea.container"
import NextMatchPage from "../containers/nextMatch/NextMatch.container"

import Training from "../containers/training/Training.container"
import TrainingDesk from "../containers/training/TrainingDesk.container"
import TrainingCalendarPage from "../containers/training/TrainingCalendar.container"
import ExerciseDesk from "../containers/training/desk/exercise/ExerciseDesk.container"
import GoalkeeperExerciseDesk from "../containers/training/desk/goalkeeper/GoalkeeperExerciseDesk.container"

import DailyReport from "../containers/medicalArea/DailyReport.container"

import InjuryDesk from "../containers/medicalArea/InjuryDesk.container"

import MedicalStatistics from "../containers/medicalArea/MedicalStatistics.container"

import Settings from "../containers/settings/Settings.container"
import Team from "../containers/competitions/Team.container"


const Router = props => {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route path="/login" component={Login} />
            <Dashboard>
                <PrivateRoute
                    path="/competitions"
                    component={CompetitionsPage}
                />
                <PrivateRoute
                    path="/medical_area"
                    component={MedicalAreaPage}
                />
                <PrivateRoute path="/next_match" component={NextMatchPage} />
                <PrivateRoute
                    path="/training_calendar/:team"
                    component={TrainingCalendarPage}
                />
                <PrivateRoute path="/training/:training" component={Training} />
                <PrivateRoute path="/training_desk" component={TrainingDesk} />
                <PrivateRoute path="/exercise_desk" component={ExerciseDesk} />
                <PrivateRoute path="/goalkeeper_exercise_desk" component={GoalkeeperExerciseDesk} />
                <PrivateRoute path="/daily_report" component={DailyReport} />
                <PrivateRoute path="/injury_desk" component={InjuryDesk} />
                <PrivateRoute
                    path="/medical_statistics"
                    component={MedicalStatistics}
                />
                <PrivateRoute path="/settings" component={Settings} />
                <PrivateRoute path="/team/:team" component={Team} />
            </Dashboard>
        </Switch>
    )
}

export default Router
